import { KeyValues } from "../../utils";
import SelectBox from "../SelectBox";

interface IProductSelectorProps {
    productNames: KeyValues[];
    selectedIndex: number;
    disabled?: boolean;
    style?: React.CSSProperties;
    onSelectedIndex: (index: number) => void;
}

export const FRANCHISE_SELECT_ALL = 65535;
export const PRODUCT_SELECT_ALL = 65535;

export function ProductSelector({
    productNames,
    selectedIndex,
    disabled,
    style,
    onSelectedIndex,
}: IProductSelectorProps) {
    const productValue = () => {
        let result = "";
        if (productNames.length > 0) {
            result = productNames[selectedIndex].value;
        }

        return result;
    };

    const onSelectProduct = (index: number) => {
        onSelectedIndex(index);
    };

    return (
        <SelectBox
            style={style}
            key_values={productNames}
            selectValue={productValue()}
            disabled={disabled}
            onSelectValue={(_, index) => {
                onSelectProduct(index);
            }}
        />
    );
}
