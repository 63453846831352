import { FieldError, UseFormGetValues, UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import { lightTheme } from "../../../styles";
import { copyToClipboard, openUrlNewTab } from "../../../utils";
import { CheckBoxWithText } from "../../CheckBox";
import { FormInput, FormInputContainer } from "../../form/FormInputContainer";
import { IHostForm } from "../SignUpHostForm";
import {
    HostFormAnnotation,
    HostFormButton,
    HostFormContainer,
    HostFormInnerContainer,
    HostFormTitle,
} from "./HostFormCommon";

const FormButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
`;

const NA_PARTER_HELP_URL =
    "https://m.help.naver.com/support/contents/contentsView.help?contentsNo=7596";

interface NAHostFormProps {
    register: UseFormRegister<IHostForm>;
    getValues: UseFormGetValues<IHostForm>;
    formErrors: {
        na_onbi_id?: FieldError | undefined;
    };
    isNAChecked: boolean;
    onClickCheck: () => void;
}

export function NAHostForm({
    register,
    getValues,
    formErrors,
    isNAChecked,
    onClickCheck,
}: NAHostFormProps) {
    const representativeId = "onbi119";

    const onClickIdCopy = () => {
        if (representativeId) {
            copyToClipboard(representativeId);
        }

        alert("복사되었습니다.");
    };

    return (
        <HostFormContainer>
            <HostFormInnerContainer>
                <HostFormTitle>네이버 정보</HostFormTitle>
                <HostFormAnnotation>
                    ONBI에서 네이버 스마트플레이스로 [예약/관리]를 하기위해선{" "}
                    <HostFormAnnotation
                        style={{ color: lightTheme.red }}
                    >{`[${representativeId}]에게 관리자(운영자) 권한을 위임해야합니다.`}</HostFormAnnotation>{" "}
                    위임이 되지 않았을 경우 서비스 이용이 불가합니다.
                </HostFormAnnotation>
                {isNAChecked ? null : (
                    <FormButtonContainer>
                        <HostFormButton
                            style={{
                                backgroundColor: "white",
                                color: `${lightTheme.green}`,
                                border: `${lightTheme.green} 1px solid`,
                                width: "150px",
                            }}
                            onClick={() => openUrlNewTab(NA_PARTER_HELP_URL)}
                        >{`[${representativeId}]에게 관리자 권한 위임 방법 보기`}</HostFormButton>
                        <HostFormButton
                            style={{
                                backgroundColor: lightTheme.blue,
                                color: lightTheme.white,
                                width: "150px",
                            }}
                            onClick={onClickIdCopy}
                        >{`위임받을 관리자 (${representativeId}) 복사하기`}</HostFormButton>
                    </FormButtonContainer>
                )}

                <CheckBoxWithText
                    isChecked={isNAChecked}
                    title={`[${representativeId}]에게 관리자 권한을 위임하였습니다.`}
                    style={{ marginTop: "20px" }}
                    color="chocolate"
                    onClick={() => onClickCheck()}
                />
                {isNAChecked ? (
                    <>
                        <FormInputContainer
                            style={{
                                marginTop: "5px",
                                marginBottom: "10px",
                            }}
                            input={
                                <FormInput
                                    {...register("na_onbi_id", {
                                        validate: (value) => {
                                            return (
                                                value === representativeId ||
                                                `${representativeId}를 입력해주세요.`
                                            );
                                        },
                                    })}
                                    placeholder="관리자 권한이 위임된 ONBI 아이디"
                                />
                            }
                            isError={Boolean(formErrors.na_onbi_id?.message)}
                            error={formErrors.na_onbi_id?.message}
                        />
                    </>
                ) : null}
            </HostFormInnerContainer>
        </HostFormContainer>
    );
}
