import styled from "styled-components";
import { Input, ButtonSpan } from "../../styles";

const SFormInputContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;

export const FormInput = styled(Input)`
    border: 0px;

    font-size: 14px;
    outline: none;
    padding: 10px 0px;
    width: 100%;
    background-color: ${(props) => props.theme.white};

    &::placeholder {
        color: ${(props) => props.theme.fontGray};
    }
`;

const FormInputUnderline = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const FormUnderline = styled.div<{ error?: Boolean }>`
    width: 100%;
    border-bottom: 0.5px solid
        ${(props) => (props.error ? props.theme.red : props.theme.border)};
`;

const FormInputBox = styled.div`
    display: flex;
    width: 100%;
`;

const FormInputButton = styled(ButtonSpan)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    margin-left: 20px;
    width: 100px;
    height: 40px;
    color: ${(props) => props.theme.blue};
`;

const FormInputHint = styled.span<{ error?: Boolean }>`
    width: 100%;
    color: ${(props) => (props.error ? props.theme.red : props.theme.fontGray)};
    font-size: 12px;
    margin-top: 10px;
`;

interface FormInputContainerProps {
    input: any;
    hint?: string;
    style?: React.CSSProperties;
    buttonTitle?: string;
    buttonDisabled?: boolean;
    buttonClick?: React.MouseEventHandler;
    hidden?: boolean;
    isError?: boolean;
    error?: string;
}

export function FormInputContainer({
    input,
    hint,
    style,
    buttonTitle,
    buttonDisabled,
    buttonClick,
    hidden,
    error,
    isError,
}: FormInputContainerProps) {
    return (
        <SFormInputContainer style={style} hidden={hidden}>
            <FormInputBox>
                <FormInputUnderline>
                    {input}
                    <FormUnderline error={isError} />
                </FormInputUnderline>
                {buttonTitle ? (
                    <FormInputButton
                        disabled={buttonDisabled}
                        onClick={buttonDisabled ? undefined : buttonClick}
                    >
                        {buttonTitle}
                    </FormInputButton>
                ) : null}
            </FormInputBox>
            {isError && error ? (
                <FormInputHint error={isError}>{error}</FormInputHint>
            ) : hint ? (
                <FormInputHint>{hint}</FormInputHint>
            ) : null}
        </SFormInputContainer>
    );
}
