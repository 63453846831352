import { useQuery } from "@apollo/client";
import { useState } from "react";
import styled from "styled-components";
import { toWon } from "../../global";
import { SEE_ALL_SALE_ITEMS_QUERY } from "../../graphqls/queries";
import { useSystem } from "../../hooks/useMember";
import { lightTheme } from "../../styles";
import {
    seeAllSaleItems,
    seeAllSaleItems_seeAllSaleItems_saleItems,
} from "../../__generated__/seeAllSaleItems";

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.mobile} {
        width: 1200px;
    }
`;

export const GridHintContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 270px repeat(5, 150px);
    grid-template-rows: repeat(2, 80px) 130px 100px 170px repeat(1, 80px);
    margin-top: 30px;
    margin-bottom: 5px;
    user-select: none;
`;

export const ItemTitle = styled.span`
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 700;
    padding: 20px;
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid ${(props) => props.theme.border};
`;

export const ItemText = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid ${(props) => props.theme.border};
`;

const HintContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`;

const Hint = styled.span`
    font-size: 15px;
    color: ${(props) => props.theme.fontDarkGray};
    margin-bottom: 8px;
`;

export const ItemHintContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`;

export const ItemHint = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.fontDarkGray};
    margin-bottom: 8px;
    line-height: 130%;
`;

export function InfoItemGrid() {
    const [saleItems, setSaleItems] = useState<
        seeAllSaleItems_seeAllSaleItems_saleItems[]
    >([]);

    const system = useSystem();

    useQuery<seeAllSaleItems>(SEE_ALL_SALE_ITEMS_QUERY, {
        onCompleted: (data: seeAllSaleItems) => {
            const {
                seeAllSaleItems: { ok, error, saleItems },
            } = data;

            if (ok) {
                saleItems?.map((item) => {
                    if (item) {
                        setSaleItems((prev) => {
                            if (prev && prev !== undefined) {
                                return [...prev, item];
                            } else {
                                return [item];
                            }
                        });
                    }
                });
            } else {
                alert(error);
            }
        },
    });

    return (
        <Container>
            {saleItems.length > 0 ? (
                <GridHintContainer>
                    <GridContainer>
                        <ItemTitle
                            style={{
                                justifyContent: "center",
                                borderBottom: "2px solid black",
                                backgroundColor: lightTheme.bookingUsed,
                                color: lightTheme.blue,
                            }}
                        >
                            제품
                        </ItemTitle>
                        {saleItems.map((item, index) => {
                            return (
                                <ItemTitle
                                    key={index}
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "25px",
                                        borderBottom: "2px solid black",
                                        color: lightTheme.blue,
                                        backgroundColor: lightTheme.bookingUsed,
                                    }}
                                >
                                    {item.name}
                                </ItemTitle>
                            );
                        })}
                        <ItemTitle
                            style={{
                                justifyContent: "center",
                            }}
                        >
                            예약 연동
                        </ItemTitle>
                        {saleItems.map((_, index) => {
                            if (index === 2) {
                                return (
                                    <ItemText
                                        style={{ borderRight: "0px" }}
                                        key={index}
                                    >
                                        무료
                                    </ItemText>
                                );
                            } else {
                                if (saleItems.length - 1 === index) {
                                    return <ItemText key={index}></ItemText>;
                                } else {
                                    return (
                                        <ItemText
                                            style={{ borderRight: "0px" }}
                                            key={index}
                                        ></ItemText>
                                    );
                                }
                            }
                        })}
                        <ItemTitle>
                            방/공간 수(최대)
                            <ItemHintContainer>
                                <ItemHint>* 방 추가는 개당 +25,000원</ItemHint>
                                <ItemHint>
                                    * Enterprise 상품의 경우 +20,000원
                                </ItemHint>
                            </ItemHintContainer>
                        </ItemTitle>
                        {saleItems.map((item, index) => {
                            return (
                                <ItemText key={index}>
                                    {item.maxProductCount}개
                                </ItemText>
                            );
                        })}

                        <ItemTitle>
                            문자 발송 건(월)
                            <ItemHintContainer>
                                <ItemHint>
                                    * 방 추가 구매 시 방 1개당 +100건
                                </ItemHint>
                            </ItemHintContainer>
                        </ItemTitle>
                        {saleItems.map((item, index) => {
                            return (
                                <ItemText key={index}>
                                    {item.maxMessageCount}건
                                </ItemText>
                            );
                        })}
                        <ItemTitle>
                            마케팅 대행
                            <ItemHintContainer>
                                <ItemHint>
                                    * 가입 후 최초 결제 시 1회 제공
                                </ItemHint>
                                <ItemHint>* 블로그 체험단, 방문 리뷰</ItemHint>
                                <ItemHint>
                                    * 추가 상품 구매를 원하실 경우 부가서비스를
                                    참고해주세요
                                </ItemHint>
                            </ItemHintContainer>
                        </ItemTitle>
                        {saleItems.map((item, index) => {
                            if (item.maxMarketingCount === 0) {
                                return (
                                    <ItemText key={index}>해당없음</ItemText>
                                );
                            } else {
                                return (
                                    <ItemText key={index}>
                                        {item.maxMarketingCount}건
                                    </ItemText>
                                );
                            }
                        })}
                        <ItemTitle
                            style={{
                                justifyContent: "center",
                            }}
                        >
                            월 이용료
                        </ItemTitle>
                        {saleItems.map((item, index) => {
                            return (
                                <ItemText
                                    key={index}
                                    style={{ color: lightTheme.red }}
                                >
                                    {toWon(item.cost)}
                                </ItemText>
                            );
                        })}
                    </GridContainer>
                    <HintContainer>
                        <Hint>{`* VAT 별도`}</Hint>
                        {system?.settlementDay ? (
                            <Hint>
                                {`* 결제일은 매월 ${system.settlementDay}일입니다`}
                            </Hint>
                        ) : null}
                    </HintContainer>
                </GridHintContainer>
            ) : null}
        </Container>
    );
}
