import { useState } from "react";
import styled from "styled-components";
import { ButtonSpan } from "../../styles";
import { seeBookingCalendar_seeBookingCalendar_bookings } from "../../__generated__/seeBookingCalendar";
import { BookingDetailPopup } from "../popup/BookingDetailPopup";
import { bookingBackgroundColor } from "./BookingsCalendarDayBooking";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Inner = styled(ButtonSpan)`
    display: flex;
    margin: 5px;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 700;

    color: ${(props) => props.theme.black};
`;

const InnerText = styled.span`
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

interface IBookingsCalendarMonthBookingProps {
    booking: seeBookingCalendar_seeBookingCalendar_bookings;
    franchiseIndex: number;
    productIndex: number;
    franchiseName: string;
    productName: string;
    useHalfTimeFranchise: boolean;
}

export const getKRDate = (date: Date) => {
    const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
    const krDate = new Date(utc + KR_TIME_DIFF);

    return krDate;
};

export const getBookingHour = (date: Date, isEnd: Boolean) => {
    const krDate = getKRDate(date);

    let result = String(krDate.getHours()).padStart(2, "0");
    if (isEnd) {
        if (krDate.getHours() === 23 && krDate.getMinutes() === 59) {
            result = "24";
        }

        if (krDate.getHours() === 0) {
            result = "24";
        }
    }

    return result;
};

export const getBookingMinute = (date: Date) => {
    const krDate = getKRDate(date);
    let result = String(krDate.getMinutes()).padStart(2, "0");
    if (krDate.getMinutes() === 59) {
        result = "00";
    }
    return result;
};

export function BookingsCalendarMonthBooking({
    booking,
    franchiseIndex,
    productIndex,
    franchiseName,
    productName,
    useHalfTimeFranchise,
}: IBookingsCalendarMonthBookingProps) {
    const startDate = new Date(Number(booking.startDateUTC));
    const endDate = new Date(Number(booking.endDateUTC));
    const [showBookingDetailPopup, setShowBookingDetailPopup] = useState(false);

    const getHourText = () => {
        let text = `${getBookingHour(startDate, false)}~${getBookingHour(
            endDate,
            true,
        )}, ${booking.username}`;

        if (useHalfTimeFranchise === true) {
            text = `${getBookingHour(startDate, false)}:${String(
                startDate.getMinutes(),
            ).padStart(2, "0")}~${getBookingHour(
                endDate,
                true,
            )}:${getBookingMinute(endDate)}`;
        }

        return text;
    };

    return (
        <Container>
            <Inner
                style={{ backgroundColor: bookingBackgroundColor(booking) }}
                onClick={() => setShowBookingDetailPopup(true)}
            >
                <InnerText>{`${getHourText()}`}</InnerText>
            </Inner>

            {showBookingDetailPopup ? (
                <BookingDetailPopup
                    didClosePopup={() => setShowBookingDetailPopup(false)}
                    booking={booking}
                    franchiseIndex={franchiseIndex}
                    productIndex={productIndex}
                    franchiseName={franchiseName}
                    productName={productName}
                    useHalfTimeFranchise={useHalfTimeFranchise}
                />
            ) : null}
        </Container>
    );
}
