import { PageTitle } from "../components/PageTitle";
import {
    Base,
    BoldText,
    Container,
    Enter,
    Header,
    Table,
    TableCell,
    TableColumn,
    TabText,
    Text,
    Title,
} from "../components/TermsPrivacyStyled";

export function Privacy() {
    return (
        <Base>
            <Container>
                <PageTitle title="개인정보처리방침" />
                <Header>개인정보처리방침</Header>
                <Enter />
                <Text>
                    환영합니다. 잠시 시간을 내어 ONBI의 개인정보 처리방침을 주의
                    깊게 읽어주시기 바랍니다.
                </Text>
                <Enter />
                <Text>
                    본 개인정보 처리방침은 ONBI(온비)가 수집하는 정보, 정보를
                    수집하는 이유, 수집한 정보의 이용, 개인정보에 대한 사용자의
                    선택에 대한 내용을 다루고 있으며, 법률 및 전문 용어의 사용을
                    최대한 자제하여 사용자가 이해하기 쉬운 용어로 설명합니다.
                </Text>
                <Text>
                    (주)폰티스 (이하 “회사”라 함)는 사용자의 개인정보를 처리함에
                    있어 「개인정보 보호법」(이하 “개인정보법”) 등 관련 법령 및
                    규정 등을 준수하고 있습니다. 본 “개인정보처리방침”은 회사가
                    제공하는 ONBI 서비스 및 그 후속 서비스(이하 총칭하여
                    “서비스”라 함)에 적용되며, 사용자의 소중한 개인정보를
                    보호함으로써 사용자가 안심하고 서비스를 사용할 수 있도록
                    회사가 준수해야 할 사항을 정합니다.
                </Text>
                <Text>
                    본 개인정보 처리방침은 2022년 7월 26일부터 적용됩니다.
                </Text>
                <Text>발효일: 2022년 7월 26일</Text>
                <Title>개인정보 수집 항목 및 방법</Title>
                <Text>
                    회사는 사용자들이 회사의 개인정보 보호정책 또는 이용약관의
                    내용에 대하여 회원가입을 완료하면 개인정보 수집에 대해
                    동의한 것으로 봅니다.
                </Text>
                <BoldText>개인정보 수집 항목</BoldText>
                <BoldText>회원가입에 필요한 정보</BoldText>
                <Text>
                    로그인 ID, 비밀번호, 이름, 닉네임, 이메일, 사업자번호,
                    상호명, 대표성함, 예금주
                </Text>
                <BoldText>ONBI 서비스 신청에 필요한 정보</BoldText>
                <Text>
                    플랫폼별 호스트 이메일, 비밀번호, 로그인 타입, 네이버 연동
                    ID, 네이버 예약 서비스 코드, 공간 정보(업체명, 지점 개수,
                    지점별 방 개수)
                </Text>
                <BoldText>ONBI 서비스 문자 전송 위임에 필요한 서류</BoldText>
                <Text>
                    발신번호 증빙서류[이용계약증명서(SKT), 통신가입증명원(KT),
                    서비스가입확인서(LGU+) 등], 신분증 사본, 위-수탁계약서
                </Text>
                <BoldText>ONBI 서비스 설정</BoldText>
                <Text>
                    휴대폰 번호, 설정 할 문자 메시지, 필요할 시 출입문 비밀번호,
                    예약 및 문자 발송내역 확인에 필요한 지점 이용 고객의 예약
                    번호, 이름, 휴대폰 번호
                </Text>
                <Title>개인정보의 수집 및 이용 목적</Title>
                <BoldText>
                    회원 가입시 또는 서비스 사용 과정에서 수집한 개인 정보는
                    아래와 같은 목적으로 사용합니다.
                </BoldText>
                <Text>
                    - 회원 관리: 사용자 식별, 가입의사 확인, 부정사용 및 비인가
                    사용 방지, 사기 또는 법령 및 사용약관을 위반하는 사용자에
                    대한 사용 제한 조치, 부정 사용 행위를 포함하여 기타 서비스의
                    원활한 운영에 지장을 주는 행위에 대한 방지 및 제재
                </Text>
                <Text>
                    - 서비스 제공: 서비스 사용에 관련된 결제 처리, 서비스 사용과
                    관련된 모든 문의사항, 불만처리, 공지사항 전달, 예약 목록
                    확인, 발송 내역 등 예약 통계 확인
                </Text>
                <Text>
                    - 광고성 정보 전송 및 이벤트 안내: 문자 메시지, 이메일 등을
                    사용하여 각종 이벤트 정보 및 참여기회 제공, 회사의 새로운
                    제품, 새로운 기능 서비스의 안내 등 광고성 정보의 제공,
                    마케팅 또는 프로모션에 활용
                </Text>
                <BoldText>
                    회사는 개인정보법에 따라 당초 수집 목적과 합리적으로 관련된
                    범위에서 다음의 사항을 고려하여 정보주체의 동의 없이
                    개인정보를 사용, 제공할 수 있습니다.
                </BoldText>
                <Text>- 당초 수집 목적과 합리적으로 관련성이 있는지 여부</Text>
                <Text>
                    - 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때
                    개인정보의 추가적인 사용 또는 제공에 대한 예측가능성이
                    있는지 여부
                </Text>
                <Text>- 정보주체의 이익을 부당하게 침해하는지 여부</Text>
                <Text>
                    - 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를
                    하였는지 여부
                </Text>
                <Title>개인정보의 제 3자 제공</Title>
                <Text>
                    회사는 사용자가 사전에 동의를 한 경우, 법률에 특별한 규정이
                    있거나 법령상 의무를 준수하기 위하여 불가피한 경우,
                    정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우 등
                    예외적인 경우를 제외하고는 원칙적으로 사용자의 개인정보를 제
                    3자에게 제공하지 않습니다.
                </Text>
                <Title>개인정보의 처리 위탁</Title>
                <BoldText>
                    회사는 보다 나은 서비스를 제공과 사용자 편의, 마케팅 업무
                    수행등을 위해 다음과 같이 사용자의 개인정보를 제 3에게 처리
                    위탁 할 수 있습니다.
                </BoldText>
                <Table>
                    <TableColumn>
                        <TableCell>수탁업체</TableCell>
                        <TableCell>위탁내용</TableCell>
                        <TableCell>기간</TableCell>
                    </TableColumn>
                    <TableColumn>
                        <TableCell>1) 주)누리고</TableCell>
                        <TableCell>
                            문자 전송, 문자 전송 위임에 필요한 서류
                        </TableCell>
                        <TableCell>이용목적 달성 시</TableCell>
                    </TableColumn>
                    <TableColumn>
                        <TableCell>2) Salesforce, Inc.</TableCell>
                        <TableCell>정보 보관 및 시스템 운영</TableCell>
                        <TableCell>이용목적 달성 시</TableCell>
                    </TableColumn>
                    <TableColumn>
                        <TableCell>3) Amazon Web Service Inc.</TableCell>
                        <TableCell>시스템 운영</TableCell>
                        <TableCell>이용목적 달성 시</TableCell>
                    </TableColumn>
                </Table>
                <Title>개인정보의 국외이전</Title>
                <BoldText>
                    회사는 서비스 제공 목적, 사용자 편의 제공 및 마케팅 업무
                    수행 등을 위해 다음과 같이 사용자의 개인정보를 국외로
                    이전하거나, 국외에서 관리할 수 있습니다.
                </BoldText>
                <Text>
                    이전 받는 업체(연락처):{" "}
                    <BoldText>Salesforce, Inc.</BoldText>{" "}
                    (heroku-abuse@salesforce.com)
                </Text>
                <Text>이전 국가: 미국</Text>
                <Text>
                    이전되는 개인정보 항목: 서비스 제공 과정 중 수집된 모든 정보
                </Text>
                <Text>목적: 서비스, 정보 보관 및 사용행태 분석</Text>
                <Text>기간: 이용목적 달성 시</Text>
                <Text>
                    일시/방법: 회원가입 및 서비스 제공 과정 중 정보통신망을 통해
                    수시로 이전
                </Text>
                <Enter />
                <Text>
                    이전 받는 업체(연락처):{" "}
                    <BoldText>Amazon Web Service Inc.</BoldText>{" "}
                    (aws-korea-privacy@amazon.com)
                </Text>
                <Text>이전 국가: 미국</Text>
                <Text>
                    이전되는 개인정보 항목: 서비스 제공 과정 중 수집된 모든 정보
                </Text>
                <Text>목적: 서비스</Text>
                <Text>기간: 이용목적 달성 시</Text>
                <Text>
                    일시/방법: 회원가입 및 서비스 제공 과정 중 정보통신망을 통해
                    수시로 이전
                </Text>
                <Title>사용자 개인정보 보관 기간</Title>
                <Text>
                    회사는 사용자가 회원 가입한 날로부터 서비스를 제공하는 기간
                    동안에 한하여 사용자의 개인정보를 보유 및 이용하게 됩니다.
                    개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및
                    이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당
                    개인정보를 지체 없이 파기합니다. 이 경우 개인정보는 어떠한
                    용도로도 열람 또는 이용할 수 없도록 처리됩니다. 단, 다음의
                    정보에 대해서는 하기의 이유로 명시한 기간 동안 보존합니다.
                </Text>
                <Text>
                    전자상거래 등에서의 소비자보호에 관한 법률에 의거하여 특정
                    정보를 일정 기간 동안 보관합니다.
                </Text>
                <BoldText>1) 계약 또는 청약철회 등에 관한 기록 5년</BoldText>
                <BoldText>
                    2) 대금결제 및 재화 등의 공급에 관한 기록 5년
                </BoldText>
                <BoldText>
                    3) 소비자의 불만 또는 분쟁처리에 관한 기록 3년
                </BoldText>
                <Text>
                    통신비밀보호법에 의거하여 사용자의 서비스 이용기록, 접속
                    로그, 접속 IP 정보를 3개월동안 보관합니다.
                </Text>
                <Title>사용자의 권리와 그 행사</Title>
                <BoldText>
                    사용자는 언제든지 등록되어있는 개인정보를 수정할 수 있으며
                    가입을 해지할 수도 있습니다.
                </BoldText>
                <Text>
                    사용자는 언제는 정보 수정에서 개인정보를 조회하거나 수정할
                    수 있으며 회원탈퇴에서 개인정보의 수집 및 이용 동의를 철회할
                    수 있습니다. 사용자의 권리는 정보 수정에서 직접 처리하거나,
                    본 개인정보처리방침{" "}
                    <BoldText>"개인정보 보호 책임자 및 연락처"</BoldText>에서
                    명시한 연락처 또는 전자우편을 통해 요청할 수
                    있습니다.사용자가 개인정보의 오류에 대한 정정을 요청하신
                    경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는
                    제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미
                    제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여
                    정정이 이루어지도록 하겠습니다. 회사는 사용자 혹은 법정
                    대리인의 요청에 의해 해지 또는 삭제된 개인정보는{" "}
                    <BoldText>"사용자 개인정보 보관 기간"</BoldText>에 명시된
                    바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
                    처리하고 있습니다.
                </Text>
                <Title>
                    개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 내용
                </Title>
                <Text>
                    회사는 서비스 제공에 필요한 접속로그, 기기정보 등을 자동으로
                    수집하기 위해서 사용자들의 정보를 저장하고 수시로 불러오는
                    '쿠키(cookie)'를 사용합니다. 쿠키는 서비스를 운영하는데
                    이용되는 서버(HTTP)가 사용자의 웹/모바일기기 브라우저에게
                    보내는 소량의 정보이며 사용자들의 컴퓨터/모바일기기 내의
                    하드디스크에 저장되기도 합니다.
                </Text>
                <BoldText>1) 쿠키의 사용 목적</BoldText>
                <Text>
                    이용자들이 각 서비스와 웹 사이트 방문 및 이용형태, 개인의
                    관심 분야 및 사용 버전에 따른 차별화된 정보 제공, 회원과
                    비회원의 접속 빈도 및 체류 시간 등을 분석하여 마케팅을
                    포함한 최적화된 맞춤형 정보를 제공하기 위해 사용합니다.
                </Text>
                <BoldText>2) 쿠키의 설치/운영 및 거부</BoldText>
                <Text>
                    사용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
                    사용자는 웹/모바일기기 브라우저에서 옵션을 설정함으로써 모든
                    쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                    아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의
                    저장을 거부할 경우에는 사용에 어려움이 있을 수 있습니다.
                </Text>
                <Title>개인정보의 안전성 확보 조치</Title>
                <Text>
                    회사는 사용자들의 개인정보를 처리함에 있어, 외부로부터의
                    무단 접근을 통제하고, 개인정보처리시스템에 대한 접속기록을
                    보관하며, 개인정보 처리 직원을 대상으로 정기적인 사내 교육을
                    실시하는 등 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지
                    않도록 개인정보보호법에서 정한 안전성 확보 조치를 취하고
                    있습니다.
                </Text>
                <Title>개인정보 처리방침의 변경</Title>
                <Text>
                    회사 서비스는 항상 여러분의 의미 있는 예약 스케줄 관리 및
                    소중한 시간의 이용을 줄일 수 있도록 돕고 서비스 데이터
                    이용에 대한 최신 안내를 제공할 수 있는 새롭고 혁신적인
                    방법을 모색하고 있습니다. 이에 본 개인정보 처리방침의 내용은
                    시간이 흐름에 따라 변경될 수 있습니다. 변경 사항이 있을
                    경우, 이를 충분히 검토할 수 있도록 실제 서비스에 적용되기 전
                    미리 공지해 드립니다.
                </Text>
                <Title>개인정보 보호 책임자 및 연락처</Title>
                <Text>
                    회사는 사용자의 개인정보 보호 업무 및 관련 고충사항 처리를
                    위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.
                    귀하가 서비스를 사용하면서 발생하는 모든 개인정보보호 관련
                    문의, 불만, 의견이나 기타 사항은 개인정보보호 책임자 및
                    담당부서로 연락해 주시기 바랍니다. 회사는 사용자 여러분의
                    목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록
                    최선을 다하겠습니다.
                </Text>
                <Enter />
                <TabText>개인정보 보호 책임자 : 고객지원실장</TabText>
                <TabText>소속: (주)폰티스</TabText>
                <TabText>연락처: support@pontis.co.kr</TabText>
                <Enter />
                <Text>
                    기타 개인정보침해에 대한 신고나 상담이 필요할 경우 아래
                    기관에 문의 바랍니다.
                </Text>
                <TabText>
                    KISA 개인정보침해신고센터: https://privacy.kisa.or.kr /
                    (국번없이) 118
                </TabText>
                <TabText>
                    경찰청 사이버수사국: https://cyberbureau.police.go.kr /
                    (국번없이) 182
                </TabText>
                <TabText>
                    대검찰청 사이버안전국: https://spo.go.kr / (국번없이) 1301
                </TabText>
            </Container>
        </Base>
    );
}
