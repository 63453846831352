import { KeyValues } from "../../utils";
import SelectBox from "../SelectBox";
import { useNavigate } from "react-router-dom";

export const DateColloct = {
    Day: "Day",
    Calendar: "Calendar",
};

interface IDataSwitchProps {
    style?: React.CSSProperties;
    dateCollector: string;
    routes: KeyValues[];
}

export function DateSwitch({ style, dateCollector, routes }: IDataSwitchProps) {
    const dateCollects: KeyValues[] = [
        { key: DateColloct.Day, value: "일별" },
        { key: DateColloct.Calendar, value: "월별" },
    ];

    const navigate = useNavigate();

    const dateCollectorValue = () => {
        let result = "";
        dateCollects.map((c) => {
            if (c.key === dateCollector) {
                result = c.value;
            }
        });

        return result;
    };

    const onSelectDateCollector = (keyValue: KeyValues) => {
        for (let i = 0; i < routes.length; ++i) {
            const route = routes[i];

            if (route.key === keyValue.key) {
                navigate(route.value);
                break;
            }
        }
    };

    return (
        <SelectBox
            style={style}
            key_values={dateCollects}
            selectValue={dateCollectorValue()}
            onSelectValue={(key_value) => {
                onSelectDateCollector(key_value);
            }}
        />
    );
}
