import styled from "styled-components";
import { ButtonSpan, lightTheme } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { refresh } from "../../apollo";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    left: 15px;
    width: 43px;
    height: 43px;
    z-index: 1;
`;

const Button = styled(ButtonSpan)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
`;

export function RefreshButton() {
    return (
        <Container>
            <Button
                onClick={() => refresh()}
                style={{ backgroundColor: lightTheme.blue }}
            >
                <FontAwesomeIcon
                    icon={faRefresh}
                    color={lightTheme.white}
                    size="lg"
                ></FontAwesomeIcon>
            </Button>
        </Container>
    );
}
