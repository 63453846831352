import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getOnbiLoginInfoDatas, isSignedIn, signUserOut } from "../apollo";
import useMember from "../hooks/useMember";
import routes from "../routes";
import { ButtonSpan, LogoFontSpan } from "../styles";
import { openUrlNewTab } from "../utils";
import { SignUpCompleteStep } from "../__generated__/globalTypes";
import { useState } from "react";
import { AccountListPopup } from "./popup/AccountListPopup";

export const HEADER_HEIGHT = "70px";

const SHeader = styled.header`
    background-color: ${(props) => props.theme.blue};
    height: ${HEADER_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    a {
        color: ${(props) => props.theme.font};
        text-decoration: none;
    }
`;

const Wrapper = styled.div`
    max-width: 900px;
    min-width: 320px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const Column = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled(LogoFontSpan)`
    font-size: 30px;
    a {
        color: ${(props) => props.theme.white};
    }

    @media ${({ theme }) => theme.mobile} {
        font-size: 20px;
    }
`;

const HeaderButton = styled(ButtonSpan)`
    font-size: 17px;
    font-weight: 700;
    font-size: 700;
    margin-left: 30px;
    color: ${(props) => props.theme.white};
    a {
        color: ${(props) => props.theme.white};
    }

    @media ${({ theme }) => theme.mobile} {
        font-size: 13px;
    }
`;

const SNSContainer = styled.div`
    display: flex;
    font-size: 30px;
    margin-left: 20px;
    margin-top: 3px;
    color: ${(props) => props.theme.white};

    @media ${({ theme }) => theme.mobile} {
        font-size: 25px;
        margin-left: 10px;
    }
`;

const SNSButton = styled(ButtonSpan)``;

export function Header() {
    const me = useMember();
    const location = useLocation();

    const [showAccountListPopup, setShowAccountListPopup] = useState(false);

    const onClickNotComptionConsole = () => {
        if (me?.signUpCompleteStep !== SignUpCompleteStep.COMPLETION) {
            alert(
                `ONBI가 플랫폼별 관리번호와 전화번호 인증서류를 확인중에 있습니다.\n이 작업은 최대 72시간이 소요될 수 있고 완료가되면 가입된 휴대폰 번호로 안내문자 드리겠습니다.`,
            );
        }
    };

    const hasSignedInView = () => {
        let result = false;

        if (location.pathname.includes("console")) {
            if (me || isSignedIn()) {
                result = true;
            }
        } else {
            if (me) {
                result = true;
            }
        }

        return result;
    };

    return (
        <SHeader>
            <Wrapper>
                <Column>
                    <Title>
                        <Link to={routes.home}>온비</Link>
                    </Title>
                </Column>
                <Column>
                    {hasSignedInView() ? (
                        <HeaderButton onClick={onClickNotComptionConsole}>
                            {me?.signUpCompleteStep ===
                            SignUpCompleteStep.COMPLETION ? (
                                <Link to={routes.console}>콘솔로 이동</Link>
                            ) : (
                                "콘솔로 이동"
                            )}
                        </HeaderButton>
                    ) : (
                        <HeaderButton>
                            <Link to={routes.consulting}>상담 신청</Link>
                        </HeaderButton>
                    )}

                    {hasSignedInView() ? (
                        getOnbiLoginInfoDatas().length > 0 ? (
                            <HeaderButton
                                onClick={() => setShowAccountListPopup(true)}
                            >
                                계정
                            </HeaderButton>
                        ) : (
                            <HeaderButton onClick={signUserOut}>
                                로그아웃
                            </HeaderButton>
                        )
                    ) : (
                        <HeaderButton>
                            <Link to={routes.signin}>로그인</Link>
                        </HeaderButton>
                    )}
                </Column>
            </Wrapper>
            {showAccountListPopup ? (
                <AccountListPopup
                    didClosePopup={() => setShowAccountListPopup(false)}
                />
            ) : null}
        </SHeader>
    );
}
