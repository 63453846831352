import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { lightTheme } from "../../styles";

const BookingColorContainer = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 100%;
`;

const Inner = styled.div`
    display: flex;

    @media ${({ theme }) => theme.mobile} {
        justify-content: space-around;
    }
`;

const BookingColor = styled.div`
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    width: 100px;
    background-color: green;
    margin: 0px 10px;
`;

export function BookingColorHint() {
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    return (
        <BookingColorContainer>
            {isMobile ? (
                <>
                    <Inner>
                        <BookingColor
                            style={{ backgroundColor: lightTheme.bookingNA }}
                        >
                            네이버
                        </BookingColor>
                        <BookingColor
                            style={{ backgroundColor: lightTheme.bookingSC }}
                        >
                            스페이스클라우드
                        </BookingColor>
                    </Inner>
                    <Inner style={{ marginTop: "5px" }}>
                        <BookingColor
                            style={{ backgroundColor: lightTheme.bookingUsed }}
                        >
                            이용완료
                        </BookingColor>
                        <BookingColor
                            style={{
                                backgroundColor: lightTheme.bookingSchedule,
                            }}
                        >
                            추가된 스케줄
                        </BookingColor>
                    </Inner>
                </>
            ) : (
                <Inner>
                    <BookingColor
                        style={{ backgroundColor: lightTheme.bookingNA }}
                    >
                        네이버
                    </BookingColor>
                    <BookingColor
                        style={{ backgroundColor: lightTheme.bookingSC }}
                    >
                        스페이스 클라우드
                    </BookingColor>
                    <BookingColor
                        style={{ backgroundColor: lightTheme.bookingUsed }}
                    >
                        이용완료
                    </BookingColor>
                    <BookingColor
                        style={{
                            backgroundColor: lightTheme.bookingSchedule,
                        }}
                    >
                        추가된 스케줄
                    </BookingColor>
                </Inner>
            )}
        </BookingColorContainer>
    );
}
