import styled from "styled-components";
import { InfoItemAdditionsGrid } from "./InfoItemAdditionsGrid";
import { InfoItemGrid } from "./InfoItemGrid";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.background};
    padding-left: 30px;
`;

const Title = styled.span`
    margin-top: 30px;
    margin-left: 20px;
    font-size: 35px;
    font-weight: 700;
`;

export function InfoItemsNewPage() {
    return (
        <Container>
            <Title>간편한 요금제</Title>
            <InfoItemGrid />
            <Title style={{ marginTop: "90px" }}>부가서비스</Title>
            <InfoItemAdditionsGrid />
        </Container>
    );
}
