import {
    faCalendarCheck,
    faCalendarDays,
    faEnvelope,
    faKeyboard,
} from "@fortawesome/free-regular-svg-icons";
import { faBed, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 90px 0px;
    user-select: none;

    @media ${({ theme }) => theme.tablet} {
        padding: 40px 0px;
    }
`;

const Title = styled.span`
    font-size: 35px;
    font-weight: 600;
`;

const GridContaier = styled.div`
    display: grid;
    margin-top: 60px;
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: repeat(2, 180px);

    gap: 30px;

    @media ${({ theme }) => theme.tablet} {
        grid-template-columns: repeat(3, 180px);
        grid-template-rows: repeat(2, 170px);
    }

    @media ${({ theme }) => theme.mobile} {
        grid-template-columns: repeat(2, 180px);
        grid-template-rows: repeat(3, 170px);
        gap: 10px;
    }

    @media ${({ theme }) => theme.miniMobile} {
        grid-template-columns: repeat(1, 180px);
        grid-template-rows: repeat(6, 170px);
        gap: 10px;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 5px;
`;
const CardImage = styled.span`
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-size: 30px;
    color: ${(props) => props.theme.blue};
`;
const CardTitle = styled.span`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
`;
const CardText = styled.span`
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 20px;
    text-align: center;
`;

interface ICard {
    icon: JSX.Element;
    title: string;
    text: string;
}

export function InfoCards() {
    const [cards, setCards] = useState<ICard[]>([]);

    useEffect(() => {
        const datas: ICard[] = [];

        datas.push({
            icon: <FontAwesomeIcon icon={faKeyboard} />,
            title: "예약 문자 설정",
            text: "사업주님이 작성하신 예약 안내 문자가 발송됩니다",
        });

        datas.push({
            icon: <FontAwesomeIcon icon={faCalendarDays} />,
            title: "예약 연동",
            text: "여러 예약 플랫폼에 장소를 등록하세요 ONBI가 관리해드립니다",
        });

        datas.push({
            icon: <FontAwesomeIcon icon={faEnvelope} />,
            title: "문자 자동 발송",
            text: "사업주님의 번호로 예약한 사용자에게 문자를 자동으로 발송합니다",
        });

        datas.push({
            icon: <FontAwesomeIcon icon={faCalendarCheck} />,
            title: "예약 관리",
            text: "예약 내역 통합 관리 시스템으로 예약 내역을 한눈에 확인 가능합니다",
        });

        datas.push({
            icon: <FontAwesomeIcon icon={faBed} />,
            title: "24시간 365 운영",
            text: "새벽, 주말 예약 걱정은 NO! 문자 발송 자동 처리가 가능합니다",
        });

        datas.push({
            icon: <FontAwesomeIcon icon={faChartLine} />,
            title: "매출 관리",
            text: "직관적인 매출 지표를 통한 인사이트를 제공합니다",
        });

        setCards(datas);
    }, []);

    return (
        <Container>
            <Title>온비의 주요기능</Title>
            <GridContaier>
                {cards.map((card, index) => {
                    return (
                        <Card key={index}>
                            <CardImage>{card.icon}</CardImage>
                            <CardTitle>{card.title}</CardTitle>
                            <CardText>{card.text}</CardText>
                        </Card>
                    );
                })}
            </GridContaier>
        </Container>
    );
}
