import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SEE_BOOKING_CALENDAR_QUERY } from "../../graphqls/queries";
import { useBookingHeaderHeight } from "../../hooks/useBookingHeaderHeight";
import { useNavigationWidth } from "../../hooks/useNavigationWidth";
import { ButtonSpan, lightTheme } from "../../styles";
import {
    seeBookingCalendar,
    seeBookingCalendarVariables,
    seeBookingCalendar_seeBookingCalendar_bookings,
} from "../../__generated__/seeBookingCalendar";
import { HEADER_HEIGHT } from "../Header";
import {
    MARGIN_LEFT,
    MARGIN_LEFT_NUMBER,
    PRODUCT_TITLE_BORDER,
    PRODUCT_TITLE_HEIGHT,
} from "./BookingsCalendarDay";
import { BookingsCalendarMonthBooking } from "./BookingsCalendarMonthBooking";
import { useNavigate } from "react-router-dom";
import { consoleRoutes } from "../../routes";
import { globalMilliseconds } from "../../global";

const Container = styled.div<{
    navigationWidth: string;
    bookingHeaderHeight: string;
}>`
    display: flex;
    flex-direction: column;
    margin-left: ${MARGIN_LEFT};
    min-width: calc(
        100vw - ${(props) => props.navigationWidth} - ${MARGIN_LEFT}
    );
    min-height: calc(
        100vh - ${HEADER_HEIGHT} - ${(props) => props.bookingHeaderHeight}
    );
`;

const TableScroll = styled.div<{
    navigationWidth: string;
}>`
    overflow-x: overlay;
    overflow-y: hidden;
    max-width: calc(100vw - ${(props) => props.navigationWidth});
`;

const ContentScroll = styled.div<{
    dayWidth: string;
    navigationWidth: string;
    bookingHeaderHeight: string;
}>`
    overflow-x: hidden;
    overflow-y: overlay;
    min-width: ${(props) => props.dayWidth};
    max-height: calc(
        100vh - ${HEADER_HEIGHT} - ${(props) => props.bookingHeaderHeight} -
            ${PRODUCT_TITLE_HEIGHT} - ${PRODUCT_TITLE_BORDER}
    );
`;

const CalendarHeader = styled.div<{ bookingWidth: string }>`
    display: grid;
    grid-template-columns: repeat(7, ${(props) => props.bookingWidth});
`;

const CalendarHeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${PRODUCT_TITLE_HEIGHT};
    border-right: 1px solid ${(props) => props.theme.borderLight};
    border-top: 1px solid ${(props) => props.theme.black};
    border-bottom: 1px solid ${(props) => props.theme.border};
    :nth-child(7) {
        border-right: 0px;
    }
`;

const CalendarHeaderText = styled.span`
    font-size: 15px;
    font-weight: 700;
`;

const CalendarBody = styled.div<{ bookingWidth: string }>`
    display: grid;
    grid-template-columns: repeat(7, ${(props) => props.bookingWidth});
`;

const CalendarBodyContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100px;
    border-right: 1px solid ${(props) => props.theme.borderLight};
    border-bottom: 1px solid ${(props) => props.theme.borderLight};
    :nth-child(7n) {
        border-right: 0px;
    }
`;

const BookingContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
`;

const CalendarBodyDay = styled(ButtonSpan)`
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 700;
`;

interface IBookingsCalendarMonthProps {
    franchiseIndex: number;
    selectedProductIndex: number;
    franchiseName: string;
    useHalfTimeFranchise: boolean;
    selectedProductName: string;
    calendarDates: Date[];
    thisMonthIndex: number;
}

interface ICalendarBookings {
    date: Date;
    bookings: seeBookingCalendar_seeBookingCalendar_bookings[];
}

export function BookingsCalendarMonth({
    franchiseIndex,
    selectedProductIndex,
    franchiseName,
    useHalfTimeFranchise,
    selectedProductName,
    calendarDates,
    thisMonthIndex,
}: IBookingsCalendarMonthProps) {
    const navigationWidth = useNavigationWidth();
    const bookingHeaderHeight = useBookingHeaderHeight();

    const [calendarWeeks, setCalendarWeeks] = useState<string[]>([]);
    const [calendarBookings, setCalendarBookings] = useState<
        ICalendarBookings[]
    >([]);
    const [bookings, setBookings] = useState<
        seeBookingCalendar_seeBookingCalendar_bookings[]
    >([]);

    const bookingWidth = 120;
    const nowDateRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        nowDateRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [nowDateRef, calendarBookings]);

    useEffect(() => {
        const temp: string[] = [];

        temp.push("일요일");
        temp.push("월요일");
        temp.push("화요일");
        temp.push("수요일");
        temp.push("목요일");
        temp.push("금요일");
        temp.push("토요일");

        setCalendarWeeks(temp);
    }, []);

    useEffect(() => {
        setCalendarBookings([]);

        const temp: ICalendarBookings[] = [];

        bookings.map((booking) => {
            if (booking.product.index === selectedProductIndex) {
                calendarDates.map((date) => {
                    const startDate = new Date(Number(booking.startDateUTC));
                    if (startDate.getFullYear() === date.getFullYear()) {
                        if (startDate.getMonth() === date.getMonth()) {
                            if (startDate.getDate() === date.getDate()) {
                                let isPush = false;

                                for (let i = 0; i < temp.length; ++i) {
                                    const cBookings = temp[i];
                                    if (
                                        cBookings.date.getTime() ===
                                        date.getTime()
                                    ) {
                                        cBookings.bookings.push(booking);
                                        isPush = true;
                                        break;
                                    }
                                }

                                if (isPush === false) {
                                    temp.push({ date, bookings: [booking] });
                                }
                            }
                        }
                    }
                });
            }
        });

        setCalendarBookings(temp);
    }, [bookings, selectedProductIndex]);

    const startDate = calendarDates[0];
    let endDate = calendarDates[calendarDates.length - 1];

    endDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        23,
        59,
        59,
        globalMilliseconds(),
    );

    useQuery<seeBookingCalendar, seeBookingCalendarVariables>(
        SEE_BOOKING_CALENDAR_QUERY,
        {
            variables: {
                startDateTime: `${startDate.getTime()}`,
                endDateTime: `${endDate.getTime()}`,
                franchiseIndex,
            },
            onCompleted: (data: seeBookingCalendar) => {
                const {
                    seeBookingCalendar: { ok, error, bookings: seeBookings },
                } = data;

                if (ok) {
                    setBookings([]);
                    let bookingsTemp: seeBookingCalendar_seeBookingCalendar_bookings[] =
                        [];
                    seeBookings?.map((booking) => {
                        if (booking) {
                            let removeIndex = -1;
                            for (let i = 0; i < bookingsTemp.length; ++i) {
                                const bookingTemp = bookingsTemp[i];

                                if (
                                    booking.product.index ===
                                    bookingTemp.product.index
                                ) {
                                    if (
                                        booking.startDateUTC ===
                                        bookingTemp.startDateUTC
                                    ) {
                                        if (
                                            booking.endDateUTC ===
                                            bookingTemp.endDateUTC
                                        ) {
                                            if (bookingTemp.schedule === true) {
                                                removeIndex = i;
                                            }
                                        }
                                    }
                                }
                            }
                            if (removeIndex !== -1) {
                                bookingsTemp = bookingsTemp.filter(
                                    (_, index) => index !== removeIndex,
                                );
                                removeIndex = -1;
                            }

                            bookingsTemp.push(booking);
                        }
                    });
                    setBookings(bookingsTemp);
                } else {
                    alert(error);
                }
            },
            onError: (err) => {},
        },
    );

    const getDateStyle = (d: Date) => {
        let style: React.CSSProperties = { color: lightTheme.black };

        if (d.getDay() % 7 === 0) {
            style = { color: lightTheme.red };
        }

        if (d.getMonth() !== thisMonthIndex) {
            style.opacity = 0.3;
        }

        return style;
    };

    const onClickDate = (date: Date) => {
        navigate(
            `${
                consoleRoutes.bookingsDay
            }?franchise=${franchiseIndex}&dateTime=${date.getTime()}`,
        );
    };

    const dayWidth = `${bookingWidth * 7 + MARGIN_LEFT_NUMBER}px`;

    return (
        <Container
            navigationWidth={navigationWidth}
            bookingHeaderHeight={bookingHeaderHeight}
        >
            <TableScroll navigationWidth={navigationWidth}>
                <CalendarHeader bookingWidth={`${bookingWidth}px`}>
                    {calendarWeeks.map((week, index) => {
                        return (
                            <CalendarHeaderContent key={index}>
                                <CalendarHeaderText
                                    style={
                                        index === 0
                                            ? { color: lightTheme.red }
                                            : undefined
                                    }
                                >
                                    {week}
                                </CalendarHeaderText>
                            </CalendarHeaderContent>
                        );
                    })}
                </CalendarHeader>
                <ContentScroll
                    dayWidth={dayWidth}
                    navigationWidth={navigationWidth}
                    bookingHeaderHeight={bookingHeaderHeight}
                >
                    <CalendarBody bookingWidth={`${bookingWidth}px`}>
                        {calendarDates.map((date, index) => {
                            const isNow = () => {
                                let result = false;

                                const now = new Date();

                                if (date.getFullYear() === now.getFullYear()) {
                                    if (date.getMonth() === now.getMonth()) {
                                        if (date.getDate() === now.getDate()) {
                                            result = true;
                                        }
                                    }
                                }

                                return result;
                            };

                            const getDateName = () => {
                                let result = `${date.getDate()}`;

                                if (isNow()) {
                                    result = result + " (오늘)";
                                }

                                return result;
                            };

                            return (
                                <CalendarBodyContent
                                    key={index}
                                    style={
                                        isNow()
                                            ? {
                                                  background:
                                                      lightTheme.lightBeige,
                                              }
                                            : undefined
                                    }
                                    ref={
                                        isNow() === true &&
                                        calendarBookings.length > 0
                                            ? nowDateRef
                                            : null
                                    }
                                >
                                    <CalendarBodyDay
                                        style={getDateStyle(date)}
                                        onClick={() => {
                                            onClickDate(date);
                                        }}
                                    >
                                        {getDateName()}
                                    </CalendarBodyDay>
                                    <BookingContainer>
                                        {calendarBookings.map((cBooking) => {
                                            if (
                                                date.getTime() ===
                                                cBooking.date.getTime()
                                            ) {
                                                return cBooking.bookings.map(
                                                    (booking, index) => {
                                                        return (
                                                            <BookingsCalendarMonthBooking
                                                                key={index}
                                                                booking={
                                                                    booking
                                                                }
                                                                franchiseIndex={
                                                                    franchiseIndex
                                                                }
                                                                productIndex={
                                                                    selectedProductIndex
                                                                }
                                                                franchiseName={
                                                                    franchiseName
                                                                }
                                                                productName={
                                                                    selectedProductName
                                                                }
                                                                useHalfTimeFranchise={
                                                                    useHalfTimeFranchise
                                                                }
                                                            />
                                                        );
                                                    },
                                                );
                                            }
                                        })}
                                    </BookingContainer>
                                </CalendarBodyContent>
                            );
                        })}
                    </CalendarBody>
                </ContentScroll>
            </TableScroll>
        </Container>
    );
}
