import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { byte } from "../../global";
import { CREATE_CONSULTING_MUTATION } from "../../graphqls/mutations";
import {
    createConsulting,
    createConsultingVariables,
} from "../../__generated__/createConsulting";
import { FormButton } from "../form/FormButton";
import { FormInput, FormInputContainer } from "../form/FormInputContainer";
import { ConsultingPrivacyPopup } from "../popup/ConsultingPrivacyPopup";

interface IConsultingForm {
    onbi_sign_up_name: string;
    onbi_sign_up_phone: string;
    onbi_sign_up_franchise_name: string;
    onbi_sign_up_content: string;
    onbi_sign_up_recommend_franchise_name: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 80px;
    font-size: 13px;
`;

const TextLengthContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
`;

const TextLength = styled.span`
    font-size: 13px;
`;

const TextLengthError = styled.span`
    font-size: 13px;
    color: ${(props) => props.theme.red};
`;

const TextAreaContainer = styled.div``;

const TEXT_MAX_BYTES = 500;

export function SignUpConsultingForm() {
    const {
        register,
        formState: { errors, isValid },
        getValues,
    } = useForm<IConsultingForm>({
        mode: "onChange",
    });

    const [currentBytes, setCurrentBytes] = useState(0);
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);
    const [content, setContent] = useState("");
    const [showConsultingPrivacyPopup, setShowConsultingPrivacyPopup] =
        useState(false);

    const [createConsultingMutaion] = useMutation<
        createConsulting,
        createConsultingVariables
    >(CREATE_CONSULTING_MUTATION, {
        onCompleted: ({
            createConsulting: { ok, error },
        }: createConsulting) => {
            if (ok) {
                alert(
                    "상담이 접수되었습니다.\n빠른 시일내로 아래 연락처로 연락 드리겠습니다.\n\n031-341-8880 또는 010-4965-5072",
                );
                window.location.href = "/";
            } else {
                alert(
                    "죄송합니다. 현재 상담 접수량이 많아 에러가 발생하였습니다.\n010-4965-5072로 문자 남겨주시면 친절히 안내해드리겠습니다.",
                );
            }
        },
        onError: (e) => {
            alert(
                "상담 접수중 에러가 발생하였습니다.\n010-4965-5072로 문자 남겨주시면 친절히 안내해드리겠습니다.",
            );
        },
    });

    useEffect(() => {
        setDisableConfirmButton(!isValid);

        if (currentBytes > TEXT_MAX_BYTES) {
            setDisableConfirmButton(true);
        }
    }, [isValid, currentBytes]);

    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        setCurrentBytes(byte(text));
        setContent(text);
    };

    const sendConsultingValues = () => {
        const name = getValues("onbi_sign_up_name");
        const phone = getValues("onbi_sign_up_phone");
        const franchiseName = getValues("onbi_sign_up_franchise_name");
        const recommendFranchiseName = getValues(
            "onbi_sign_up_recommend_franchise_name",
        );

        createConsultingMutaion({
            variables: {
                name,
                phone,
                franchiseName,
                content,
                recommendFranchiseName,
            },
        });
    };

    return (
        <Container>
            <FormInputContainer
                input={
                    <FormInput
                        {...register("onbi_sign_up_name", {
                            required: true,
                            minLength: {
                                value: 2,
                                message: "최소 2자 이상 입력해주세요.",
                            },
                        })}
                        placeholder="성함"
                    />
                }
                isError={Boolean(errors.onbi_sign_up_name?.message)}
                error={errors.onbi_sign_up_name?.message}
            />
            <FormInputContainer
                input={
                    <FormInput
                        {...register("onbi_sign_up_phone", {
                            required: true,
                            pattern: {
                                value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                                message: "휴대폰 번호를 확인해주세요.",
                            },
                        })}
                        placeholder="상담연락을 받으실 전화번호(01012345678)"
                        type="number"
                    />
                }
                isError={Boolean(errors.onbi_sign_up_phone?.message)}
                error={errors.onbi_sign_up_phone?.message}
            />
            <FormInputContainer
                input={
                    <FormInput
                        {...register("onbi_sign_up_franchise_name", {
                            required: true,
                            minLength: {
                                value: 2,
                                message: "최소 2자 이상 입력해주세요.",
                            },
                        })}
                        placeholder="대표 지점(연습실) 이름"
                    />
                }
                isError={Boolean(errors.onbi_sign_up_franchise_name?.message)}
                error={errors.onbi_sign_up_franchise_name?.message}
            />
            <FormInputContainer
                input={
                    <FormInput
                        {...register("onbi_sign_up_recommend_franchise_name", {
                            required: false,
                        })}
                        placeholder="추천 받으신 연습실(옵션)"
                    />
                }
                isError={Boolean(errors.onbi_sign_up_franchise_name?.message)}
                error={errors.onbi_sign_up_franchise_name?.message}
            />
            <TextAreaContainer>
                <TextLengthContainer>
                    {currentBytes <= TEXT_MAX_BYTES ? (
                        <TextLength>{`${currentBytes} / ${TEXT_MAX_BYTES} Bytes`}</TextLength>
                    ) : (
                        <TextLength>
                            <TextLengthError>
                                {`${currentBytes}`}
                            </TextLengthError>
                            {` / ${TEXT_MAX_BYTES} Bytes`}
                        </TextLength>
                    )}
                </TextLengthContainer>
                <TextArea
                    onChange={onChangeText}
                    placeholder="원하시는 상담 내용을 작성해주세요(옵션)"
                />
            </TextAreaContainer>
            <FormButton
                title="상담신청하기"
                disabled={disableConfirmButton}
                onClick={() => {
                    setShowConsultingPrivacyPopup(true);
                }}
            />
            {showConsultingPrivacyPopup ? (
                <ConsultingPrivacyPopup
                    didSelectOkButton={(ok) => {
                        setShowConsultingPrivacyPopup(false);
                        if (ok) {
                            sendConsultingValues();
                        }
                    }}
                    didClosePopup={() => {
                        setShowConsultingPrivacyPopup(false);
                    }}
                />
            ) : null}
        </Container>
    );
}
