import React from "react";
import styled from "styled-components";

const SelectContainer = styled.div<{ width?: string }>`
    width: ${(props) => (props.width ? (props) => props.width : undefined)};
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.fontDarkGray};
    height: 30px;
    min-width: 50px;
`;
const SelectTitle = styled.span`
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 5px;
`;
const Select = styled.select<{ arrow: boolean }>`
    text-align-last: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 0px 5px;
    border: 0px;
    outline: none;
    -moz-appearance: ${(props) => (props.arrow ? undefined : "none")};
    -webkit-appearance: ${(props) => (props.arrow ? undefined : "none")};
    appearance: ${(props) => (props.arrow ? undefined : "none")};
    color: ${(props) => props.theme.black};
    background-color: ${(props) => props.theme.white};
    font-size: 13px;
`;

const SelectBoxOption = styled.option`
    text-align: center;
`;

interface ISelectBoxProps {
    title?: string;
    key_values: {
        key: string;
        value: string;
    }[];
    onSelectValue?: (
        key_value: { key: string; value: string },
        index: number,
    ) => void;
    selectValue?: string | number | readonly string[] | undefined;
    margin?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    width?: string;
    arrow?: boolean;
}

export default function SelectBox({
    title,
    key_values,
    onSelectValue,
    selectValue,
    margin,
    disabled,
    style,
    width,
    arrow,
}: ISelectBoxProps) {
    return (
        <SelectContainer style={style ? style : { margin }} width={width}>
            {title ? <SelectTitle>{title}</SelectTitle> : null}
            <Select
                disabled={disabled}
                onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const key_value = key_values[selectedIndex];
                    if (onSelectValue !== undefined) {
                        onSelectValue(key_value, selectedIndex);
                    }
                }}
                value={selectValue}
                arrow={arrow === undefined ? true : arrow ? arrow : false}
            >
                {key_values.map(({ key, value }) => (
                    <SelectBoxOption id={key} key={key} defaultValue={value}>
                        {value}
                    </SelectBoxOption>
                ))}
            </Select>
        </SelectContainer>
    );
}
