import styled from "styled-components";

export const FormTitle = styled.span`
    font-weight: 700;
    font-size: 15px;
    padding: 30px 15px;
    max-width: 350px;
    color: ${(props) => props.theme.black};
    line-height: 130%;
    user-select: none;
`;
