import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Footer } from "../components/Footer";
import { Form } from "../components/form/Form";
import { FormContainer } from "../components/form/FormContainer";
import { FormLogo } from "../components/form/FormLogo";
import { FormTitle } from "../components/form/FormTitle";
import { PageTitle } from "../components/PageTitle";
import { SignUpConsultingForm } from "../components/signUp/SignUpConsultingForm";
import { SignUpHostForm } from "../components/signUp/SignUpHostForm";
import useMember from "../hooks/useMember";
import routes from "../routes";
import { SignUpCompleteStep } from "../__generated__/globalTypes";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    flex: 1;
`;

const RootContainer = styled.div`
    display: flex;
    min-height: calc(100vh);
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 20px;
`;

const Logo = styled(FormLogo)`
    cursor: pointer;
    a {
        color: ${(props) => props.theme.blue};
        text-decoration: none;
    }
    margin-top: 15px;
`;

export function SingUp() {
    const me = useMember();
    const location = useLocation();

    const currentTitle = () => {
        let title = "상담을 원하시는 경우 아래 양식을 작성해주세요.";

        if (location.pathname.includes(routes.consulting)) {
            title = "상담을 원하시는 경우 아래 양식을 작성해주세요.";
        } else {
            if (me) {
                const name = me.name;
                switch (me.signUpCompleteStep) {
                    case SignUpCompleteStep.DEFAULT:
                        title = `ONBI를 통한 문자 서비스를 이용하시려면 ${name}님이 사용하시는 플랫폼의 계정 정보가 필요합니다.`;
                        break;
                    case SignUpCompleteStep.HOST:
                        title = `ONBI가 플랫폼별 관리번호와 전화번호 인증서류를 확인중에 있습니다.\n이 작업은 최대 72시간이 소요될 수 있고 완료가되면 가입된 휴대폰 번호로 안내문자 드리겠습니다.`;
                        break;
                }
            }
        }

        return title;
    };

    const currentScreen = () => {
        let screen = <SignUpConsultingForm />;

        if (location.pathname.includes(routes.consulting)) {
            screen = <SignUpConsultingForm />;
        } else {
            if (me) {
                switch (me.signUpCompleteStep) {
                    case SignUpCompleteStep.DEFAULT:
                        screen = <SignUpHostForm />;
                        break;
                    case SignUpCompleteStep.HOST:
                        screen = <></>;
                        break;
                }
            }
        }

        return screen;
    };

    return (
        <RootContainer>
            {location.pathname.includes(routes.consulting) ? (
                <PageTitle title="상담신청" />
            ) : (
                <PageTitle title="회원가입" />
            )}
            <Container>
                <FormContainer>
                    <Header>
                        <Logo>
                            <Link to={routes.home}>ONBI</Link>
                        </Logo>
                    </Header>
                    <FormTitle>{currentTitle()}</FormTitle>
                    <Form>{currentScreen()}</Form>
                </FormContainer>
            </Container>
            <Footer />
        </RootContainer>
    );
}
