import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { isMinimizeNavigationBar, minimizeNavigationBar } from "../apollo";
import { lightTheme } from "../styles";

export function useNavigationWidth() {
    const [navigationWidth, setNavigationWidth] = useState("200px");
    const isMobile = useMediaQuery({ query: lightTheme.mobile });
    let isMinimize = isMinimizeNavigationBar();

    useEffect(() => {
        if (isMinimize === false) {
            if (isMobile) {
                setNavigationWidth("120px");
            } else {
                setNavigationWidth("200px");
            }
        } else {
            setNavigationWidth("20px");
        }
    }, [isMobile, isMinimize]);

    return navigationWidth;
}

export function toggleNavigationMinimize() {
    minimizeNavigationBar({ minimize: !isMinimizeNavigationBar() });
}
