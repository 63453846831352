import styled from "styled-components";
import { lightTheme } from "../../styles";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    gap: 30px;
    border-bottom: 1px solid ${(props) => props.theme.borderLight};
    height: 40px;
    white-space: nowrap;
`;

interface IMessageTableCell {
    header: Boolean;
    messageObj: IMessageObj;
}

export interface IMessageObj {
    empty: boolean;
    create: string;
    to: string;
    status: string;
    messageId: string;
}

const Cell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Text = styled.span`
    max-width: 150px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

//생성일 수신번호 내용 상태
export function MessageTableCell({
    header,
    messageObj: { empty, create, to, status },
}: IMessageTableCell) {
    const getHeaderStyle = () => {
        let style:
            | {
                  fontWeight?: string;
                  color?: string;
                  borderBottom?: string;
              }
            | undefined = undefined;

        if (header) {
            style = {
                fontWeight: "700",
                color: lightTheme.blue,
            };
        }

        if (empty) {
            if (style === undefined) {
                style = {
                    borderBottom: "0px",
                };
            } else {
                style.borderBottom = "0px";
            }
        }

        return style;
    };

    return (
        <Container style={getHeaderStyle()}>
            <Cell>
                <Text>{create}</Text>
            </Cell>
            <Cell>
                <Text>{to}</Text>
            </Cell>
            <Cell>
                <Text>{status}</Text>
            </Cell>
        </Container>
    );
}
