import { useState } from "react";
import styled from "styled-components";
import { ButtonSpan, lightTheme } from "../../styles";
import { BookingStatus, SiteType } from "../../__generated__/globalTypes";
import { seeBookingCalendar_seeBookingCalendar_bookings } from "../../__generated__/seeBookingCalendar";
import { BookingDetailPopup } from "../popup/BookingDetailPopup";
import {
    getBookingHour,
    getBookingMinute,
} from "./BookingsCalendarMonthBooking";
import { AddSchedulePopup } from "../popup/AddSchedulePopup";
import useMember, { useSystem } from "../../hooks/useMember";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Booking = styled(ButtonSpan)`
    font-size: 12px;
    width: 80%;
    text-align: center;
    border-radius: 5px;
    user-select: none;
`;

const EmptyBooking = styled.div`
    width: 100%;
    height: 100%;
    user-select: none;
`;

const BookingText = styled.span`
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
`;

interface IBookingsCalendarDayBookingProps {
    booking?: seeBookingCalendar_seeBookingCalendar_bookings;
    franchiseIndex: number;
    productIndex?: number;
    franchiseName: string;
    productName?: string;
    useHalfTimeFranchise: boolean;
    date: Date;
}

export const bookingBackgroundColor = (
    booking?: seeBookingCalendar_seeBookingCalendar_bookings,
) => {
    let color = undefined;

    if (booking?.schedule === true) {
        color = lightTheme.bookingSchedule;
    } else {
        if (booking?.site === SiteType.SC) {
            color = lightTheme.bookingSC;
        } else if (booking?.site === SiteType.NA) {
            color = lightTheme.bookingNA;
        }

        if (booking?.statusCode === BookingStatus.USED) {
            color = lightTheme.bookingUsed;
        }
    }

    return color;
};

export function BookingsCalendarDayBooking({
    booking,
    franchiseIndex,
    productIndex,
    franchiseName,
    productName,
    useHalfTimeFranchise,
    date,
}: IBookingsCalendarDayBookingProps) {
    const [showBookingDetailPopup, setShowBookingDetailPopup] = useState(false);
    const [selectAddScheduleDate, setSelectAddScheduleDate] =
        useState<Date | null>(null);

    const getName = () => {
        let result = "";

        if (booking?.username) {
            result = booking.username;
            if (
                result === null ||
                result === undefined ||
                result.length === 0
            ) {
                if (booking.schedule) {
                    result = "스케줄";
                }
            }
        }

        return result;
    };

    const getBookingHours = () => {
        let hours = "";
        if (booking?.startDateUTC && booking.endDateUTC) {
            const startDate = new Date(Number(booking.startDateUTC));
            const endDate = new Date(Number(booking.endDateUTC));

            hours = `${getBookingHour(startDate, false)}~${getBookingHour(
                endDate,
                true,
            )}`;
            if (useHalfTimeFranchise === true) {
                hours = `${getBookingHour(startDate, false)}:${String(
                    startDate.getMinutes(),
                ).padStart(2, "0")}~${getBookingHour(
                    endDate,
                    true,
                )}:${getBookingMinute(endDate)}`;
            }
        }

        return hours;
    };

    const onClickEmptyBooking = (date: Date) => {
        setSelectAddScheduleDate(date);
    };

    return (
        <Container>
            {booking ? (
                <Booking
                    style={{ backgroundColor: bookingBackgroundColor(booking) }}
                    onClick={() => setShowBookingDetailPopup(true)}
                >
                    <BookingText>{`${getBookingHours()}`}</BookingText>
                    <BookingText>{`${getName()}`}</BookingText>
                </Booking>
            ) : (
                <EmptyBooking onClick={() => onClickEmptyBooking(date)} />
            )}

            {showBookingDetailPopup &&
            booking !== undefined &&
            productIndex !== undefined &&
            productName !== undefined ? (
                <BookingDetailPopup
                    didClosePopup={() => setShowBookingDetailPopup(false)}
                    booking={booking}
                    franchiseIndex={franchiseIndex}
                    productIndex={productIndex}
                    franchiseName={franchiseName}
                    productName={productName}
                    useHalfTimeFranchise={useHalfTimeFranchise}
                />
            ) : null}
            {selectAddScheduleDate && productIndex !== undefined ? (
                <AddSchedulePopup
                    franchiseIndex={franchiseIndex}
                    startProductIndex={productIndex}
                    franchiseName={franchiseName}
                    productName={productName}
                    date={selectAddScheduleDate}
                    didClosePopup={() => setSelectAddScheduleDate(null)}
                    didSuccessAddSchedule={() => {
                        setSelectAddScheduleDate(null);
                    }}
                    useHalfTimeFranchise={useHalfTimeFranchise}
                    username={""}
                    userPhone={""}
                    startDate={selectAddScheduleDate}
                />
            ) : null}
        </Container>
    );
}
