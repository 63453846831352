import styled from "styled-components";
import { lightTheme } from "../../styles";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
`;

const InnerContainer = styled.div<{ index: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${(props) =>
        props.index % 2 === 0 ? lightTheme.white : lightTheme.background};
`;

const CardContainer = styled.div<{ index: number }>`
    display: flex;
    flex-direction: ${(props) =>
        props.index % 2 === 0 ? "row" : "row-reverse"};
    align-items: center;
    padding: 90px;

    @media ${({ theme }) => theme.tablet} {
        flex-direction: column;
        padding: 40px;
    }
`;

const Card = styled.img`
    width: 427px;
    height: 640px;
    margin: 0px;
    border: 15px solid black;
    border-radius: 5%;

    @media ${({ theme }) => theme.tablet} {
        border: 8px solid black;
        border-radius: 3%;
        width: 320px;
        height: 480px;
    }
`;

const TextContainter = styled.div<{ index: number }>`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: 400px;
    margin: ${(props) =>
        props.index % 2 === 0 ? "0px 0px 0px 90px" : "0px 90px 0px 0px"};

    @media ${({ theme }) => theme.tablet} {
        margin: 0px;
        margin-top: 50px;
        min-width: 320px;
    }
`;
const Title = styled.span`
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 40px;
    min-width: 400px;

    @media ${({ theme }) => theme.tablet} {
        font-size: 25px;
        text-align: center;
        min-width: 320px;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const Content = styled.span`
    font-size: 23px;
    margin: 0px 15px;
    margin-bottom: 20px;
    line-height: 130%;

    @media ${({ theme }) => theme.tablet} {
        font-size: 15px;
        margin: 0px;
        margin-bottom: 20px;
    }
`;

export function InfoHowToUse() {
    const datas = [
        {
            image: require("../../assets/images/home_sms_setting.webp"),
            imageAlt: "문자설정",
            title: "수동으로 보내던 예약 문자 그대로",
            contents: [
                "1회 문자 내용 설정만으로도 모든 지점, 모든 방에 예약하시는 고객들에게 자동으로 문자가 전송됩니다",
                "비밀번호나 공지사항 변경이 필요하신 경우라면 언제든 문자 내용을 변경하여 관리해보세요",
                "각 방 별로 예약 문자 내용을 다르게 설정할 수 있어요",
            ],
        },
        {
            image: require("../../assets/images/home_bookings.webp"),
            imageAlt: "예약관리",
            title: "플랫폼별 예약 확인을 한 눈에",
            contents: [
                "여러 플랫폼의 예약을 한눈에 확인 할 수 있어요",
                "전화로 받는 예약은 온비 [스케줄 추가]를 통해 관리해보세요",
            ],
        },
        {
            image: require("../../assets/images/home_franchise_sales.webp"),
            imageAlt: "매출관리",
            title: "예약 매출을 한 곳에서",
            contents: [
                "모든 지점들의 매출을 쉽고 간편하게 확인해 볼 수 있어요",
                "하나의 지점, 하나의 방, 월별, 일별 통계 까지도 모두 확인이 가능해요",
            ],
        },
    ];
    return (
        <Container>
            {datas.map((data, index) => {
                return (
                    <InnerContainer key={index} index={index}>
                        <CardContainer index={index}>
                            <Card src={data.image} alt={data.imageAlt} />
                            <TextContainter index={index}>
                                <Title>{data.title}</Title>
                                <ContentContainer>
                                    {data.contents.map((content, index) => {
                                        return (
                                            <Content key={index}>
                                                {content}
                                            </Content>
                                        );
                                    })}
                                </ContentContainer>
                            </TextContainter>
                        </CardContainer>
                    </InnerContainer>
                );
            })}
        </Container>
    );
}
