import { Helmet } from "react-helmet-async";

type Props = {
    title: string;
};

export function PageTitle({ title }: Props) {
    return (
        <Helmet>
            <title>{`온비 | ${title}`}</title>
            <meta
                name="description"
                content="온비 | 장소대여 운영자들을 위한 나만의 온라인 비서"
            />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="온비" />
            <meta
                property="og:description"
                content="온비 | 장소대여 운영자들을 위한 나만의 온라인 비서"
            />
            <meta
                property="og:image"
                content="http://www.myonbi.kr/share_thumbnail.webp"
            />
            <meta
                property="og:image:alt"
                content="온비 | 장소대여 운영자들을 위한 나만의 온라인 비서"
            />
            <meta property="og:url" content="http://www.myonbi.kr" />
        </Helmet>
    );
}
