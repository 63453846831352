import { PageTitle } from "../components/PageTitle";
import {
    Base,
    BoldText,
    Container,
    Enter,
    Header,
    TabText,
    Text,
} from "../components/TermsPrivacyStyled";

export function Terms() {
    return (
        <Base>
            <Container>
                <PageTitle title="이용약관" />
                <Header>서비스 이용약관</Header>
                <BoldText>제 1 조 (목적)</BoldText>
                <Text>
                    이 약관은 주식회사 폰티스(이하 "회사")가 제공하는 인터넷
                    서비스인 온비(이하 "서비스")를 이용함에 있어 회사와 이용자
                    (이하 “회원”)의 관리.의무 및 책임사항을 규정함을 목적으로
                    합니다.
                </Text>
                <Enter />
                <BoldText>제 2 조 (이용약관의 효력 및 변경)</BoldText>
                <Text>
                    본 약관은 서비스 메뉴 게시 및 회원에게 공시하므로써 효력이
                    발생합니다.
                </Text>
                <Text>
                    “회사”는 「전자상거래 등에서의 소비자보호에 관한 법률」,
                    「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」,
                    「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
                    정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
                    「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이
                    약관을 개정할 수 있습니다.
                </Text>
                <Text>
                    회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을
                    중단하고 회원등록을 해지할 수 있습니다.
                </Text>
                <Text>
                    본 이용약관은 관계 법령 및 회사의 정책 변경 기타의 사유로
                    인하여 변경될 수 있으며, 회사는 변경 사항에 관하여 게시판에
                    공지할 의무가 있습니다.
                </Text>
                <Enter />
                <BoldText>제 3조 (약관의 적용)</BoldText>
                <Text>
                    본 약관은 회사의 서비스 이용 가입절차 시 공지되며 동의하는
                    동시에 약관이 적용됩니다. 또한 약관에 동의 없이는 가입을 할
                    수 없습니다.
                </Text>
                <Text>
                    본 약관은 회사가 제공하는 개별서비스에 관한 이용안내와 함께
                    적용합니다.
                </Text>
                <Text>
                    이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                    전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에
                    관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의
                    소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
                </Text>
                <Enter />
                <BoldText>제 4조 (용어의 정의)</BoldText>
                <Text>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</Text>
                <Text>
                    문자메세지 서비스 : 회사의 시스템에 의하여 휴대폰으로
                    문자메시지를 보내는 서비스를 의미합니다.
                </Text>
                <Text>
                    이용자ID: 회원의 식별과 서비스이용을 위하여 회원이 선정하고
                    회사가 관리하는 문자와 숫자의 조합을 말합니다.
                </Text>
                <Text>
                    비밀번호: 부여 받은 ID와 일치된 회원임을 확인하고 회원의
                    보안을 위해 회원이 선정한 문자와 숫자의 조합을 말합니다.
                </Text>
                <Text>
                    이용해지: 회사 또는 회원이 서비스 이용을 해지하는 것을
                    의미합니다.
                </Text>
                <Enter />
                <BoldText>제 5조 (회원가입)</BoldText>
                <Text>
                    회원은 서비스가 정한 가입 양식에 따라 회원정보를 기입한 후
                    이 약관에 동의한다는 의사표시를 함으로서 회원가입을
                    신청합니다.
                </Text>
                <Text>
                    회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지
                    않는 한 회원으로 등록합니다.
                </Text>
                <Text>기술상 지장이 없는 경우</Text>
                <Text>등록 내용에 허위, 기재누락, 오기가 있는 경우</Text>
                <Text>기타 회사가 정한 등록신청 요건이 미비 된 경우</Text>
                <Enter />
                <BoldText>
                    제 6조 (회원 탈퇴 및 자격 상실, 서비스 이용제한)
                </BoldText>
                <Text>
                    회원은 서비스에 언제든지 탈퇴를 요청할 수 있으며 서비스는
                    즉시 회원탈퇴를 처리합니다.
                </Text>
                <Text>
                    회원이 다음 각 호의 사유에 해당하는 경우, 서비스는
                    회원자격을 제한 및 정지시킬 수 있습니다.
                </Text>
                <Text>가입 신청 시에 허위 내용을 등록한 경우</Text>
                <Text>타인의 명의를 이용하여 신청한 경우</Text>
                <Text>
                    서비스를 이용하여 법령 또는 이 약관이 금지하는 행위를 하는
                    경우
                </Text>
                <Text>
                    다른 사람의 서비스 이용을 방해하거나, 타인의 명예를
                    훼손하거나 그 정보를 도용하는 등 전자거래 및 정보통신서비스
                    이용질서를 위협하는 경우
                </Text>
                <Text>
                    제공되는 정보를 변경하는 등 회사의 운영을 방해한 경우
                </Text>
                <Enter />
                <BoldText>제 7조 (회원정보의 변경)</BoldText>
                <Text>
                    회원은 이용 신청 시 기재한 회원정보가 변경되었을 경우 즉시
                    온라인으로 직접 수정해야 하며, 자사의 메일 문의
                    (support@pontis.co.kr)를 통해 관리자에게 수정을 요구할 수
                    있습니다. 이때 변경하지 않은 정보로 인해 발생되는 문제는
                    회원에게 책임이 있습니다.
                </Text>
                <Enter />
                <BoldText>제 8조 (개인정보의 보호)</BoldText>
                <Text>
                    서비스는 이용자의 개인정보 수집 시 서비스제공을 위하여
                    필요한 범위에서 개인정보를 수집합니다.
                </Text>
                <Text>
                    서비스는 이용자의 개인정보를 제3자, 문자 전송하는 업체인
                    ‘퍼플북’에게, 예약한 사용자에게 문자 전송하는 목적으로만
                    제공합니다.
                </Text>
                <Text>
                    회사의 개인정보보호와 관련된 보다 자세한 사항은 웹사이트에
                    개시된 개인정보처리방침을 참조하기시 바랍니다.
                </Text>
                <Enter />
                <BoldText>제 9조 (회사의 의무)</BoldText>
                <Text>
                    회사는 법령과 본 약관이 금지하는 행위를 하지 않으며 본
                    약관이 정하는 바에 따라 서비스를 안정적이고 지속적으로
                    제공할 의무가 있습니다.
                </Text>
                <Text>
                    회사는 서비스를 받는 회원간의 상호권익에 대하여 관리자로서의
                    의무가 있습니다.
                </Text>
                <Text>
                    회사는 서비스 제공과 관련된 설비에 장애가 발생할 경우,
                    공지하고 신속히 복구할 의무가 있습니다.
                </Text>
                <Text>
                    회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인
                    정보에 관하여 보호 대책을 수립하고, 성실히 관리할 의무가
                    있습니다.
                </Text>
                <Text>
                    회사는 회원 개인 정보를 본인의 승낙 없이 제3자에게 누설,
                    배포하지 않는 것을 원칙으로 합니다. 단, 아래 사항에 해당하는
                    경우는 예외입니다.
                </Text>
                <Text>
                    문자 발송 업체를 통한 사전에 설정된 예약 문자 자동으로 전송
                </Text>
                <Text>
                    '정보통신망법' 또는 전기통신기본법 등 관련 법률의 규정에
                    의해 국가기관 또는 관련 기관의 요구가 있는 경우
                </Text>
                <Enter />
                <BoldText>제 10조 (회원의 의무)</BoldText>
                <Text>
                    회원은 이 약관에서 규정하는 모든 사항과 서비스 이용안내 및
                    주의사항 등을 준수하여야 합니다.
                </Text>
                <Text>
                    회원은 등록 양식에서 요구되는 내용들에 대해 진실되고
                    정확하며 현재의 사실과 일치되는 완전한 최신의 정보를
                    제공하고 유지합니다.
                </Text>
                <Text>
                    회원은 자신의 ID와 비밀번호를 관리할 의무를 가지고 있으며,
                    그에 대한 회원의 관리 소홀로 인하여 발생하는 모든 결과에
                    대하여는 회원에게 책임이 있습니다.
                </Text>
                <Text>
                    회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                    안되며, ID 및 비밀번호를 도난 당하거나 제3자가 사용하고
                    있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가
                    있는 경우에는 그에 따라야 합니다.
                </Text>
                <Text>
                    회원은 '정보통신망법' 및 기타 관련 법령을 위반하지 않도록
                    준수하여야 합니다.
                </Text>
                <Text>
                    회원은 ‘전기통신사업법 제84조의 2(전화번호의 거짓표시 금지
                    및 이용자 보호)’에 의거 발신번호를 사전에 등록하고 등록된
                    번호로만 문자를 발송하여야 합니다.
                </Text>
                <Text>
                    회원은 발신번호 등록을 위한 증빙 서류 제출 시 사실과 정확히
                    일치하는 정보만을 회사에 제공해야 합니다. 증빙 서류에 거짓된
                    정보가 있을 시 그 책임은 회원에게 있습니다.
                </Text>
                <Text>
                    회원은 회사를 이용함으로써 얻은 정보를 승낙 없이 복제, 전송,
                    출판, 배포, 방송 및 기타 방법에 의하여 상업적으로 이용하거나
                    제3자에게 이용하게 할 수 없습니다.
                </Text>
                <Text>
                    회원은 서비스의 취약점을 이용해 서비스를 악용하거나 회사의
                    운영을 방해한 경우 별도의 경고 조치없이 이용 자격을 상실할
                    수 있고 추후 가입이 불가할 수 있습니다.
                </Text>
                <Enter />
                <BoldText>제 11조 (서비스 이용시간 및 중단)</BoldText>
                <Text>
                    서비스의 이용은 회사가 업무상 또는 기술상 특별한 경우를
                    제외하고는 연중무휴 1일 24시간 동안 제공하는 것을 원칙으로
                    합니다. 다만, 운영상의 목적(서버의 정기점검관리 및 보수
                    등)으로 회사가 정한 기간에는 서비스가 일시 중지될 수
                    있습니다. 이런 경우 회사는 사전 또는 사후 이를 공지합니다.
                </Text>
                <Text>
                    회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수
                    있습니다.
                </Text>
                <Text>- 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</Text>
                <Text>
                    - 기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
                    중지했을 경우
                </Text>
                <Text>
                    - 설비 장애 또는 이용 폭주 등으로 서비스 이용에 지장이 있는
                    경우
                </Text>
                <Enter />
                <BoldText>제 12조 (분쟁해결)</BoldText>
                <Text>
                    회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하여
                    적절하고 빠른 조치를 취합니다
                </Text>
                <Text>
                    회사는 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와
                    처리일정을 통보합니다.
                </Text>
                <Enter />
                <BoldText>제 13조 (손해배상)</BoldText>
                <Text>
                    회사는 회사가 제공하는 서비스의 이용과 관련하여 회사의 고의
                    또는 중대한 과실이 없이 회원에게 발생한 손해에 대해서는
                    책임을 지지 않습니다.
                </Text>
                <Enter />
                <BoldText>제 14조 (환불정책)</BoldText>
                <Text>
                    - (주)폰티스 서비스 중 “나만의 온라인 비서 서비스 (이하
                    온비)“는 이용자의 환불 요청에 대해 상세 확인 후 대금 환급,
                    청약 철회, 거래에 대한 취소 등의 업무를 진행합니다.
                </Text>
                <Text>
                    - 이용자가 온비에 대해 구매 혹은 결제를 시작한 후 해지 혹은
                    환급 요청이 있을 경우 다음 각 호에 해당하는 경우에는 전액
                    또는 일부 환불이 불가할 수 있습니다.
                </Text>
                <TabText>
                    1. 이용자의 귀책 사유(별도의 서비스 해지 신청 또는 거래 중지
                    신청을 하지 않은 경우)로 인해 결제가 이루어진 경우
                </TabText>
                <TabText>
                    2. 이용자가 결제 후 부가세 신고 등의 세무신고가 이미
                    접수되어 완료된 경우
                </TabText>
                <TabText>3. 이용기한이 만료되었을 경우</TabText>
                <TabText>
                    4. 이용자가 거래 후 온비를 이용하였음에도 미사용을 사유로
                    환불 요청을 할 경우
                </TabText>
                <TabText>
                    5. 이용자가 서비스를 악용하여 이용 자격이 상실한 경우
                </TabText>
                <Text>
                    - 이용자의 귀책 사유(별도의 서비스 해지 신청 또는 거래 중지
                    신청을 하지 않은 경우)로 발생하는 손해에 대해서는 회사가
                    책임을 지지 않습니다.
                </Text>
            </Container>
        </Base>
    );
}
