import styled from "styled-components";
import { Input, lightTheme } from "../../styles";
import { Popup } from "./Popup";
import {
    seeAllMemberFranchisesByKakao,
    seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises,
} from "../../__generated__/seeAllMemberFranchisesByKakao";
import { SEE_ALL_MEMBER_FRANCHISES_BY_KAKAO_QUERY } from "../../graphqls/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { SiteType } from "../../__generated__/globalTypes";
import { CheckBoxWithText } from "../CheckBox";
import { FormButton } from "../form/FormButton";
import { useForm } from "react-hook-form";
import useMember from "../../hooks/useMember";
import { byte, evictCacheRootQuery } from "../../global";
import { CREATE_KAKAO_MESSAGE_INFO_MUTATION } from "../../graphqls/mutations";
import {
    createKakaoMessageInfo,
    createKakaoMessageInfoVariables,
} from "../../__generated__/createKakaoMessageInfo";

interface IKakaoMessagePopup {
    didClosePopup: () => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ChannelInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 20px;
`;

const ChannelIDTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const FranchiseContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 20px;
`;

const FranchiseTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    margin-bottom: 5px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Title = styled.span`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 30px;
`;

const SubTitle = styled.span`
    font-size: 15px;
    line-height: 130%;
    font-weight: 600;
`;

const Text = styled.span`
    font-size: 15px;
    line-height: 130%;
`;

const Enter = styled.span`
    padding: 10px;
`;

interface IChannelInfoForm {
    onbi_channel_info_phone: string | null;
    onbi_channel_info_id: string | null;
}

export function KakaoMessageSendPopup({ didClosePopup }: IKakaoMessagePopup) {
    const { register: channelInfoRegister, getValues: getChannelInfoValues } =
        useForm<IChannelInfoForm>({
            mode: "onChange",
        });

    const {
        register,
        formState: { isValid },
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
    });

    const [franchises, setFranchises] = useState<
        seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises[]
    >([]);

    const [settingProducts, setSettingProducts] = useState<String[]>([]);

    const me = useMember();

    const [createKakaoMessageInfoMutation] = useMutation<
        createKakaoMessageInfo,
        createKakaoMessageInfoVariables
    >(CREATE_KAKAO_MESSAGE_INFO_MUTATION, {
        onCompleted: (data) => {
            const {
                createKakaoMessageInfo: { ok, error },
            } = data;

            if (ok) {
                evictCacheRootQuery();
            } else {
                alert(error);
            }
        },
        onError: (err) => {
            alert(err);
        },
    });

    useQuery<seeAllMemberFranchisesByKakao>(
        SEE_ALL_MEMBER_FRANCHISES_BY_KAKAO_QUERY,
        {
            onCompleted: (data) => {
                const {
                    seeAllMemberFranchisesByKakao: { franchises: _franchises },
                } = data;

                const franchises: seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises[] =
                    [];

                _franchises?.map((franchise) => {
                    if (franchise && franchise.site === SiteType.SC) {
                        franchises.push(franchise);
                    }
                });

                setFranchises(franchises);
            },
            onError: (error) => {
                console.log(error);
            },
        },
    );

    const onSettingProductsClick = (franchiseKey: string) => {
        franchises.map((franchise) => {
            setValue(`key.${franchise.key}.value`, "");
            franchise.products.map((product) => {
                setValue(`key.${product.key}.value`, "");
            });
        });

        if (settingProducts.includes(franchiseKey)) {
            setSettingProducts((props) =>
                props.filter((prop) => prop !== franchiseKey),
            );
        } else {
            setSettingProducts((props) => [...props, franchiseKey]);
        }
    };

    const onCompletionClick = () => {
        const phone = getChannelInfoValues("onbi_channel_info_phone");
        const channelId = getChannelInfoValues("onbi_channel_info_id");

        if (!phone) {
            alert("전화번호를 입력해주세요.");
            return;
        }

        if (!channelId) {
            alert("검색용 아이디를 입력해주세요.");
            return;
        }

        const messageList: string[] = [];
        let error = "";

        franchises.map((franchise, index) => {
            let message = `${index + 1}/${
                franchises.length
            }, 카카오톡 전환 요청(${me?.name})`;

            let isChecked = false;

            if (settingProducts.includes(franchise.key) === true) {
                isChecked = true;
            }

            message = message + `\n\n${franchise.name}`;
            if (isChecked === true) {
                message = message + `(방별)`;
                franchise.products.map((product) => {
                    const url = getValues(`key.${product.key}.value`);
                    if (!url) {
                        error = "URL 값을 모두 채워야합니다.";
                    } else {
                        if (typeof url === "string") {
                            if (url.length === 0) {
                                error = "URL 값을 모두 채워야합니다.";
                            } else {
                                if (url.includes("http") === false) {
                                    error = "올바른 URL 형식을 사용해야합니다.";
                                }
                            }
                        }
                    }
                    message = message + `\n\n${product.index}: ${url}`;
                });
            } else {
                message = message + `(지점별)`;

                const url = getValues(`key.${franchise.key}.value`);
                if (!url) {
                    error = "URL 값을 모두 채워야합니다.";
                } else {
                    if (typeof url === "string") {
                        if (url.length === 0) {
                            error = "URL 값을 모두 채워야합니다.";
                        } else {
                            if (url.includes("http") === false) {
                                error = "올바른 URL 형식을 사용해야합니다.";
                            }
                        }
                    }
                }

                message = message + `\n${url}`;
            }

            messageList.push(message);
        });

        if (error.length > 0) {
            alert(error);
        } else {
            sendMessage(messageList, phone, channelId);
        }
    };

    const sendMessage = async (
        messageList: string[],
        phone: string,
        channelId: string,
    ) => {
        await createKakaoMessageInfoMutation({
            variables: {
                message: `[카카오톡 전환 요청] ${me?.name}(${me?.phone})님이 카카오톡 메시지 전환을 요청하였습니다.\n\n채널 가입 전화번호:\n${phone}\n\n검색용 아이디: \n${channelId}\n\n수신될 총 메시지 개수: ${messageList.length}개`,
            },
        });

        messageList.map(async (msg) => {
            await createKakaoMessageInfoMutation({
                variables: {
                    message: msg,
                },
            });
        });

        alert(
            "고객센터에 메시지가 전달되었습니다.\n전환 작업이 완료되면 문자로 다시 안내해드리겠습니다.\n\n(평일 기준 1~3일 소요)",
        );
        didClosePopup();
    };

    return (
        <Popup
            didClosePopup={didClosePopup}
            maxWidth={"350px"}
            disabledBackgrounClickClose={true}
        >
            <Container>
                <Title>고객 센터로 메시지를 전송합니다.</Title>
                <Text style={{ color: lightTheme.red }}>
                    카카오톡 비즈니스 채널 심사가 성공적으로 완료 되어야만
                    전환이 가능합니다.
                </Text>
                <Enter />
                <Text>
                    카카오톡 메시지 전송에 사용할 URL 링크를 각 지점(방)별로
                    채워주세요.
                </Text>
                <Enter />
                <ChannelInfoContainer>
                    <SubTitle>채널 가입 전화번호</SubTitle>
                    <Input
                        {...channelInfoRegister("onbi_channel_info_phone")}
                        style={{ height: "25px" }}
                        placeholder={
                            "채널 가입시 인증했던 전화번호(01012345678)"
                        }
                    />
                    <Enter />
                    <ChannelIDTitleContainer>
                        <SubTitle>채널 검색용 아이디</SubTitle>
                        <a
                            href="https://dotorisoop.notion.site/3-119e3eef525d80bbaa8ecbb64281e156?pvs=4"
                            target="_blank"
                        >
                            Link
                        </a>
                    </ChannelIDTitleContainer>
                    <Input
                        {...channelInfoRegister("onbi_channel_info_id")}
                        style={{ height: "25px" }}
                        placeholder={"검색용 아이디 입력"}
                    />
                </ChannelInfoContainer>
                <Enter />
                {franchises.map((franchise, index) => {
                    let isChecked = false;

                    if (settingProducts.includes(franchise.key) === true) {
                        isChecked = true;
                    }

                    return (
                        <FranchiseContainer key={index}>
                            <FranchiseTitleContainer>
                                <SubTitle>{franchise.name}</SubTitle>
                                <CheckBoxWithText
                                    style={{
                                        padding: "0px",
                                        marginLeft: "30px",
                                        whiteSpace: "nowrap",
                                    }}
                                    isChecked={isChecked}
                                    title="방 별로 설정"
                                    onClick={() => {
                                        onSettingProductsClick(franchise.key);
                                    }}
                                />
                            </FranchiseTitleContainer>

                            <ProductContainer>
                                {isChecked ? (
                                    franchise.products.map((product, index) => {
                                        return (
                                            <Input
                                                {...register(
                                                    `key.${product.key}.value`,
                                                )}
                                                style={{ height: "25px" }}
                                                placeholder={`${product.name}, 예약 안내 URL(https://)`}
                                                key={index}
                                            />
                                        );
                                    })
                                ) : (
                                    <Input
                                        {...register(
                                            `key.${franchise.key}.value`,
                                        )}
                                        style={{ height: "25px" }}
                                        placeholder={`${franchise.name}, 예약 안내 URL`}
                                        key={index}
                                    />
                                )}
                            </ProductContainer>
                        </FranchiseContainer>
                    );
                })}
                <Text>작성이 완료 되셨다면 아래 [전송] 버튼을 눌러주세요</Text>
                <Enter />
                <FormButton
                    title="전송"
                    onClick={onCompletionClick}
                    backgroundColor="#f7e50b"
                    color="#391c1e"
                />
            </Container>
        </Popup>
    );
}
