import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { signUserIn } from "../../apollo";
import {
    CREATE_MEMBER_MUTATION,
    SIGNIN_MEMBER_MUTATION,
} from "../../graphqls/mutations";
import routes from "../../routes";
import { openUrlNewTab } from "../../utils";
import {
    createMember,
    createMemberVariables,
} from "../../__generated__/createMember";
import { SignInType } from "../../__generated__/globalTypes";
import {
    signInMember,
    signInMemberVariables,
} from "../../__generated__/signInMember";
import { FormButton } from "../form/FormButton";
import { FormFooter } from "../form/FormFooter";
import { FormInput, FormInputContainer } from "../form/FormInputContainer";

export const PASSWORD_HINT =
    "영문, 숫자, 특수문자(!@#$%^&*)를 모두 포함해 8자리 이상 입력해주세요.";

const FormLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

interface IDefaultInfoForm {
    onbi_sign_up_name: string;
    onbi_sign_up_phone: string;
    onbi_sign_up_id: string;
    onbi_sign_up_password: string;
    onbi_sign_up_checkPassword: string;
}

export function SignUpDefaultForm() {
    const {
        register,
        formState: { errors, isValid },
        getValues,
    } = useForm<IDefaultInfoForm>({
        mode: "onChange",
    });

    const onCompletedSignIn = (data: signInMember) => {
        const {
            signInMember: { ok, error, token },
        } = data;

        if (ok) {
            if (token) {
                const loginId = getValues("onbi_sign_up_id");
                signUserIn({
                    loginId,
                    token,
                    autoSignIn: false,
                    refresh: false,
                });
            }
        } else {
            alert(error);
        }
    };

    const [signInMutation] = useMutation<signInMember, signInMemberVariables>(
        SIGNIN_MEMBER_MUTATION,
        { onCompleted: onCompletedSignIn },
    );

    const onCompletedCreateMember = (data: createMember) => {
        const {
            createMember: { ok, error },
        } = data;

        if (ok) {
            signInMutation({
                variables: {
                    id: getValues("onbi_sign_up_id"),
                    password: getValues("onbi_sign_up_password"),
                    autoSignIn: false,
                    signInType: SignInType.MEMBER,
                },
            });
        } else {
            alert(error);
        }
    };

    const [createMemberMutation] = useMutation<
        createMember,
        createMemberVariables
    >(CREATE_MEMBER_MUTATION, { onCompleted: onCompletedCreateMember });

    const onClickSignUp = () => {
        const phone = getValues("onbi_sign_up_phone");
        const ok = window.confirm(
            `가입이 완료되면 다음 휴대폰 번호로 지점에 예약한 고객들에게 안내 문자가 전송됩니다. 계속 진행하시겠습니까? \n\n (기존 예약 안내 문자를 개인폰이 아닌 법인폰 등으로 전송하셨다면 해당 번호로 가입해주세요.) \n\n "${phone}"\n`,
        );

        if (ok) {
            createMemberMutation({
                variables: {
                    id: getValues("onbi_sign_up_id"),
                    name: getValues("onbi_sign_up_name"),
                    phone,
                },
            });
        }
    };

    return (
        <>
            <FormInputContainer
                input={
                    <FormInput
                        {...register("onbi_sign_up_name", {
                            required: true,
                            minLength: {
                                value: 2,
                                message: "최소 2자 이상 입력해주세요.",
                            },
                        })}
                        placeholder="이름"
                    />
                }
                isError={Boolean(errors.onbi_sign_up_name?.message)}
                error={errors.onbi_sign_up_name?.message}
            />
            <FormInputContainer
                style={{ marginTop: "30px" }}
                input={
                    <FormInput
                        {...register("onbi_sign_up_phone", {
                            required: true,
                            pattern: {
                                value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                                message: "휴대폰 번호를 확인해주세요.",
                            },
                        })}
                        placeholder="휴대폰 번호(01012345678)"
                        type="number"
                    />
                }
                hint="예약 고객에게 문자 메시지를 전달할 휴대폰 번호를 입력해주세요."
                isError={Boolean(errors.onbi_sign_up_phone?.message)}
                error={errors.onbi_sign_up_phone?.message}
            />
            <FormInputContainer
                style={{ marginTop: "30px" }}
                input={
                    <FormInput
                        {...register("onbi_sign_up_id", {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9]{4,}$/,
                                message:
                                    "영문 및 숫자 조합으로 4자리 이상 입력해주세요.",
                            },
                        })}
                        placeholder="아이디"
                    />
                }
                hint="영문 및 숫자 조합으로 4자리 이상 입력해주세요."
                isError={Boolean(errors.onbi_sign_up_id?.message)}
                error={errors.onbi_sign_up_id?.message}
            />
            <FormInputContainer
                style={{ marginTop: "30px" }}
                input={
                    <FormInput
                        autoComplete="new-password"
                        {...register("onbi_sign_up_password", {
                            required: true,
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                message: PASSWORD_HINT,
                            },
                        })}
                        placeholder="비밀번호"
                        type="password"
                    />
                }
                hint={PASSWORD_HINT}
                isError={Boolean(errors.onbi_sign_up_password?.message)}
                error={errors.onbi_sign_up_password?.message}
            />
            <FormInputContainer
                style={{ marginTop: "10px", marginBottom: "50px" }}
                input={
                    <FormInput
                        autoComplete="new-password"
                        {...register("onbi_sign_up_checkPassword", {
                            required: true,
                            validate: (value) => {
                                return (
                                    value ===
                                        getValues("onbi_sign_up_password") ||
                                    "두 비밀번호가 일치하지 않습니다."
                                );
                            },
                        })}
                        placeholder="비밀번호 확인"
                        type="password"
                    />
                }
                isError={Boolean(errors.onbi_sign_up_checkPassword?.message)}
                error={errors.onbi_sign_up_checkPassword?.message}
            />
            <FormFooter>
                회원가입을 완료하시면 ONBI 서비스의{" "}
                <FormLink onClick={() => openUrlNewTab(routes.terms)}>
                    이용약관
                </FormLink>{" "}
                및{" "}
                <FormLink onClick={() => openUrlNewTab(routes.privacy)}>
                    개인정보 처리방침
                </FormLink>
                에 동의하시게 됩니다.
            </FormFooter>
            <FormButton
                title="계속 진행하기"
                disabled={!isValid}
                onClick={onClickSignUp}
            />
        </>
    );
}
