import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useSystem } from "../../hooks/useMember";
import routes from "../../routes";
import { ButtonSpan, lightTheme } from "../../styles";
import { openUrlNewTab } from "../../utils";
import { InfoItemGrid } from "./InfoItemGrid";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.background};
    padding: 90px;
    height: 870px;

    @media ${({ theme }) => theme.tablet} {
        width: auto;
        height: 200px;
        padding: 20px 10px;
    }
`;

const Title = styled.span`
    font-size: 35px;
    font-weight: 700;
`;

const Text = styled.span`
    margin-top: 20px;
    font-size: 20px;
`;

const TextBlue = styled(Text)`
    color: ${(props) => props.theme.lightBlue};
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const Button = styled(ButtonSpan)`
    color: ${(props) => props.theme.red};
`;

export function InfoItems() {
    const system = useSystem();
    const isTablet = useMediaQuery({ query: lightTheme.tablet });

    return (
        <Container>
            <Title>간편한 요금제</Title>
            <Text>여러개의 장소를 운영해도 문제 없습니다!</Text>
            {system?.freePeriod ? (
                <Text>
                    <TextBlue>{system?.freePeriod}일간 무료 체험</TextBlue>해
                    보시고 결정하세요
                </Text>
            ) : (
                <Text>
                    <TextBlue>무료 체험</TextBlue>해 보시고 결정하세요
                </Text>
            )}

            {isTablet ? (
                <ButtonContainer>
                    <Button onClick={() => openUrlNewTab(routes.saleItems)}>
                        이곳을 눌러 이용 요금 및 부가서비스 확인
                    </Button>
                </ButtonContainer>
            ) : (
                <InfoItemGrid />
            )}
        </Container>
    );
}
