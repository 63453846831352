import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import { ButtonSpan, lightTheme } from "../../styles";

const Container = styled.div`
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* background-color: gray; */
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`;

const HeaderMonth = styled.div`
    margin: 0px 30px;
    font-size: 20px;
    font-weight: 800;
`;

const HeaderButton = styled(ButtonSpan)`
    padding: 10px;
`;

const Input = styled(ButtonSpan)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 800;
`;

const Day = styled.span`
    font-size: 14px;
    font-weight: 400;
`;

interface IOnbiDatePicker {
    startDate: Date;
    isDay: Boolean;
    onChangeDate: (date: Date) => void;
}

export function OnbiDatePicker({
    startDate,
    isDay,
    onChangeDate,
}: IOnbiDatePicker) {
    const CustomInput = forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >
    >(({ value, onClick }, ref) => {
        return (
            <Input onClick={onClick} ref={ref}>
                {value}
            </Input>
        );
    });

    return (
        <Container>
            {isDay ? (
                <DatePicker
                    locale={ko}
                    disabledKeyboardNavigation
                    className="form-control"
                    dropdownMode="select"
                    closeOnScroll={true}
                    selected={startDate}
                    dateFormat="yyyy.MM.dd(eee)"
                    onChange={(date: Date) => onChangeDate(date)}
                    useWeekdaysShort={true}
                    customInput={<CustomInput />}
                    renderDayContents={(day, date) => {
                        let textColor = lightTheme.black;
                        let textWeight = 400;
                        const today = new Date();
                        let text: number | string = day;
                        let opacity = 1;
                        if (date) {
                            if (
                                date.getFullYear() === today.getFullYear() &&
                                date.getMonth() === today.getMonth() &&
                                date.getDate() === today.getDate()
                            ) {
                                textWeight = 900;
                                text = "오늘";
                            }
                            if (date.getDay() === 0) {
                                textColor = lightTheme.red;
                            }
                        }

                        return (
                            <Day
                                style={{
                                    color: textColor,
                                    fontWeight: `${textWeight}`,
                                    opacity: `${opacity}`,
                                }}
                            >{`${text}`}</Day>
                        );
                    }}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                    }) => {
                        const year = getYear(date);
                        const month = getMonth(date) + 1;
                        return (
                            <HeaderContainer>
                                <HeaderButton onClick={decreaseMonth}>
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        size="lg"
                                    />
                                </HeaderButton>
                                <HeaderMonth>{`${year}.${month}`}</HeaderMonth>

                                <HeaderButton onClick={increaseMonth}>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="lg"
                                    />
                                </HeaderButton>
                            </HeaderContainer>
                        );
                    }}
                />
            ) : (
                <Day
                    style={{
                        fontWeight: `900`,
                    }}
                >
                    {`${startDate.getFullYear()}.${String(
                        startDate.getMonth() + 1,
                    ).padStart(2, "0")}`}
                </Day>
            )}
        </Container>
    );
}
