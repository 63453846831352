/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BoardType {
  BOARD = "BOARD",
  BUGREPORT = "BUGREPORT",
  FAQ = "FAQ",
  NOTICE = "NOTICE",
  PRIVACY = "PRIVACY",
  QNA = "QNA",
  TERMS = "TERMS",
}

export enum BookingStatus {
  CANCEL_REFUND = "CANCEL_REFUND",
  CANCEL_SYSTEM = "CANCEL_SYSTEM",
  CONFIRM = "CONFIRM",
  ETC = "ETC",
  USED = "USED",
}

export enum LoginType {
  EMAIL = "EMAIL",
  KAKAO = "KAKAO",
  NAVER = "NAVER",
  NORMAL = "NORMAL",
}

export enum MemberInactiveReason {
  DEFAULT = "DEFAULT",
  EXCEEDED_PAYMENT = "EXCEEDED_PAYMENT",
  EXCEEDED_PHONE_CERT = "EXCEEDED_PHONE_CERT",
  MAL_MEMBER = "MAL_MEMBER",
  WRONG_PAYMENT_INFO = "WRONG_PAYMENT_INFO",
}

export enum MemberLevel {
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
}

export enum MemberStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  STOPPED = "STOPPED",
  UNVERIFIED = "UNVERIFIED",
}

export enum OnbiFileType {
  MESSAGE_FILE = "MESSAGE_FILE",
  PHONE_CERT_FILE = "PHONE_CERT_FILE",
}

export enum ScheduleRepeatType {
  MONTH = "MONTH",
  NONE = "NONE",
  WEEK = "WEEK",
}

export enum SignInType {
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
}

export enum SignUpCompleteStep {
  COMPLETION = "COMPLETION",
  DEFAULT = "DEFAULT",
  FRANCHISE = "FRANCHISE",
  HOST = "HOST",
  ITEM = "ITEM",
  MESSAGE = "MESSAGE",
  PRODUCT = "PRODUCT",
}

export enum SiteType {
  NA = "NA",
  SC = "SC",
}

export interface NameIndexInsertInfo {
  name: string;
  index: number;
}

export interface UploadProp {
  file: any;
  size: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
