import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { lightTheme } from "../styles";

export function useBookingHeaderHeight(extend: boolean = true) {
    const [bookingHeaderHeight, setBookingHeaderHeight] = useState("80px");
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    useEffect(() => {
        if (isMobile) {
            setBookingHeaderHeight("170px");
            if (extend) {
                setBookingHeaderHeight("210px");
            }
        } else {
            setBookingHeaderHeight("110px");
            if (extend) {
                setBookingHeaderHeight("130px");
            }
        }
    }, [isMobile, extend]);

    return bookingHeaderHeight;
}
