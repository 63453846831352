import styled from "styled-components";

export const BookingsSelectorContainer = styled.div<{
    bookingHeaderHeight: string;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${(props) => props.bookingHeaderHeight};
    min-height: ${(props) => props.bookingHeaderHeight};
    max-height: ${(props) => props.bookingHeaderHeight};
    margin: 0px 20px;

    @media ${({ theme }) => theme.mobile} {
        margin: 0px 10px;
    }
`;

export const BookingsSelectorContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BookingSelectorContentInner = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;

    @media ${({ theme }) => theme.mobile} {
        justify-content: space-around;
    }
`;
