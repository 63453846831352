import React from "react";
import { render, hydrate } from "react-dom";
import App from "./App";
const root = document.getElementById("root");

if (root?.hasChildNodes() === true) {
    console.log("root hasChild");
    hydrate(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        root,
    );
} else {
    console.log("root hasnt");
    render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        root,
    );
}
