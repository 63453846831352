import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { UPDATE_MEMBER_PASSWORD_MUTATION } from "../../graphqls/mutations";
import {
    updateMemberPassword,
    updateMemberPasswordVariables,
} from "../../__generated__/updateMemberPassword";
import { Form } from "../form/Form";
import { FormButton } from "../form/FormButton";
import { FormContainer } from "../form/FormContainer";
import { FormInput, FormInputContainer } from "../form/FormInputContainer";
import { PASSWORD_HINT } from "../signUp/SignUpDefaultForm";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 20px;
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 700;
`;

interface IChangePasswordForm {
    onbi_change_password_origin: string | null;
    onbi_change_password: string | null;
    onbi_change_password_check: string | null;
}

export function MemberPasswordChange() {
    const {
        register,
        formState: { errors, isValid },
        getValues,
        setValue,
    } = useForm<IChangePasswordForm>({
        mode: "onChange",
    });

    const [updateMemberPasswordMutation] = useMutation<
        updateMemberPassword,
        updateMemberPasswordVariables
    >(UPDATE_MEMBER_PASSWORD_MUTATION, {
        onCompleted: (data) => {
            const {
                updateMemberPassword: { ok, error },
            } = data;

            if (ok) {
                alert("비밀번호가 변경되었습니다.");
            } else {
                alert(error);
            }

            setValue("onbi_change_password_origin", null);
            setValue("onbi_change_password", null);
            setValue("onbi_change_password_check", null);
        },
    });

    const onClickChangePassword = () => {
        const originPassword = getValues("onbi_change_password_origin");
        const newPassword = getValues("onbi_change_password");
        if (originPassword && newPassword) {
            updateMemberPasswordMutation({
                variables: {
                    originPassword,
                    newPassword,
                },
            });
        } else {
            alert("입력 정보 오류");
        }
    };

    return (
        <Container>
            <Title>ONBI 계정의 비밀번호를 변경합니다.</Title>
            <FormContainer>
                <Form>
                    <FormInputContainer
                        style={{ marginTop: "20px" }}
                        input={
                            <FormInput
                                style={{ borderRadius: "3px" }}
                                autoComplete="new-password"
                                {...register("onbi_change_password_origin", {
                                    required: true,
                                })}
                                placeholder="기존 비밀번호"
                                type="password"
                            />
                        }
                    />
                    <FormInputContainer
                        style={{ marginTop: "30px" }}
                        input={
                            <FormInput
                                style={{ borderRadius: "3px" }}
                                autoComplete="new-password"
                                {...register("onbi_change_password", {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                        message: PASSWORD_HINT,
                                    },
                                })}
                                placeholder="신규 비밀번호"
                                type="password"
                            />
                        }
                        hint={PASSWORD_HINT}
                        isError={Boolean(errors.onbi_change_password?.message)}
                        error={errors.onbi_change_password?.message}
                    />
                    <FormInputContainer
                        style={{ marginTop: "30px", marginBottom: "30px" }}
                        input={
                            <FormInput
                                style={{ borderRadius: "3px" }}
                                autoComplete="new-password"
                                {...register("onbi_change_password_check", {
                                    required: true,
                                    validate: (value) => {
                                        return (
                                            value ===
                                                getValues(
                                                    "onbi_change_password",
                                                ) ||
                                            "두 비밀번호가 일치하지 않습니다."
                                        );
                                    },
                                })}
                                placeholder="신규 비밀번호 확인"
                                type="password"
                            />
                        }
                        isError={Boolean(
                            errors.onbi_change_password_check?.message,
                        )}
                        error={errors.onbi_change_password_check?.message}
                    />
                    <FormButton
                        title="비밀번호 변경"
                        disabled={!isValid}
                        onClick={onClickChangePassword}
                    />
                </Form>
            </FormContainer>
        </Container>
    );
}
