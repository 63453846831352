import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import { evictCacheRootQuery, getAMPM, getHourText } from "../../global";
import { CREATE_ONBI_SCHEDULES_MUTATION } from "../../graphqls/mutations";
import { lightTheme } from "../../styles";
import { KeyValues } from "../../utils";
import {
    createOnbiSchedules,
    createOnbiSchedulesVariables,
} from "../../__generated__/createOnbiSchedules";
import { ScheduleRepeatType } from "../../__generated__/globalTypes";
import { seeFranchises_seeFranchises_franchises } from "../../__generated__/seeFranchises";
import { OnbiDatePicker } from "../datePicker/OnbiDatePicker";
import SelectBox from "../SelectBox";
import { Popup } from "./Popup";
import {
    PopupButton,
    PopupCell,
    PopupCellContentRow,
    PopupCellInput,
    PopupCellInputContainer,
    PopupCellTitle,
} from "./PopupCommon";
import { get24Dates } from "../bookings/BookingsCalendarDay";

const Container = styled.div`
    display: flex;
    width: 300px;
    flex-direction: column;
`;

const DateContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 5px;
`;

const LoadingContainer = styled.div`
    align-self: center;
`;

interface AddSchedulePopupProps {
    didClosePopup: () => void;
    didSuccessAddSchedule: () => void;
    franchises?: seeFranchises_seeFranchises_franchises[];
    franchiseNames?: KeyValues[];
    franchiseIndex: number;
    startProductIndex: number;
    date: Date;
    username?: string | null;
    userPhone?: string | null;
    startDate?: Date;
    franchiseName?: string;
    productName?: string;
    useHalfTimeFranchise?: boolean;
}

interface AddScheduleInputForm {
    schedule_username: string;
    schedule_userPhone: string;
}

export function AddSchedulePopup({
    didClosePopup,
    didSuccessAddSchedule,
    franchises,
    franchiseNames,
    franchiseIndex,
    startProductIndex,
    date,
    username,
    userPhone,
    startDate,
    useHalfTimeFranchise,
    franchiseName,
    productName,
}: AddSchedulePopupProps) {
    const [productNames, setProductNames] = useState<KeyValues[]>([]);
    const [selectedProductIndex, setSelectedProductIndex] =
        useState(startProductIndex);
    const [selectedProductAll, setSeletedProductAll] = useState(false);
    const [currentDate, setCurrentDate] = useState(date);
    const [repeatEndDate, setRepeatEndDate] = useState(date);
    const [startHours, setStartHours] = useState<KeyValues[]>([]);
    const [endHours, setEndHours] = useState<KeyValues[]>([]);
    const [repeats, setRepeats] = useState<KeyValues[]>([]);
    const [selectedRepeat, setSelectedRepeat] = useState(
        ScheduleRepeatType.NONE,
    );
    const [selectedStartIndex, setSelectedStartIndex] = useState(0);
    const [selectedEndIndex, setSelectedEndIndex] = useState(0);
    const [selectedFranchiseIndex, setSelectedFranchiseIndex] =
        useState(franchiseIndex);
    const [createScheduleLoading, setCreateScheduleLoading] = useState(false);

    const { register, getValues, setValue } = useForm<AddScheduleInputForm>({
        mode: "onChange",
    });

    const onCompletedCreateOnbiSchedules = (data: createOnbiSchedules) => {
        setCreateScheduleLoading(false);

        const {
            createOnbiSchedules: { ok, error, message },
        } = data;

        if (ok) {
            evictCacheRootQuery();
            didSuccessAddSchedule();

            if (message && message.length > 0) {
                alert(message);
            }
        } else {
            alert(error);
        }
    };

    const [createOnbiSchedulesMutation] = useMutation<
        createOnbiSchedules,
        createOnbiSchedulesVariables
    >(CREATE_ONBI_SCHEDULES_MUTATION, {
        onCompleted: onCompletedCreateOnbiSchedules,
    });

    let dates = get24Dates(false, currentDate);

    if (franchises) {
        dates = get24Dates(
            franchises[selectedFranchiseIndex].useHalfTimeFranchise,
            currentDate,
        );
    } else if (useHalfTimeFranchise) {
        dates = get24Dates(useHalfTimeFranchise, currentDate);
    }

    useEffect(() => {
        if (username) {
            setValue("schedule_username", username);
        }

        if (userPhone) {
            setValue("schedule_userPhone", userPhone);
        }
    }, [username, userPhone]);

    useEffect(() => {
        if (startDate) {
            if (startHours.length > 0) {
                const value = `${getAMPM(startDate)} ${getHourText(startDate)}`;
                let index = 0;
                for (let i = 0; i < startHours.length; ++i) {
                    const startHour = startHours[i].value;

                    if (startHour === value) {
                        index = i;
                        break;
                    }
                }
                onSelectStartIndex(index);
            }
        }
    }, [startDate, startHours]);

    useEffect(() => {
        setSelectedProductIndex(startProductIndex);
        let temp: KeyValues[] = [];
        if (franchises) {
            const franchise = franchises[selectedFranchiseIndex];
            franchise.products?.map((product) => {
                if (product && product.hideProduct === false) {
                    temp.push({
                        value: product.name,
                        key: `${product.index}`,
                    });
                }
            });

            temp = temp.filter((e, index) => temp.indexOf(e) === index);

            temp.push({
                value: "전체",
                key: "all",
            });

            setProductNames(temp);

            if (temp.length > 0) {
                setSelectedProductIndex(Number(temp[0].key));
            }
        }

        setStartHours([]);
        setEndHours([]);

        dates.map((date, i) => {
            let keyValue: KeyValues = {
                key: `${getAMPM(date)} ${getHourText(date)}`,
                value: `${getAMPM(date)} ${getHourText(date)}`,
            };
            if (date.getHours() === 0) {
                setStartHours((prev) => [...prev, keyValue]);
                if (date.getMinutes() === 30) {
                    setEndHours((prev) => [...prev, keyValue]);
                }
            } else if (dates[dates.length - 1] === date) {
                setStartHours((prev) => [...prev, keyValue]);
                setEndHours((prev) => [...prev, keyValue]);

                const date = new Date(currentDate.getTime());
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                date.setHours(0);

                const keyValue2 = {
                    key: `${getAMPM(date)} 12:00`,
                    value: `${getAMPM(date)} 12:00`,
                };

                setEndHours((prev) => [...prev, keyValue2]);
            } else {
                setStartHours((prev) => [...prev, keyValue]);
                setEndHours((prev) => [...prev, keyValue]);
            }
        });

        for (let i = 0; i <= 24; ++i) {}

        const tempRepeats: KeyValues[] = [];

        let keyValue: KeyValues = {
            key: `${ScheduleRepeatType.NONE}`,
            value: "안함",
        };
        tempRepeats.push(keyValue);

        keyValue = {
            key: `${ScheduleRepeatType.WEEK}`,
            value: "매주 같은 요일",
        };
        tempRepeats.push(keyValue);

        keyValue = {
            key: `${ScheduleRepeatType.MONTH}`,
            value: "매달 같은 날짜",
        };
        tempRepeats.push(keyValue);
        setRepeats(tempRepeats);
    }, [franchises, selectedFranchiseIndex]);

    const onSelectProduct = (key: string) => {
        if (key === "all") {
            setSeletedProductAll(true);
        } else {
            setSeletedProductAll(false);
            setSelectedProductIndex(Number(key));
        }
    };

    const checkHour = (startDate: Date, endDate: Date) => {
        let ok = true;

        if (startDate.getTime() >= endDate.getTime()) {
            ok = false;
        }

        return ok;
    };

    const onSelectFranchise = (key: string) => {
        setProductNames([]);
        setSelectedFranchiseIndex(Number(key));
    };

    const franchiseValue = () => {
        let result = "";
        if (franchiseNames && franchiseNames.length > 0) {
            result = franchiseNames[selectedFranchiseIndex].value;
        }

        return result;
    };

    const getDate = (dates: KeyValues[], index: number) => {
        if (dates.length <= index) {
            console.log("error!!!!!", dates.length, index);
        }

        const values = dates[index].value.split(" ");

        const AMPM = values[0];
        const hourMin = values[values.length - 1];
        let hour = Number(hourMin.split(":")[0]);
        const min = Number(hourMin.split(":")[hourMin.split(":").length - 1]);

        if (AMPM === "오후" && hour !== 12) {
            hour = hour + 12;
        } else if (AMPM === "오전") {
            if (hour === 12) {
                hour = 0;
            }
        }

        const date = new Date(currentDate.getTime());
        date.setMinutes(min);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setHours(hour);

        if (dates === endHours) {
            if (hour === 0 && min === 0) {
                date.setDate(date.getDate() + 1);
            }
        }

        return date;
    };

    const onSelectStartIndex = (index: number) => {
        setSelectedStartIndex(index);
        if (
            checkHour(
                getDate(startHours, index),
                getDate(endHours, selectedEndIndex),
            ) === false
        ) {
            setSelectedEndIndex(index);
        }
    };

    const onSelectEndIndex = (index: number) => {
        setSelectedEndIndex(index);

        if (
            checkHour(
                getDate(startHours, selectedStartIndex),
                getDate(endHours, index),
            ) === false
        ) {
            alert("종료 시간이 시작 시간보다 크거나 같을 순 없습니다.");
            setSelectedStartIndex(index);
        }
    };

    const onSelectStartDate = (date: Date) => {
        setCurrentDate(date);
        if (date.getTime() > repeatEndDate.getTime()) {
            setRepeatEndDate(date);
        }
    };

    const onSelectEndDate = (date: Date) => {
        if (date.getTime() < currentDate.getTime()) {
            alert("종료 날짜가 시작 날짜보다 이전일 수 없습니다.");
        } else {
            setRepeatEndDate(date);
        }
    };

    const onSelectRepeat = (key: string) => {
        setSelectedRepeat(key as ScheduleRepeatType);
    };

    const selectedProductValue = () => {
        let result = "";
        if (selectedProductAll === true) {
            result = productNames[productNames.length - 1].value;
        } else {
            if (productNames.length > 0) {
                for (let i = 0; i < productNames.length; ++i) {
                    const productName = productNames[i];

                    if (Number(productName.key) === selectedProductIndex) {
                        result = productName.value;
                        break;
                    }
                }
            }
        }

        return result;
    };

    const selectedStartHourValue = () => {
        let result = "";
        startHours.map((keyValue, index) => {
            if (index === selectedStartIndex) {
                result = keyValue.value;
            }
        });

        return result;
    };

    const selectedEndHourValue = () => {
        let result = "";
        endHours.map((keyValue, index) => {
            if (index === selectedEndIndex) {
                result = keyValue.value;
            }
        });

        return result;
    };

    const selectedRepeatValue = () => {
        let result = "";

        repeats.map((keyValue) => {
            if (keyValue.key === selectedRepeat) {
                result = keyValue.value;
            }
        });

        return result;
    };

    const onClickAddSchedule = () => {
        if (createScheduleLoading === false) {
            setCreateScheduleLoading(true);
            const username = getValues("schedule_username");
            const userPhone = getValues("schedule_userPhone");

            const _startDate = getDate(startHours, selectedStartIndex);
            const _endDate = getDate(endHours, selectedEndIndex);

            let startHalf = false;
            let endHalf = false;

            if (_startDate.getMinutes() !== 0) {
                startHalf = true;
            }

            if (_endDate.getMinutes() !== 0) {
                endHalf = true;
            }

            let startHour = _startDate.getHours();
            let endHour = _endDate.getHours();

            if (_startDate.getDate() !== _endDate.getDate()) {
                endHour = 24;
            }

            createOnbiSchedulesMutation({
                variables: {
                    franchiseIndex: selectedFranchiseIndex,
                    productIndex: selectedProductIndex,
                    allRooms: selectedProductAll,
                    repeatStartDateTime: String(currentDate.getTime()),
                    startHour,
                    startHalf,
                    endHour,
                    endHalf,
                    username,
                    userPhone,
                    repeatType: selectedRepeat,
                    repeatEndDateTime: String(repeatEndDate.getTime()),
                },
            });
        }
    };

    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                {franchiseName ? (
                    <PopupCell>
                        <PopupCellTitle>지점</PopupCellTitle>
                        <PopupCellContentRow>
                            {`${franchiseName}` +
                                `${productName ? `(${productName})` : ""}`}
                        </PopupCellContentRow>
                    </PopupCell>
                ) : null}
                {franchises && franchiseNames ? (
                    <>
                        <PopupCell>
                            <PopupCellTitle>지점</PopupCellTitle>
                            <PopupCellContentRow>
                                <SelectBox
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        margin: "0px",
                                        borderColor: lightTheme.border,
                                    }}
                                    key_values={franchiseNames}
                                    selectValue={franchiseValue()}
                                    onSelectValue={(keyValue) => {
                                        onSelectFranchise(keyValue.key);
                                    }}
                                />
                            </PopupCellContentRow>
                        </PopupCell>
                        <PopupCell>
                            <PopupCellTitle>방</PopupCellTitle>
                            <PopupCellContentRow>
                                <SelectBox
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        margin: "0px",
                                        borderColor: lightTheme.border,
                                    }}
                                    key_values={productNames}
                                    selectValue={selectedProductValue()}
                                    onSelectValue={(keyValue) => {
                                        onSelectProduct(keyValue.key);
                                    }}
                                />
                            </PopupCellContentRow>
                        </PopupCell>
                        <PopupCell>
                            <PopupCellTitle>반복</PopupCellTitle>
                            <PopupCellContentRow>
                                <SelectBox
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        margin: "0px",
                                        marginLeft: "2px",
                                        borderColor: lightTheme.border,
                                    }}
                                    key_values={repeats}
                                    selectValue={selectedRepeatValue()}
                                    onSelectValue={(keyValue) => {
                                        onSelectRepeat(keyValue.key);
                                    }}
                                />
                            </PopupCellContentRow>
                        </PopupCell>
                        <PopupCell>
                            <PopupCellTitle>적용날짜</PopupCellTitle>
                            <PopupCellContentRow>
                                <DateContainer>
                                    <OnbiDatePicker
                                        startDate={currentDate}
                                        isDay={true}
                                        onChangeDate={(date) =>
                                            onSelectStartDate(date)
                                        }
                                    />
                                </DateContainer>
                            </PopupCellContentRow>
                        </PopupCell>
                    </>
                ) : null}

                <PopupCell>
                    <PopupCellTitle>적용시간</PopupCellTitle>
                    <PopupCellContentRow>
                        <SelectBox
                            style={{
                                height: "100%",
                                width: "46%",
                                margin: "0px",
                                marginRight: "2px",
                                borderColor: lightTheme.border,
                            }}
                            key_values={startHours}
                            selectValue={selectedStartHourValue()}
                            onSelectValue={(_, selectedIndex) => {
                                onSelectStartIndex(selectedIndex);
                            }}
                        />
                        ~
                        <SelectBox
                            style={{
                                height: "100%",
                                width: "46%",
                                margin: "0px",
                                marginLeft: "2px",
                                borderColor: lightTheme.border,
                            }}
                            key_values={endHours}
                            selectValue={selectedEndHourValue()}
                            onSelectValue={(_, index) => {
                                onSelectEndIndex(index);
                            }}
                        />
                    </PopupCellContentRow>
                </PopupCell>

                {selectedRepeat !== ScheduleRepeatType.NONE ? (
                    <PopupCell>
                        <PopupCellTitle>종료날짜</PopupCellTitle>
                        <PopupCellContentRow>
                            <DateContainer>
                                <OnbiDatePicker
                                    startDate={repeatEndDate}
                                    isDay={true}
                                    onChangeDate={(date) =>
                                        onSelectEndDate(date)
                                    }
                                />
                            </DateContainer>
                        </PopupCellContentRow>
                    </PopupCell>
                ) : null}

                <PopupCell>
                    <PopupCellTitle>예약자명</PopupCellTitle>
                    <PopupCellContentRow>
                        <PopupCellInputContainer>
                            <PopupCellInput
                                {...register("schedule_username")}
                                placeholder="옵션"
                            ></PopupCellInput>
                        </PopupCellInputContainer>
                    </PopupCellContentRow>
                </PopupCell>
                <PopupCell>
                    <PopupCellTitle>연락처</PopupCellTitle>
                    <PopupCellContentRow>
                        <PopupCellInputContainer>
                            <PopupCellInput
                                {...register("schedule_userPhone")}
                                placeholder="옵션"
                            ></PopupCellInput>
                        </PopupCellInputContainer>
                    </PopupCellContentRow>
                </PopupCell>
                <PopupButton
                    disabled={createScheduleLoading}
                    onClick={onClickAddSchedule}
                >
                    스케줄 추가하기
                </PopupButton>
                {createScheduleLoading ? (
                    <LoadingContainer>
                        <RotatingLines
                            strokeColor="black"
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="20"
                            visible={createScheduleLoading}
                        />
                    </LoadingContainer>
                ) : null}
            </Container>
        </Popup>
    );
}
