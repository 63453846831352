import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { ButtonSpan } from "../../styles";
import { OnbiDatePicker } from "../datePicker/OnbiDatePicker";
import { DateColloct } from "./DateSwitch";

const DatePickerContainer = styled.div`
    display: flex;
    height: 100%;
    width: 300px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 8px;
`;

const DatePickerButton = styled(ButtonSpan)`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    color: ${(props) => props.theme.blue};
`;

interface IDateSelectorProps {
    dateCollector: string;
    date: Date;
    style?: React.CSSProperties;
    onChangeDate: (date: Date) => void;
}

export function DateSelector({
    dateCollector,
    date,
    onChangeDate,
    style,
}: IDateSelectorProps) {
    if (dateCollector === DateColloct.Calendar) {
        date.setDate(1);
    }

    const onClickDateLeft = () => {
        const newDate = new Date(date.getTime());

        if (dateCollector === DateColloct.Day) {
            newDate.setDate(newDate.getDate() - 1);
        } else if (dateCollector === DateColloct.Calendar) {
            newDate.setMonth(newDate.getMonth() - 1);
        }

        onChangeDate(newDate);
    };

    const onClickDateRight = () => {
        const newDate = new Date(date.getTime());

        if (dateCollector === DateColloct.Day) {
            newDate.setDate(newDate.getDate() + 1);
        } else if (dateCollector === DateColloct.Calendar) {
            newDate.setMonth(newDate.getMonth() + 1);
        }

        onChangeDate(newDate);
    };

    return (
        <DatePickerContainer style={style}>
            <DatePickerButton onClick={onClickDateLeft}>
                <FontAwesomeIcon icon={faCaretLeft} size="lg" />
            </DatePickerButton>

            <OnbiDatePicker
                startDate={date}
                isDay={dateCollector === DateColloct.Day ? true : false}
                onChangeDate={(date) => onChangeDate(date)}
            />
            <DatePickerButton onClick={onClickDateRight}>
                <FontAwesomeIcon icon={faCaretRight} size="lg" />
            </DatePickerButton>
        </DatePickerContainer>
    );
}
