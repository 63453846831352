import { gql } from "@apollo/client";

export const CREATE_MEMBER_MUTATION = gql`
    mutation createMember($id: String!, $name: String!, $phone: String!) {
        createMember(id: $id, name: $name, phone: $phone) {
            ok
            error
        }
    }
`;

export const SIGNIN_MEMBER_MUTATION = gql`
    mutation signInMember(
        $id: String!
        $password: String!
        $autoSignIn: Boolean!
        $signInType: SignInType!
    ) {
        signInMember(
            id: $id
            password: $password
            autoSignIn: $autoSignIn
            signInType: $signInType
        ) {
            ok
            error
            token
        }
    }
`;

export const CREATE_UPDATE_MEMBER_PAYMENT_INFO_MUTATION = gql`
    mutation createUpdateMemberPaymentInfo(
        $saleItemKey: String
        $name: String
        $businessNumber: String
        $businessName: String
        $bossName: String
        $email: String
        $updateCost: Boolean!
    ) {
        createUpdateMemberPaymentInfo(
            saleItemKey: $saleItemKey
            name: $name
            businessNumber: $businessNumber
            businessName: $businessName
            bossName: $bossName
            email: $email
            updateCost: $updateCost
        ) {
            ok
            error
        }
    }
`;

export const CREATE_HOST_MUTATION = gql`
    mutation createHost(
        $loginId: String
        $site: SiteType!
        $loginPassword: String
        $provider: LoginType
        $representativeLoginId: String
        $isInactive: Boolean
    ) {
        createHost(
            loginId: $loginId
            site: $site
            loginPassword: $loginPassword
            provider: $provider
            representativeLoginId: $representativeLoginId
            isInactive: $isInactive
        ) {
            ok
            error
        }
    }
`;

export const UPDATE_SIGN_UP_COMPLETE_STEP_MUTATION = gql`
    mutation updateMemberSignUpCompletionStep($step: SignUpCompleteStep!) {
        updateMemberSignUpCompletionStep(step: $step) {
            ok
            error
            step
            memberKey
        }
    }
`;

export const CREATE_FRANCHISE_MUTATION = gql`
    mutation createFranchise($nameIndexes: [NameIndexInsertInfo]!) {
        createFranchise(nameIndexes: $nameIndexes) {
            ok
            error
        }
    }
`;

export const CREATE_PRODUCT_MUTATION = gql`
    mutation createProduct(
        $nameIndexes: [NameIndexInsertInfo]!
        $franchiseIndex: Int!
    ) {
        createProduct(
            nameIndexes: $nameIndexes
            franchiseIndex: $franchiseIndex
        ) {
            ok
            error
        }
    }
`;

export const DELETE_ALL_PRODUCT_MUTATION = gql`
    mutation deleteAllProduct {
        deleteAllProduct {
            ok
            error
        }
    }
`;

export const DELETE_ALL_FRANCHISE_MUTATION = gql`
    mutation deleteAllFranchise {
        deleteAllFranchise {
            ok
            error
        }
    }
`;

export const DELETE_MEMBER_MUTATION = gql`
    mutation deleteMember {
        deleteMember {
            ok
            error
        }
    }
`;

export const DELETE_MEMBER_PAYMENT_INFO_MUTATION = gql`
    mutation deleteMemberPaymentInfo {
        deleteMemberPaymentInfo {
            ok
            error
        }
    }
`;

export const DELETE_ALL_HOST_MUTATION = gql`
    mutation deleteAllHost {
        deleteAllHost {
            ok
            error
        }
    }
`;

export const UPDATE_PRODUCT_MESSAGE_MUTATION = gql`
    mutation updateProductMessage(
        $isProductMsg: Boolean!
        $franchiseIndex: Int!
        $messages: [String]!
        $sendToUserUpdatedMsg: Boolean!
        $productIndex: Int
    ) {
        updateProductMessage(
            isProductMsg: $isProductMsg
            franchiseIndex: $franchiseIndex
            messages: $messages
            sendToUserUpdatedMsg: $sendToUserUpdatedMsg
            productIndex: $productIndex
        ) {
            ok
            error
            didUpdateStep
            result
        }
    }
`;

export const UPDATE_MEMBER_ACTIVE_MUTATION = gql`
    mutation updateMemberActive {
        updateMemberActive {
            ok
            error
        }
    }
`;

export const CREATE_ONBI_SCHEDULES_MUTATION = gql`
    mutation createOnbiSchedules(
        $franchiseIndex: Int!
        $allRooms: Boolean!
        $repeatStartDateTime: String!
        $startHour: Int!
        $startHalf: Boolean
        $endHour: Int!
        $endHalf: Boolean
        $repeatType: ScheduleRepeatType!
        $productIndex: Int
        $username: String
        $userPhone: String
        $memo: String
        $repeatEndDateTime: String
    ) {
        createOnbiSchedules(
            franchiseIndex: $franchiseIndex
            allRooms: $allRooms
            repeatStartDateTime: $repeatStartDateTime
            startHour: $startHour
            startHalf: $startHalf
            endHour: $endHour
            endHalf: $endHalf
            repeatType: $repeatType
            productIndex: $productIndex
            username: $username
            userPhone: $userPhone
            memo: $memo
            repeatEndDateTime: $repeatEndDateTime
        ) {
            error
            message
            ok
        }
    }
`;

export const DELETE_ONBI_SCHEDULE_MUTATION = gql`
    mutation deleteOnbiSchedule(
        $franchiseIndex: Int!
        $productIndex: Int!
        $startDateKST: String!
        $endDateKST: String!
    ) {
        deleteOnbiSchedule(
            franchiseIndex: $franchiseIndex
            productIndex: $productIndex
            startDateKST: $startDateKST
            endDateKST: $endDateKST
        ) {
            ok
            error
        }
    }
`;

export const UPDATE_ADD_MEMBER_ITEM_COUNT = gql`
    mutation updateAddMemberItemCount($addItemCount: Int!) {
        updateAddMemberItemCount(addItemCount: $addItemCount) {
            ok
            error
        }
    }
`;

export const UPDATE_MEMBER_PASSWORD_MUTATION = gql`
    mutation updateMemberPassword(
        $originPassword: String!
        $newPassword: String!
    ) {
        updateMemberPassword(
            originPassword: $originPassword
            newPassword: $newPassword
        ) {
            ok
            error
        }
    }
`;

export const UPDATE_SYSTEM_CANCEL_REG_SCHEDULE_BOOKING__MUTATION = gql`
    mutation updateSystemCancelRegScheduleBooking(
        $sysCancelCauseScheuldKey: String!
    ) {
        updateSystemCancelRegScheduleBooking(
            sysCancelCauseScheuldKey: $sysCancelCauseScheuldKey
        ) {
            ok
            error
        }
    }
`;

export const UPLOAD_FILES_MUTATION = gql`
    mutation awsUploadFiles(
        $fileProps: [UploadProp!]!
        $fileType: OnbiFileType!
    ) {
        awsUploadFiles(fileProps: $fileProps, fileType: $fileType) {
            ok
            error
        }
    }
`;

export const CREATE_CONSULTING_MUTATION = gql`
    mutation createConsulting(
        $name: String!
        $phone: String!
        $franchiseName: String!
        $content: String
        $recommendFranchiseName: String
    ) {
        createConsulting(
            name: $name
            phone: $phone
            franchiseName: $franchiseName
            content: $content
            recommendFranchiseName: $recommendFranchiseName
        ) {
            ok
            error
        }
    }
`;

export const WITHDRAWAL_MEMBER_MUTATION = gql`
    mutation withdrawalMember {
        withdrawalMember {
            ok
            error
        }
    }
`;

export const UPDATE_SCHEDULE_INFO_MUTATION = gql`
    mutation updateScheduleInfo(
        $key: String!
        $username: String
        $userPhone: String
        $memo: String
    ) {
        updateScheduleInfo(
            key: $key
            username: $username
            userPhone: $userPhone
            memo: $memo
        ) {
            ok
            error
        }
    }
`;

export const UPDATE_HIDE_PRODUCT_MUTATION = gql`
    mutation updateHideProduct($productKey: String!, $hide: Boolean!) {
        updateHideProduct(productKey: $productKey, hide: $hide) {
            ok
            error
        }
    }
`;

export const CREATE_KAKAO_MESSAGE_INFO_MUTATION = gql`
    mutation createKakaoMessageInfo($message: String!) {
        createKakaoMessageInfo(message: $message) {
            ok
            error
        }
    }
`;

export const CREATE_KAKAO_CUSTOM_BOOKING_MESSAGE_MUTATION = gql`
    mutation createKakaoCustomBookingMessage(
        $productKey: String!
        $templateId: String!
        $isProductMessage: Boolean!
        $franchiseIndex: Int!
        $memberKey: String
    ) {
        createKakaoCustomBookingMessage(
            productKey: $productKey
            templateId: $templateId
            isProductMessage: $isProductMessage
            franchiseIndex: $franchiseIndex
            memberKey: $memberKey
        ) {
            ok
            error
        }
    }
`;

export const CREATE_KAKAO_BOOKING_MESSAGE_MUTATION = gql`
    mutation createKakaoBookingMessage(
        $productKey: String!
        $url: String!
        $isProductMessage: Boolean!
        $franchiseIndex: Int!
        $memberKey: String
    ) {
        createKakaoBookingMessage(
            productKey: $productKey
            url: $url
            isProductMessage: $isProductMessage
            franchiseIndex: $franchiseIndex
            memberKey: $memberKey
        ) {
            ok
            error
        }
    }
`;

export const UPDATE_KAKAO_BOOKING_MESSAGE_OPTION_MUTATION = gql`
    mutation updateKakaoBookingMessageOption(
        $useCustomMessage: Boolean
        $memberKey: String
    ) {
        updateKakaoBookingMessageOption(
            useCustomMessage: $useCustomMessage
            memberKey: $memberKey
        ) {
            ok
            error
        }
    }
`;

export const RESEND_FRANCHISE_BOOKING_MESSAGES_MUTATION = gql`
    mutation resendFranchiseBookingMessages($franchiseIndex: Int!) {
        resendFranchiseBookingMessages(franchiseIndex: $franchiseIndex) {
            ok
            error
            result
        }
    }
`;

export const RESEND_PRODUCT_BOOKING_MESSAGES_MUTATION = gql`
    mutation resendProductBookingMessages(
        $franchiseIndex: Int!
        $productIndex: Int!
    ) {
        resendProductBookingMessages(
            productIndex: $productIndex
            franchiseIndex: $franchiseIndex
        ) {
            ok
            error
            result
        }
    }
`;
