import { client } from "./apollo";
import { SignUpCompleteStep } from "./__generated__/globalTypes";

var _globalMilliseconds = 0;

export const globalMilliseconds = () => {
    return _globalMilliseconds;
}

export const isMemberSignUp = (step: SignUpCompleteStep) => {
    let result = true;
    if (step === SignUpCompleteStep.COMPLETION) {
        result = false;
    }

    return result;
};

export const updateCacheMemberSingUpStep = (
    step: SignUpCompleteStep,
    memberKey: string,
) => {
    const { cache } = client;
    cache.modify({
        id: `Member: ${memberKey}`,
        fields: {
            signUpCompleteStep() {
                return step;
            },
        },
    });
};

export const evictCacheRootQuery = () => {
    try {
        const { cache } = client;

        cache.evict({
            id: 'ROOT_QUERY',
        });

        const date = new Date();
        _globalMilliseconds = date.getMilliseconds();

    } catch (error) {
        console.error('Error evicting cache:', error);
    }
};

export const getAMPM = (date: Date) => {
    let result = "오후";
    if (date.getHours() < 12) {
        result = "오전";
    }

    return result;
};

export const getHourText = (date: Date) => {
    const hour = date.getHours() % 12;
    let text = `${hour}`;

    if (hour === 0) {
        text = `12`;
    }

    text = text + `:${String(date.getMinutes()).padStart(2, "0")}`;

    return text;
};

export const getDayText = (day: number) => {
    let text = "일";
    switch (day) {
        case 0:
            text = "일";
            break;
        case 1:
            text = "월";
            break;
        case 2:
            text = "화";
            break;
        case 3:
            text = "수";
            break;
        case 4:
            text = "목";
            break;
        case 5:
            text = "금";
            break;
        case 6:
            text = "토";
            break;
    }

    return text;
};

export const toWon = (cost: number) => {
    const result = `${cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`;
    return result;
};

export const toWonWithoutWonText = (cost: number) => {
    const result = `${cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return result;
};

export const byte = (text: string) => {
    let byte = 0;
    for (var i = 0; i < text.length; ++i) {
        // 기본 한글 2바이트 처리 
        text.charCodeAt(i) > 127 ? (byte += 2) : byte++;
    }
    return byte;
};

export const rand = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
