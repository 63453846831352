import { useMutation, useQuery } from "@apollo/client";
import {
    seeFranchises,
    seeFranchisesVariables,
    seeFranchises_seeFranchises_franchises,
} from "../../__generated__/seeFranchises";
import { SEE_FRANCHISES_QUERY } from "../../graphqls/queries";
import { useState } from "react";
import styled from "styled-components";
import { ButtonSpan } from "../../styles";
import { UPDATE_HIDE_PRODUCT_MUTATION } from "../../graphqls/mutations";
import {
    updateHideProduct,
    updateHideProductVariables,
} from "../../__generated__/updateHideProduct";
import { evictCacheRootQuery } from "../../global";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const FranchiseContainer = styled.div`
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
`;

const HideContainer = styled.div`
    margin-bottom: 20px;
`;

const HideButton = styled(ButtonSpan)`
    margin-left: 20px;
    padding: 5px;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.blue};
    border-radius: 5px;
`;

const Text = styled.span``;

export const UpdateHideProducts = () => {
    const [franchises, setFranchises] = useState<
        seeFranchises_seeFranchises_franchises[]
    >([]);

    useQuery<seeFranchises, seeFranchisesVariables>(SEE_FRANCHISES_QUERY, {
        onCompleted: (data) => {
            const {
                seeFranchises: { ok, error, franchises },
            } = data;

            if (ok) {
                let siteStr: string | null = null;
                if (franchises && franchises.length > 0) {
                    const franchise = franchises[0];
                    if (franchise) {
                        siteStr = franchise.host.site;
                    }
                }

                if (siteStr) {
                    let fList: seeFranchises_seeFranchises_franchises[] = [];

                    franchises?.map((franchise) => {
                        if (franchise) {
                            if (franchise.host.site === siteStr) {
                                fList.push(franchise);
                            }
                        }
                    });

                    setFranchises(fList);
                }
            } else {
                alert(error);
            }
        },
    });

    const [updateHideProductMutation] = useMutation<
        updateHideProduct,
        updateHideProductVariables
    >(UPDATE_HIDE_PRODUCT_MUTATION, {
        onCompleted: (data) => {
            const {
                updateHideProduct: { ok, error },
            } = data;

            if (ok) {
                evictCacheRootQuery();
            } else {
                alert(error);
            }
        },
        onError: (err) => {
            alert(err);
        },
    });

    const onClickHideProduct = (productKey: string, hide: boolean) => {
        updateHideProductMutation({
            variables: {
                productKey,
                hide,
            },
        });
    };

    return (
        <Container>
            {franchises.map((franchise, index) => {
                return (
                    <FranchiseContainer key={index}>
                        {`${franchise.name}`}
                        <ProductContainer>
                            {franchise.products?.map((product, index) => {
                                return (
                                    <HideContainer key={index}>
                                        {product ? (
                                            <>
                                                <Text>{`${product?.name}(${
                                                    product?.hideProduct ===
                                                    false
                                                        ? "보여짐"
                                                        : "숨겨짐"
                                                })`}</Text>
                                                <HideButton
                                                    onClick={() => {
                                                        onClickHideProduct(
                                                            product.key,
                                                            !product.hideProduct,
                                                        );
                                                    }}
                                                >
                                                    {product?.hideProduct ===
                                                    true
                                                        ? "보이기"
                                                        : "숨기기"}
                                                </HideButton>
                                            </>
                                        ) : null}
                                    </HideContainer>
                                );
                            })}
                        </ProductContainer>
                    </FranchiseContainer>
                );
            })}
        </Container>
    );
};
