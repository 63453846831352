import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import React from "react";

const CheckBoxContainer = styled.div<{ disabled?: Boolean }>`
    display: flex;
    align-items: center;
    padding: 10px;
    max-width: 400px;
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;

const CheckBoxText = styled.span`
    font-size: 12px;
    margin-left: 5px;
    user-select: none;
    color: ${(props) => (props.color ? `${props.color}` : "black")};
`;

interface ICheckBoxProps {
    isChecked: boolean;
    color?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    style?: React.CSSProperties;
    disabled?: Boolean;
    children?: any;
}

interface ICheckBoxTextProps extends ICheckBoxProps {
    title?: string;
}

export function CheckBoxWithText({
    isChecked,
    title,
    color,
    onClick,
    style,
    disabled,
}: ICheckBoxTextProps) {
    return (
        <CheckBoxContainer
            onClick={(result) => {
                if (onClick) {
                    if (disabled !== undefined) {
                        if (disabled === false) {
                            onClick(result);
                        }
                    } else {
                        onClick(result);
                    }
                }
            }}
            style={style}
            disabled={disabled}
        >
            <FontAwesomeIcon
                icon={isChecked ? faSquareCheck : faSquare}
                color={color}
                size="1x"
            />
            {title ? <CheckBoxText color={color}>{title}</CheckBoxText> : null}
        </CheckBoxContainer>
    );
}

export function CheckBox({
    isChecked,
    color,
    onClick,
    style,
    disabled,
    children,
}: ICheckBoxProps) {
    return (
        <CheckBoxContainer
            onClick={(result) => {
                if (onClick) {
                    if (disabled !== undefined) {
                        if (disabled === false) {
                            onClick(result);
                        }
                    } else {
                        onClick(result);
                    }
                }
            }}
            style={style}
            disabled={disabled}
        >
            <FontAwesomeIcon
                icon={isChecked ? faSquareCheck : faSquare}
                color={color}
                size="1x"
            />
            {children}
        </CheckBoxContainer>
    );
}
