import styled from "styled-components";
import { seeBookingCalendar_seeBookingCalendar_bookings } from "../../__generated__/seeBookingCalendar";
import { BookingsCalendarDayBooking } from "./BookingsCalendarDayBooking";
import { get24Dates } from "./BookingsCalendarDay";
import { IProduct } from "./Bookings";
import { useEffect, useState } from "react";
import { getKRDate } from "./BookingsCalendarMonthBooking";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-right: 0.5px solid ${(props) => props.theme.borderLight};
`;

const Main = styled.div<{ now: boolean }>`
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0.5px solid ${(props) => props.theme.borderLight};
    background-color: ${(props) =>
        props.now ? props.theme.lightBeige : props.theme.white};
`;

export interface IDateBooking {
    date: Date;
    booking?: seeBookingCalendar_seeBookingCalendar_bookings;
}

interface IBookingsCalendarDayProductProps {
    bookings: seeBookingCalendar_seeBookingCalendar_bookings[];
    products: IProduct[];
    date: Date;
    width: string;
    height: string;
    franchiseIndex: number;
    franchiseName: string;
    useHalfTimeFranchise: boolean;
}

export const isNow = (date: Date, isFull: boolean) => {
    let result = false;
    const now = new Date();
    if (isFull === false) {
        if (date.getHours() === now.getHours()) {
            result = true;
        }
    } else {
        if (date.getFullYear() === now.getFullYear()) {
            if (date.getMonth() === now.getMonth()) {
                if (date.getDate() === now.getDate()) {
                    if (date.getHours() === now.getHours()) {
                        result = true;
                    }
                }
            }
        }
    }

    return result;
};

export interface IProductBookings {
    product: IProduct;
    dateBookings: IDateBooking[];
}

export function BookingsCallendarDayProduct({
    bookings,
    products,
    date,
    width,
    height,
    franchiseIndex,
    franchiseName,
    useHalfTimeFranchise,
}: IBookingsCalendarDayProductProps) {
    const [productBookings, setProductBookings] = useState<IProductBookings[]>(
        [],
    );

    useEffect(() => {
        const productBookingsTemps: IProductBookings[] = [];

        products.map((product) => {
            const productBookingsTemp: IProductBookings = {
                product,
                dateBookings: [],
            };

            productBookingsTemps.push(productBookingsTemp);
        });

        setProductBookings(productBookingsTemps);

        if (bookings.length > 0) {
            let bookingsTemp: seeBookingCalendar_seeBookingCalendar_bookings[] =
                [];

            bookings.map((booking) => {
                let removeIndex = -1;
                for (let i = 0; i < bookingsTemp.length; ++i) {
                    const bookingTemp = bookingsTemp[i];

                    if (booking.product.index === bookingTemp.product.index) {
                        if (booking.startDateUTC === bookingTemp.startDateUTC) {
                            if (booking.endDateUTC === bookingTemp.endDateUTC) {
                                if (bookingTemp.schedule === true) {
                                    removeIndex = i;
                                }
                            }
                        }
                    }
                }
                if (removeIndex !== -1) {
                    bookingsTemp = bookingsTemp.filter(
                        (_, index) => index !== removeIndex,
                    );
                    removeIndex = -1;
                }

                bookingsTemp.push(booking);
            });

            productBookingsTemps.map((productBooking) => {
                dates.map((newDate) => {
                    const today = new Date(date.getTime());
                    today.setMinutes(newDate.getMinutes());
                    today.setSeconds(0);
                    today.setMilliseconds(0);

                    today.setHours(newDate.getHours());

                    let dateBookingsTemp: IDateBooking = {
                        date: today,
                    };

                    bookingsTemp.map((booking) => {
                        if (
                            productBooking.product.index ===
                            booking.product.index
                        ) {
                            const todayTime = today.getTime();
                            const krStart = getKRDate(
                                new Date(Number(booking.startDateUTC)),
                            );
                            const krEnd = getKRDate(
                                new Date(Number(booking.endDateUTC)),
                            );
                            const startTime = krStart.getTime();
                            const endTime = krEnd.getTime();

                            if (startTime <= todayTime && todayTime < endTime) {
                                dateBookingsTemp.booking = booking;
                            }
                        }
                    });

                    productBooking.dateBookings.push(dateBookingsTemp);
                });
            });

            setProductBookings(productBookingsTemps);
        }
    }, [bookings, products]);
    const dates = get24Dates(useHalfTimeFranchise, date);

    return (
        <>
            {productBookings.map((productBooking, index) => {
                return (
                    <Container
                        style={{ width: width, minWidth: width }}
                        key={index}
                    >
                        {productBooking.dateBookings.length > 0
                            ? productBooking.dateBookings.map(
                                  (element, index) => {
                                      return (
                                          <Main
                                              key={index}
                                              now={isNow(element.date, false)}
                                              style={{ height: height }}
                                          >
                                              <BookingsCalendarDayBooking
                                                  booking={element.booking}
                                                  franchiseIndex={
                                                      franchiseIndex
                                                  }
                                                  productIndex={
                                                      productBooking.product
                                                          .index
                                                  }
                                                  franchiseName={franchiseName}
                                                  productName={
                                                      productBooking.product
                                                          .name
                                                  }
                                                  useHalfTimeFranchise={
                                                      useHalfTimeFranchise
                                                  }
                                                  date={element.date}
                                              />
                                          </Main>
                                      );
                                  },
                              )
                            : dates.map((date, index) => {
                                  return (
                                      <Main
                                          key={index}
                                          now={isNow(date, false)}
                                          style={{ height: height }}
                                      >
                                          <BookingsCalendarDayBooking
                                              franchiseIndex={franchiseIndex}
                                              productIndex={
                                                  productBooking.product.index
                                              }
                                              franchiseName={franchiseName}
                                              productName={
                                                  productBooking.product.name
                                              }
                                              useHalfTimeFranchise={
                                                  useHalfTimeFranchise
                                              }
                                              date={date}
                                          />
                                      </Main>
                                  );
                              })}
                    </Container>
                );
            })}
        </>
    );
}
