import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { lightTheme } from "../styles";

export function useBookingWidth() {
    const [bookingWidth, setBookingWidth] = useState(80);
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    useEffect(() => {
        if (isMobile) {
            setBookingWidth(80);
        } else {
            setBookingWidth(120);
        }
    }, [isMobile]);

    return bookingWidth;
}
