import styled from "styled-components";
import { ButtonSpan, Input, lightTheme } from "../../styles";
import { Popup } from "./Popup";
import {
    seeAllMemberFranchisesByKakao,
    seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises,
    seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises_products,
} from "../../__generated__/seeAllMemberFranchisesByKakao";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_ALL_MEMBER_FRANCHISES_BY_KAKAO_QUERY } from "../../graphqls/queries";
import { SiteType } from "../../__generated__/globalTypes";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { evictCacheRootQuery } from "../../global";
import {
    createKakaoCustomBookingMessage,
    createKakaoCustomBookingMessageVariables,
} from "../../__generated__/createKakaoCustomBookingMessage";
import {
    CREATE_KAKAO_BOOKING_MESSAGE_MUTATION,
    CREATE_KAKAO_CUSTOM_BOOKING_MESSAGE_MUTATION,
    RESEND_FRANCHISE_BOOKING_MESSAGES_MUTATION,
    RESEND_PRODUCT_BOOKING_MESSAGES_MUTATION,
} from "../../graphqls/mutations";
import {
    createKakaoBookingMessage,
    createKakaoBookingMessageVariables,
} from "../../__generated__/createKakaoBookingMessage";
import { CheckBoxWithText } from "../CheckBox";
import { FormButton } from "../form/FormButton";
import { MessageSettingPopup } from "./MessageSettingPopup";
import { KakaoMessagePreviewPopup } from "./KakaoMessagePreviewPopup";
import {
    resendFranchiseBookingMessages,
    resendFranchiseBookingMessagesVariables,
} from "../../__generated__/resendFranchiseBookingMessages";
import {
    resendProductBookingMessages,
    resendProductBookingMessagesVariables,
} from "../../__generated__/resendProductBookingMessages";

interface IKakaoMessagePopup {
    didClosePopup: () => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const FranchiseContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 20px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ProductInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductInnerButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FranchiseTitle = styled.span`
    font-size: 15px;
    line-height: 130%;
    font-weight: 600;
    margin-bottom: 10px;
`;

const Title = styled.span`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 30px;
`;

const Text = styled.span`
    font-size: 15px;
    line-height: 130%;
`;

const Enter = styled.span`
    padding: 10px;
`;

const Button = styled(ButtonSpan)`
    font-size: 15px;
    font-weight: 700;
    color: ${(props) => props.theme.blue};
    padding: 5px;
`;

interface IShowMessagePreviewProps {
    franchiseName: string;
    productName: string;
    url: string;
    franchiseIndex: number;
}

export function KakaoMessagePopup({ didClosePopup }: IKakaoMessagePopup) {
    const {
        register,
        formState: { isValid },
        handleSubmit,
        getValues,
    } = useForm({
        mode: "onChange",
    });

    const [franchises, setFranchises] = useState<
        seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises[]
    >([]);

    const [customMessage, setCustomMessage] = useState<boolean>(false);
    const [productMessages, setProductMessages] = useState<number[]>([]);
    const [showSettingMessagePopup, setShowSettingMessagePopup] =
        useState(false);
    const [showKakaoPreviewPopup, setShowKakaoPreviewPopup] =
        useState<IShowMessagePreviewProps>();

    useQuery<seeAllMemberFranchisesByKakao>(
        SEE_ALL_MEMBER_FRANCHISES_BY_KAKAO_QUERY,
        {
            onCompleted: (data) => {
                const {
                    seeAllMemberFranchisesByKakao: {
                        franchises: _franchises,
                        kakaoBookingMessageOption,
                    },
                } = data;

                if (kakaoBookingMessageOption) {
                    setCustomMessage(
                        kakaoBookingMessageOption.useCustomMessage,
                    );
                }

                const franchises: seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises[] =
                    [];

                _franchises?.map((franchise) => {
                    if (franchise && franchise.site === SiteType.NA) {
                        franchises.push(franchise);

                        if (franchise.isProductMessage === true) {
                            setProductMessages((props) => [
                                ...props,
                                franchise.index,
                            ]);
                        }
                    }
                });

                setFranchises(franchises);
            },
            onError: (error) => {
                console.log(error);
            },
        },
    );

    const [createKakaoBookingMessageMutation] = useMutation<
        createKakaoBookingMessage,
        createKakaoBookingMessageVariables
    >(CREATE_KAKAO_BOOKING_MESSAGE_MUTATION, {
        onCompleted: (data) => {
            const {
                createKakaoBookingMessage: { ok, error },
            } = data;

            if (ok) {
                evictCacheRootQuery();
                alert("변경되었습니다.");
            } else {
                alert(error);
            }
        },
    });

    const [createKakaoCustomBookingMessageMutation] = useMutation<
        createKakaoCustomBookingMessage,
        createKakaoCustomBookingMessageVariables
    >(CREATE_KAKAO_CUSTOM_BOOKING_MESSAGE_MUTATION, {
        onCompleted: (data) => {
            const {
                createKakaoCustomBookingMessage: { ok, error },
            } = data;

            if (ok) {
                evictCacheRootQuery();
                alert("변경되었습니다.");
            } else {
                alert(error);
            }
        },
    });

    const [resendFranchiseBookingMessagesMutation] = useMutation<
        resendFranchiseBookingMessages,
        resendFranchiseBookingMessagesVariables
    >(RESEND_FRANCHISE_BOOKING_MESSAGES_MUTATION, {
        onCompleted: (data) => {
            const {
                resendFranchiseBookingMessages: { ok, error, result },
            } = data;

            if (ok) {
                evictCacheRootQuery();
                alert(result);
            } else {
                alert(error);
            }
        },
    });

    const [resendProductBookingMessagesMutation] = useMutation<
        resendProductBookingMessages,
        resendProductBookingMessagesVariables
    >(RESEND_PRODUCT_BOOKING_MESSAGES_MUTATION, {
        onCompleted: (data) => {
            const {
                resendProductBookingMessages: { ok, error, result },
            } = data;

            if (ok) {
                evictCacheRootQuery();
                alert(result);
            } else {
                alert(error);
            }
        },
    });

    const isProductMessage = (franchiseIndex: number) => {
        let result = false;

        if (productMessages.includes(franchiseIndex) === true) {
            result = true;
        }

        return result;
    };

    const onRegisterClick = (productKey: string, franchiseIndex: number) => {
        const value = getValues(`product.${productKey}.value`);
        const productMessage = isProductMessage(franchiseIndex);

        if (value) {
            if (customMessage === true) {
                createKakaoCustomBookingMessageMutation({
                    variables: {
                        productKey: productKey,
                        franchiseIndex: franchiseIndex,
                        isProductMessage: productMessage,
                        templateId: value,
                    },
                });
            } else {
                createKakaoBookingMessageMutation({
                    variables: {
                        productKey: productKey,
                        franchiseIndex: franchiseIndex,
                        isProductMessage: productMessage,
                        url: value,
                    },
                });
            }
        } else {
            alert("값을 입력하세요");
        }
    };

    const onProductMsgClick = (franchiseIndex: number) => {
        if (productMessages.includes(franchiseIndex) === true) {
            setProductMessages((props) =>
                props.filter((prop) => prop !== franchiseIndex),
            );
        } else {
            setProductMessages((props) => [...props, franchiseIndex]);
        }
    };

    const onResendFranchiseBookingMsg = (franchiseIndex: number) => {
        resendFranchiseBookingMessagesMutation({
            variables: {
                franchiseIndex,
            },
        });
    };

    const onResendProductBookingMsg = (
        franchiseIndex: number,
        productIndex: number,
    ) => {
        resendProductBookingMessagesMutation({
            variables: {
                franchiseIndex,
                productIndex,
            },
        });
    };

    const onResend = (franchiseIndex: number, productIndex: number) => {
        const ok = window.confirm(
            "이미 메시지가 전송된 예약자들에게 메시지를 재전송합니다.",
        );

        if (ok) {
            const productMessage = isProductMessage(franchiseIndex);
            if (productMessage === false) {
                onResendFranchiseBookingMsg(franchiseIndex);
            } else {
                onResendProductBookingMsg(franchiseIndex, productIndex);
            }
        }
    };

    const productTag = (
        index: number,
        product: seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises_products,
        franchise: seeAllMemberFranchisesByKakao_seeAllMemberFranchisesByKakao_franchises,
    ) => {
        const productMessage = isProductMessage(franchise.index);

        return (
            <ProductInnerContainer key={index}>
                {productMessage === true ? <Text>{product.name}</Text> : null}
                <ProductInnerButtonContainer>
                    {customMessage === false ? (
                        <Button
                            style={{ color: lightTheme.black }}
                            onClick={() => {
                                setShowKakaoPreviewPopup({
                                    franchiseName: franchise.name ?? "",
                                    productName: product.name ?? "",
                                    url: getValues(
                                        `product.${product.key}.value`,
                                    ),
                                    franchiseIndex: franchise.index,
                                });
                            }}
                        >
                            미리보기
                        </Button>
                    ) : null}

                    <Input
                        {...register(`product.${product.key}.value`, {
                            required: true,
                        })}
                        style={{ height: "25px", width: "100%" }}
                        placeholder="URL 입력(http://)"
                        defaultValue={
                            customMessage === true
                                ? product.kakaoBookingMessage
                                      ?.kakaoCustomBookingMessage?.templateId
                                : product.kakaoBookingMessage?.url
                        }
                    />
                    <Button
                        style={{ paddingLeft: "20px", color: lightTheme.red }}
                        onClick={() => {
                            onRegisterClick(product.key, franchise.index);
                        }}
                    >
                        변경
                    </Button>
                    <Button
                        style={{ paddingLeft: "20px", color: lightTheme.black }}
                        onClick={() => {
                            onResend(franchise.index, product.index);
                        }}
                    >
                        재전송
                    </Button>
                </ProductInnerButtonContainer>
            </ProductInnerContainer>
        );
    };

    return (
        <Popup
            didClosePopup={didClosePopup}
            maxWidth={"550px"}
            disabledBackgrounClickClose={true}
        >
            <Container>
                <Title>
                    안내사항 URL을 변경하려면 내용 변경 후 [변경]버튼을
                    눌려주세요
                </Title>
                <Text>
                    안내사항은 URL 변경 보다 Notion 에서 내용을 변경하는걸
                    추천합니다.
                </Text>

                <Enter />

                {franchises.map((franchise, index) => {
                    const productMessage = isProductMessage(franchise.index);

                    return (
                        <FranchiseContainer key={index}>
                            <CheckBoxContainer>
                                <FranchiseTitle>
                                    {franchise.name}
                                </FranchiseTitle>
                                <CheckBoxWithText
                                    style={{
                                        padding: "0px",
                                        marginLeft: "30px",
                                        whiteSpace: "nowrap",
                                    }}
                                    isChecked={productMessage}
                                    title="방 별로 설정"
                                    onClick={() => {
                                        onProductMsgClick(franchise.index);
                                    }}
                                />
                            </CheckBoxContainer>
                            <ProductContainer>
                                {franchise.products.map((product, index) => {
                                    if (productMessage === false) {
                                        if (index === 0) {
                                            return productTag(
                                                index,
                                                product,
                                                franchise,
                                            );
                                        }
                                    } else {
                                        return productTag(
                                            index,
                                            product,
                                            franchise,
                                        );
                                    }
                                })}
                            </ProductContainer>
                        </FranchiseContainer>
                    );
                })}
                {customMessage === true ? (
                    <FormButton
                        title="기존 예약 문자 보기"
                        onClick={() => {
                            setShowSettingMessagePopup(true);
                        }}
                    />
                ) : null}
            </Container>
            {showSettingMessagePopup ? (
                <MessageSettingPopup
                    viewer={true}
                    didClosePopup={() => setShowSettingMessagePopup(false)}
                    didSuccessSubmit={() => setShowSettingMessagePopup(false)}
                />
            ) : null}
            {showKakaoPreviewPopup ? (
                <KakaoMessagePreviewPopup
                    franchiseName={showKakaoPreviewPopup.franchiseName}
                    productName={showKakaoPreviewPopup.productName}
                    url={showKakaoPreviewPopup.url}
                    isProductMessage={isProductMessage(
                        showKakaoPreviewPopup.franchiseIndex,
                    )}
                    didClosePopup={() => setShowKakaoPreviewPopup(undefined)}
                />
            ) : null}
        </Popup>
    );
}
