import styled from "styled-components";
import { ButtonSpan } from "../../../styles";

export const HostFormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HostFormInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
`;

export const HostFormCheckContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.fontGray};
    border-radius: 5px;
    padding: 10px;
    max-width: 500px;
    margin-bottom: 20px;
`;

export const HostFormCheckTitle = styled.span`
    font-size: 15px;
    font-weight: 700;
    color: ${(props) => props.theme.black};
    margin-bottom: 20px;
`;

export const HostFormCheckText = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.black};
    line-height: 150%;
    margin-bottom: 10px;
`;

export const HostFormTitle = styled.span`
    font-size: 16px;
    font-weight: 700;
    text-align: center;
`;

export const HostFormAnnotation = styled.span`
    font-size: 14px;
    max-width: 400px;
    margin-top: 10px;
`;

export const HostFormButton = styled(ButtonSpan)`
    text-align: center;
    font-size: 13px;
    padding: 10px;
    width: 250px;
    margin-top: 20px;
    border-radius: 5px;
`;
