import { useQuery } from "@apollo/client";
import { SEE_STATISTICS_BOOKINGS_QUERY } from "../../../graphqls/queries";
import {
    seeStatisticsBookings,
    seeStatisticsBookingsVariables,
    seeStatisticsBookings_seeStatisticsBookings_statisticsBookings,
} from "../../../__generated__/seeStatisticsBookings";
import { lightTheme } from "../../../styles";
import { Bar, Line } from "react-chartjs-2";
import Chart, { ChartOptions, ChartData } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { SiteType } from "../../../__generated__/globalTypes";
import { StatisticsBookingsTitle } from "./StatisticsBookingsTitle";
import {
    FRANCHISE_SELECT_ALL,
    PRODUCT_SELECT_ALL,
} from "../../commonBookingHeaders/ProductSelector";
import useMember from "../../../hooks/useMember";
import { useMediaQuery } from "react-responsive";
import { getKRDate } from "../../bookings/BookingsCalendarMonthBooking";
Chart.register(CategoryScale);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
`;

const Title = styled.span`
    font-size: 13px;
    margin: 5px 20px 0px 20px;
    color: ${(props) => props.theme.fontDarkGray};

    @media ${({ theme }) => theme.mobile} {
        margin: 5px 10px 0px 10px;
    }
`;

const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.border};
    padding: 20px;
    margin: 20px 20px 0px 20px;
    border-radius: 5px;
    max-width: 600px;

    @media ${({ theme }) => theme.mobile} {
        padding: 10px;
        margin: 20px 10px 0px 10px;
    }
`;

const ChartTitleContainer = styled.div`
    display: flex;
`;

const ChartTitle = styled.span`
    font-size: 18px;
    font-weight: 700;

    @media ${({ theme }) => theme.mobile} {
        font-size: 15px;
    }
`;

const ChartDate = styled.span`
    font-size: 12px;
    color: ${(props) => props.theme.fontGray};
    padding-left: 7px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

interface IStatisticsBookingGraphProps {
    selectedFranchiseIndex: number;
    selectedProductIndex: number;
    baseDate: Date;
    isMonth: boolean;
}

interface IDateBooking {
    date: Date;
    site: string;
    totalPrice: number;
    useCount: number;
    salesProjectionPrice: number;
    salesProjectionUseCount: number;
}

interface ITotalDateBooking {
    date: Date;
    totalPrice: number;
    useCount: number;
    salesProjectionPrice: number;
    salesProjectionUseCount: number;
}

export function StatisticsBookingGraph({
    selectedFranchiseIndex,
    selectedProductIndex,
    baseDate,
    isMonth,
}: IStatisticsBookingGraphProps) {
    const me = useMember();
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    const beforeDateOrMonth = 6;

    const [statisticsBookings, setStatisticsBookings] = useState<
        seeStatisticsBookings_seeStatisticsBookings_statisticsBookings[]
    >([]);
    const [selectedStatisticsBookings, setSelectedStatisticsBookings] =
        useState<
            seeStatisticsBookings_seeStatisticsBookings_statisticsBookings[]
        >([]);

    const [chartSalesData, setChartSalesData] =
        useState<ChartData<"line", number[], string>>();
    const [chartUseData, setChartUseData] =
        useState<ChartData<"line", number[], string>>();
    const [chartSiteSalesData, setChartSiteSalesData] =
        useState<ChartData<"bar", number[], string>>();
    const [chartSiteUseData, setChartSiteUseData] =
        useState<ChartData<"bar", number[], string>>();
    const [chartLabels, setChartLabels] = useState<string[]>([]);

    const options: ChartOptions<"bar"> = {
        layout: {
            padding: {
                left: 10,
                right: 10,
            },
        },
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    const sumOptions: ChartOptions<"line"> = {
        layout: {
            padding: {
                left: 10,
                right: 10,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    useQuery<seeStatisticsBookings, seeStatisticsBookingsVariables>(
        SEE_STATISTICS_BOOKINGS_QUERY,
        {
            variables: {
                baseDateTime: String(baseDate.getTime()),
                beforeDateOrMonth,
                isMonth,
            },
            onCompleted: (data: seeStatisticsBookings) => {
                const {
                    seeStatisticsBookings: {
                        ok,
                        statisticsBookings: _statisticsBookings,
                    },
                }: seeStatisticsBookings = data;

                if (ok) {
                    const temp: seeStatisticsBookings_seeStatisticsBookings_statisticsBookings[] =
                        [];
                    const labelTemp: string[] = [];
                    _statisticsBookings?.map((booking) => {
                        if (booking) {
                            const date = new Date(Number(booking.date));
                            let label = `${date.getDate()}일`;
                            if (isMonth) {
                                label = `${date.getMonth() + 1}월`;
                            }

                            if (labelTemp.includes(label) === false) {
                                labelTemp.push(label);
                            }

                            temp.push(booking);
                        }
                    });

                    setStatisticsBookings(temp);
                    setChartLabels(labelTemp);
                }
            },
        },
    );

    useEffect(() => {
        if (statisticsBookings.length > 0) {
            let temp: seeStatisticsBookings_seeStatisticsBookings_statisticsBookings[] =
                [];

            if (selectedFranchiseIndex === FRANCHISE_SELECT_ALL) {
                temp = statisticsBookings;
            } else {
                statisticsBookings.map((booking) => {
                    if (booking.franchiseIndex === selectedFranchiseIndex) {
                        if (selectedProductIndex === PRODUCT_SELECT_ALL) {
                            temp.push(booking);
                        } else {
                            if (booking.productIndex === selectedProductIndex) {
                                temp.push(booking);
                            }
                        }
                    }
                });
            }

            setSelectedStatisticsBookings(temp);
        }
    }, [selectedFranchiseIndex, selectedProductIndex, statisticsBookings]);

    const getSiteLabel = (site: SiteType) => {
        let label = "스페이스클라우드";

        if (site === SiteType.SC) {
            label = "스페이스클라우드";
            if (isMobile) {
                label = "S";
            }
        } else if (site === SiteType.NA) {
            label = "네이버";
            if (isMobile) {
                label = "N";
            }
        }

        return label;
    };

    useEffect(() => {
        if (selectedStatisticsBookings.length > 0 && chartLabels.length > 0) {
            const priceDatas: number[] = [];
            const useDatas: number[] = [];
            const salesProjectionPriceDatas: number[] = [];
            const salesProjectionUseDatas: number[] = [];
            const scPriceDatas: number[] = [];
            const scUseDatas: number[] = [];
            const scSalesProjectionPriceDatas: number[] = [];
            const scSalesProjectionUseDatas: number[] = [];
            const naPriceDatas: number[] = [];
            const naUseDatas: number[] = [];
            const naSalesProjectionPriceDatas: number[] = [];
            const naSalesProjectionUseDatas: number[] = [];

            for (let i = beforeDateOrMonth; i >= 0; --i) {
                const date = new Date(baseDate.getTime());
                if (isMonth) {
                    date.setMonth(date.getMonth() - i);
                } else {
                    date.setDate(date.getDate() - i);
                }

                let totalPrice = 0;
                let useCount = 0;
                let salesProjectionPrice = 0;
                let salesProjectionUseCount = 0;

                let scTotalPrice = 0;
                let scUseCount = 0;
                let scSalesProjectionPrice = 0;
                let scSalesProjectionUseCount = 0;

                let naTotalPrice = 0;
                let naUseCount = 0;
                let naSalesProjectionPrice = 0;
                let naSalesProjectionUseCount = 0;

                selectedStatisticsBookings.map((booking) => {
                    const bookingDate = new Date(Number(booking.date));
                    if (bookingDate.getFullYear() === date.getFullYear()) {
                        if (bookingDate.getMonth() === date.getMonth()) {
                            if (isMonth) {
                                totalPrice = totalPrice + booking.totalPrice;
                                useCount = useCount + booking.useCount;
                                salesProjectionPrice =
                                    salesProjectionPrice +
                                    booking.salesProjectionPrice;
                                salesProjectionUseCount =
                                    salesProjectionUseCount +
                                    booking.salesProjectionUseCount;

                                booking.siteDatas.map((siteDate) => {
                                    if (siteDate?.site === SiteType.SC) {
                                        scTotalPrice =
                                            scTotalPrice + siteDate.totalPrice;
                                        scUseCount =
                                            scUseCount + siteDate.useCount;
                                        scSalesProjectionPrice =
                                            scSalesProjectionPrice +
                                            siteDate.salesProjectionPrice;
                                        scSalesProjectionUseCount =
                                            scSalesProjectionUseCount +
                                            siteDate.salesProjectionUseCount;
                                    } else if (siteDate?.site === SiteType.NA) {
                                        naTotalPrice =
                                            naTotalPrice + siteDate.totalPrice;
                                        naUseCount =
                                            naUseCount + siteDate.useCount;
                                        naSalesProjectionPrice =
                                            naSalesProjectionPrice +
                                            siteDate.salesProjectionPrice;
                                        naSalesProjectionUseCount =
                                            naSalesProjectionUseCount +
                                            siteDate.salesProjectionUseCount;
                                    }
                                });
                            } else {
                                if (bookingDate.getDate() === date.getDate()) {
                                    totalPrice =
                                        totalPrice + booking.totalPrice;
                                    useCount = useCount + booking.useCount;
                                    salesProjectionPrice =
                                        salesProjectionPrice +
                                        booking.salesProjectionPrice;
                                    salesProjectionUseCount =
                                        salesProjectionUseCount +
                                        booking.salesProjectionUseCount;

                                    booking.siteDatas.map((siteDate) => {
                                        if (siteDate?.site === SiteType.SC) {
                                            scTotalPrice =
                                                scTotalPrice +
                                                siteDate.totalPrice;
                                            scUseCount =
                                                scUseCount + siteDate.useCount;
                                            scSalesProjectionPrice =
                                                scSalesProjectionPrice +
                                                siteDate.salesProjectionPrice;
                                            scSalesProjectionUseCount =
                                                scSalesProjectionUseCount +
                                                siteDate.salesProjectionUseCount;
                                        } else if (
                                            siteDate?.site === SiteType.NA
                                        ) {
                                            naTotalPrice =
                                                naTotalPrice +
                                                siteDate.totalPrice;
                                            naUseCount =
                                                naUseCount + siteDate.useCount;
                                            naSalesProjectionPrice =
                                                naSalesProjectionPrice +
                                                siteDate.salesProjectionPrice;
                                            naSalesProjectionUseCount =
                                                naSalesProjectionUseCount +
                                                siteDate.salesProjectionUseCount;
                                        }
                                    });
                                }
                            }
                        }
                    }
                });

                priceDatas.push(totalPrice);
                useDatas.push(useCount);
                salesProjectionPriceDatas.push(
                    salesProjectionPrice + totalPrice,
                );
                salesProjectionUseDatas.push(
                    salesProjectionUseCount + useCount,
                );

                scPriceDatas.push(scTotalPrice);
                scUseDatas.push(scUseCount);
                scSalesProjectionPriceDatas.push(
                    scSalesProjectionPrice + scTotalPrice,
                );
                scSalesProjectionUseDatas.push(
                    scSalesProjectionUseCount + scUseCount,
                );

                naPriceDatas.push(naTotalPrice);
                naUseDatas.push(naUseCount);
                naSalesProjectionPriceDatas.push(
                    naSalesProjectionPrice + naTotalPrice,
                );
                naSalesProjectionUseDatas.push(
                    naSalesProjectionUseCount + naUseCount,
                );
            }

            setChartSalesData({
                labels: chartLabels,
                datasets: [
                    {
                        label: "통합",
                        data: priceDatas,
                        fill: false,
                        borderColor: lightTheme.blue,
                        tension: 0.1,
                        borderWidth: 2,
                    },
                    {
                        label: "예상",
                        data: salesProjectionPriceDatas,
                        fill: false,
                        borderColor: lightTheme.fontGray,
                        tension: 0.1,
                        borderWidth: 2,
                    },
                ],
            });

            setChartUseData({
                labels: chartLabels,
                datasets: [
                    {
                        label: "통합",
                        data: useDatas,
                        fill: false,
                        borderColor: lightTheme.blue,
                        tension: 0.1,
                        borderWidth: 2,
                    },
                    {
                        label: "예상",
                        data: salesProjectionUseDatas,
                        fill: false,
                        borderColor: lightTheme.fontGray,
                        tension: 0.1,
                        borderWidth: 2,
                    },
                ],
            });

            setChartSiteSalesData({
                labels: chartLabels,
                datasets: [
                    {
                        label: getSiteLabel(SiteType.SC),
                        data: scPriceDatas,
                        backgroundColor: lightTheme.chartSC,
                        borderWidth: 0,
                        barPercentage: 0.5,
                    },
                    {
                        label: getSiteLabel(SiteType.NA),
                        data: naPriceDatas,
                        backgroundColor: lightTheme.chartNA,
                        borderWidth: 0,
                        barPercentage: 0.5,
                    },
                ],
            });

            setChartSiteUseData({
                labels: chartLabels,
                datasets: [
                    {
                        label: getSiteLabel(SiteType.SC),
                        data: scUseDatas,
                        backgroundColor: lightTheme.chartSC,
                        borderWidth: 0,
                        barPercentage: 0.5,
                    },
                    {
                        label: getSiteLabel(SiteType.NA),
                        data: naUseDatas,
                        backgroundColor: lightTheme.chartNA,
                        borderWidth: 0,
                        barPercentage: 0.5,
                    },
                ],
            });
        }
    }, [selectedStatisticsBookings, chartLabels]);

    const getTotalStatisticsBooking = (before: number = 0) => {
        const date = new Date(baseDate.getTime());
        if (before !== 0) {
            if (isMonth) {
                date.setMonth(date.getMonth() - before);
            } else {
                date.setDate(date.getDate() - before);
            }
        }

        const result: ITotalDateBooking = {
            date,
            totalPrice: 0,
            useCount: 0,
            salesProjectionPrice: 0,
            salesProjectionUseCount: 0,
        };

        for (let i = 0; i < selectedStatisticsBookings.length; ++i) {
            const booking = selectedStatisticsBookings[i];
            const bookingDate = new Date(Number(booking.date));
            if (bookingDate.getFullYear() === date.getFullYear()) {
                if (bookingDate.getMonth() === date.getMonth()) {
                    if (isMonth) {
                        result.date = bookingDate;
                        result.totalPrice =
                            result.totalPrice + booking.totalPrice;
                        result.useCount = result.useCount + booking.useCount;
                        result.salesProjectionPrice =
                            result.salesProjectionPrice +
                            booking.salesProjectionPrice;
                        result.salesProjectionUseCount =
                            result.salesProjectionUseCount +
                            booking.salesProjectionUseCount;
                    } else {
                        if (bookingDate.getDate() === date.getDate()) {
                            result.date = bookingDate;
                            result.totalPrice =
                                result.totalPrice + booking.totalPrice;
                            result.useCount =
                                result.useCount + booking.useCount;
                            result.salesProjectionPrice =
                                result.salesProjectionPrice +
                                booking.salesProjectionPrice;
                            result.salesProjectionUseCount =
                                result.salesProjectionUseCount +
                                booking.salesProjectionUseCount;
                        }
                    }
                }
            }
        }

        return result;
    };

    const getSiteStatisticsBooking = (site: string, before: number = 0) => {
        const date = new Date(baseDate.getTime());
        if (before !== 0) {
            if (isMonth) {
                date.setMonth(date.getMonth() - before);
            } else {
                date.setDate(date.getDate() - before);
            }
        }

        const result: IDateBooking = {
            date,
            site,
            totalPrice: 0,
            useCount: 0,
            salesProjectionPrice: 0,
            salesProjectionUseCount: 0,
        };

        for (let i = 0; i < selectedStatisticsBookings.length; ++i) {
            const booking = selectedStatisticsBookings[i];
            const bookingDate = new Date(Number(booking.date));
            if (bookingDate.getFullYear() === date.getFullYear()) {
                if (bookingDate.getMonth() === date.getMonth()) {
                    if (isMonth) {
                        for (let j = 0; j < booking.siteDatas.length; ++j) {
                            const siteData = booking.siteDatas[j];
                            if (siteData) {
                                if (siteData.site === site) {
                                    result.date = bookingDate;
                                    result.totalPrice =
                                        result.totalPrice + siteData.totalPrice;
                                    result.useCount =
                                        result.useCount + siteData.useCount;
                                    result.salesProjectionPrice =
                                        result.salesProjectionPrice +
                                        siteData.salesProjectionPrice;
                                    result.salesProjectionUseCount =
                                        result.salesProjectionUseCount +
                                        siteData.salesProjectionUseCount;
                                }
                            }
                        }
                    } else {
                        if (bookingDate.getDate() === date.getDate()) {
                            for (let j = 0; j < booking.siteDatas.length; ++j) {
                                const siteData = booking.siteDatas[j];
                                if (siteData) {
                                    if (siteData.site === site) {
                                        result.date = bookingDate;
                                        result.totalPrice =
                                            result.totalPrice +
                                            siteData.totalPrice;
                                        result.useCount =
                                            result.useCount + siteData.useCount;
                                        result.salesProjectionPrice =
                                            result.salesProjectionPrice +
                                            siteData.salesProjectionPrice;
                                        result.salesProjectionUseCount =
                                            result.salesProjectionUseCount +
                                            siteData.salesProjectionUseCount;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return result;
    };

    const dateToStr = (date: Date) => {
        let result = `${date.getFullYear()}-${String(
            date.getMonth() + 1,
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

        if (isMonth) {
            result = `${date.getFullYear()}-${String(
                date.getMonth() + 1,
            ).padStart(2, "0")}`;
        }

        return result;
    };

    const todayTotalBooking = getTotalStatisticsBooking();
    const yesterdayTotalBooking = getTotalStatisticsBooking(1);
    const scTodayBooking = getSiteStatisticsBooking(SiteType.SC);
    const scYesterdayBooking = getSiteStatisticsBooking(SiteType.SC, 1);
    const naTodayBooking = getSiteStatisticsBooking(SiteType.NA);
    const naYesterdayBooking = getSiteStatisticsBooking(SiteType.NA, 1);

    const getSignUpDateStr = () => {
        let result = "";

        if (me?.firstActiveDate) {
            const date = getKRDate(new Date(Number(me.firstActiveDate)));
            result = `${date.getFullYear()}-${String(
                date.getMonth() + 1,
            ).padStart(2, "0")}-${String(date.getDate()).padStart(
                2,
                "0",
            )} ${String(date.getHours()).padStart(2, "0")}:${String(
                date.getMinutes(),
            ).padStart(2, "0")}`;
        }

        return result;
    };

    return (
        <Container>
            <Title style={{ marginTop: "20px", color: lightTheme.red }}>
                * 가입일자나 통계 서비스 시작일 등의 사유로 실제 매출과 다소
                차이가 날 수 있습니다.
            </Title>
            <Title style={{ color: lightTheme.red }}>
                * 온비 통계의 매출은 결제 수수료가 제외되지 않은 금액이
                표시됩니다.
            </Title>
            <Title style={{ color: lightTheme.red }}>
                * 정확한 매출 확인을 원하시면 스마트플레이스 통계 또는
                스페이스의 정산을 확인해주세요.
            </Title>
            <Title>{`가입 완료일: ${getSignUpDateStr()}`}</Title>
            <Title>{`통계 시작일: 2022-06-10 04:00`}</Title>

            {chartSalesData && todayTotalBooking && yesterdayTotalBooking ? (
                <ChartContainer>
                    <ChartTitle>
                        매출
                        <ChartDate>{`${dateToStr(
                            new Date(Number(todayTotalBooking.date)),
                        )}`}</ChartDate>
                    </ChartTitle>
                    <StatisticsBookingsTitle
                        numberTitle="원"
                        baseData={todayTotalBooking.totalPrice}
                        beforeData={yesterdayTotalBooking.totalPrice}
                        salesProjection={todayTotalBooking.salesProjectionPrice}
                    />
                    <Line data={chartSalesData} options={sumOptions} />
                </ChartContainer>
            ) : null}
            {chartSiteSalesData &&
            scTodayBooking &&
            scYesterdayBooking &&
            naTodayBooking &&
            naYesterdayBooking ? (
                <ChartContainer>
                    <ChartTitle>사이트별 매출</ChartTitle>
                    <ChartTitleContainer>
                        <StatisticsBookingsTitle
                            numberTitle="원"
                            subTitle="스페이스클라우드"
                            subTitleColor={lightTheme.chartSC}
                            baseData={scTodayBooking.totalPrice}
                            beforeData={scYesterdayBooking.totalPrice}
                            salesProjection={
                                scTodayBooking.salesProjectionPrice
                            }
                        />
                        <StatisticsBookingsTitle
                            numberTitle="원"
                            subTitle="네이버"
                            subTitleColor={lightTheme.chartNA}
                            baseData={naTodayBooking.totalPrice}
                            beforeData={naYesterdayBooking.totalPrice}
                            salesProjection={
                                naTodayBooking.salesProjectionPrice
                            }
                        />
                    </ChartTitleContainer>

                    <Bar data={chartSiteSalesData} options={options} />
                </ChartContainer>
            ) : null}

            {chartUseData && todayTotalBooking && yesterdayTotalBooking ? (
                <ChartContainer>
                    <ChartTitle>
                        사용자수
                        <ChartDate>{`${dateToStr(
                            new Date(Number(todayTotalBooking.date)),
                        )}`}</ChartDate>
                    </ChartTitle>
                    <StatisticsBookingsTitle
                        numberTitle="회"
                        baseData={todayTotalBooking.useCount}
                        beforeData={yesterdayTotalBooking.useCount}
                        salesProjection={
                            todayTotalBooking.salesProjectionUseCount
                        }
                    />
                    <Line data={chartUseData} options={sumOptions} />
                </ChartContainer>
            ) : null}
            {chartSiteUseData &&
            scTodayBooking &&
            scYesterdayBooking &&
            naTodayBooking &&
            naYesterdayBooking ? (
                <ChartContainer>
                    <ChartTitle>사이트별 사용자수</ChartTitle>
                    <ChartTitleContainer>
                        <StatisticsBookingsTitle
                            numberTitle="회"
                            subTitle="스페이스클라우드"
                            subTitleColor={lightTheme.chartSC}
                            baseData={scTodayBooking.useCount}
                            beforeData={scYesterdayBooking.useCount}
                            salesProjection={
                                scTodayBooking.salesProjectionUseCount
                            }
                        />
                        <StatisticsBookingsTitle
                            numberTitle="회"
                            subTitle="네이버"
                            subTitleColor={lightTheme.chartNA}
                            baseData={naTodayBooking.useCount}
                            beforeData={naYesterdayBooking.useCount}
                            salesProjection={
                                naTodayBooking.salesProjectionUseCount
                            }
                        />
                    </ChartTitleContainer>
                    <Bar data={chartSiteUseData} options={options} />
                </ChartContainer>
            ) : null}
        </Container>
    );
}
