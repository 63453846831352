import { useMutation, useQuery } from "@apollo/client";
import {
    faArrowLeft,
    faArrowRight,
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { byte, evictCacheRootQuery, toWon } from "../../global";
import { UPDATE_PRODUCT_MESSAGE_MUTATION } from "../../graphqls/mutations";
import { SEE_FRANCHISES_QUERY } from "../../graphqls/queries";
import useMember, { useSystem } from "../../hooks/useMember";
import { ButtonSpan } from "../../styles";
import { copyToClipboard, KeyValues } from "../../utils";
import { SignUpCompleteStep, SiteType } from "../../__generated__/globalTypes";
import {
    seeFranchises,
    seeFranchises_seeFranchises_franchises,
} from "../../__generated__/seeFranchises";
import {
    updateProductMessage,
    updateProductMessageVariables,
} from "../../__generated__/updateProductMessage";
import { CheckBoxWithText } from "../CheckBox";
import { FormButton } from "../form/FormButton";
import SelectBox from "../SelectBox";
import { Popup } from "./Popup";
import { consoleRoutes } from "../../routes";

const MAX_PAGE_COUNT = 5;
const TEXT_MAX_BYTES = 1800;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 300px;
    border: 1px solid ${(props) => props.theme.border};
    outline: none;
    padding: 8px;
`;

const Footer = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 15px;
`;

const FooterCenter = styled.div`
    display: flex;
    width: 70px;
    justify-content: space-between;
`;

const FooterPage = styled.span`
    font-size: 13px;
    user-select: none;
`;

const FooterButton = styled(ButtonSpan)``;

const TextLengthContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
`;

const TextLength = styled.span`
    font-size: 13px;
`;

const TextLengthError = styled.span`
    font-size: 13px;
    color: ${(props) => props.theme.red};
`;

const Hint = styled.span`
    font-size: 13px;
    width: 100%;
    margin-bottom: 10px;
    color: ${(props) => props.theme.blue};
    line-height: 120%;
    text-align: center;
`;

const Text = styled(ButtonSpan)``;

interface IProductMessage {
    key: string;
    name: string;
    messages: string[];
    index: number;
    page: number;
    selected: boolean;
}

interface IFranchiseProductMessage {
    key: string;
    products: IProductMessage[];
    name: string;
    messages: string[];
    index: number;
    page: number;
    checkProductMessage: boolean;
    selected: boolean;
}

interface IMessageSettingPopupProps {
    viewer: boolean;
    didClosePopup: () => void;
    didSuccessSubmit: () => void;
}

export function MessageSettingPopup({
    viewer,
    didClosePopup,
    didSuccessSubmit,
}: IMessageSettingPopupProps) {
    const system = useSystem();
    const [checkProductMsgUI, setCheckProductMsgUI] = useState(false);
    const [sendToUserUpdatedMsg, setSendToUserUpdatedMsg] = useState(true);
    const [insertedTextUI, setInsertedTextUI] = useState<string>("");
    const [selectedFranchiseUI, setSelectedFranchiseUI] =
        useState<IFranchiseProductMessage | null>(null);
    const [selectedProductUI, setSelectedProductUI] =
        useState<IProductMessage | null>(null);
    const [currentPageUI, setCurrentPageUI] = useState(1);
    useState<seeFranchises_seeFranchises_franchises | null>(null);
    const [franchiseNames, setFranchiseNames] = useState<KeyValues[]>([]);
    const [productNames, setProductNames] = useState<KeyValues[]>([]);
    const [selectedFranchiseName, setSelectedFranchiseName] =
        useState<KeyValues | null>();
    const [selectedProductName, setSelectedProductName] =
        useState<KeyValues | null>();
    const [franchiseMessages, setFranchiseMessages] = useState<
        IFranchiseProductMessage[]
    >([]);
    const [currentBytes, setCurrentBytes] = useState(0);
    const [firstInsertText, setFirstInsertText] = useState(false);
    const [willSendUpdateMsg, setWillSendUpdateMsg] = useState(false);

    useEffect(() => {
        if (franchiseMessages.length > 0) {
            if (franchiseMessages[0].checkProductMessage) {
                const keyValue: KeyValues = {
                    key: franchiseMessages[0].key,
                    value: franchiseMessages[0].name,
                };
                onSelectFranchise(keyValue);
            }

            reloadUI();
        }
    }, [franchiseMessages]);

    const me = useMember();

    const onCompletedUpdateProductMessage = (data: updateProductMessage) => {
        const {
            updateProductMessage: { ok, error, result },
        } = data;

        setWillSendUpdateMsg(false);

        if (ok) {
            if (result) {
                evictCacheRootQuery();
                alert(result);
                didSuccessSubmit();
            } else {
                evictCacheRootQuery();
                didSuccessSubmit();
            }
        } else {
            if (error !== "same msg") {
                alert(error);
            }
        }
    };

    const [updateProductMessageMutation] = useMutation<
        updateProductMessage,
        updateProductMessageVariables
    >(UPDATE_PRODUCT_MESSAGE_MUTATION, {
        onCompleted: onCompletedUpdateProductMessage,
        onError: (error) => {
            alert(error);
            setWillSendUpdateMsg(false);
        },
    });

    const onSelectFranchise = (keyValue: KeyValues) => {
        franchiseMessages.map((franchise) => {
            franchise.selected = false;

            if (franchise.key === keyValue.key) {
                franchise.selected = true;
            }
        });

        setSelectedFranchiseName(keyValue);
        setCheckProductMsgUI(checkProductMessage());

        reloadUI();
    };

    const reloadUI = () => {
        if (checkProductMessage()) {
            reloadProductUI();
        } else {
            reloadFranchiseUI();
        }
    };

    const selectedFranchiseMsg = () => {
        for (let i = 0; i < franchiseMessages.length; ++i) {
            const franchise = franchiseMessages[i];
            if (franchise.selected === true) {
                return franchise;
            }
        }
    };

    const selectedProductMsg = () => {
        for (let i = 0; i < franchiseMessages.length; ++i) {
            const franchise = franchiseMessages[i];

            if (franchise.selected === true) {
                for (let j = 0; j < franchise.products.length; ++j) {
                    const product = franchise.products[j];

                    if (product.selected === true) {
                        return product;
                    }
                }
            }
        }
    };

    const onSelectProduct = (KeyValue: KeyValues) => {
        franchiseMessages.map((franchise) => {
            if (franchise.selected === true) {
                franchise.products.map((product) => {
                    product.selected = false;

                    if (product.key === KeyValue.key) {
                        product.selected = true;
                    }
                });
            }
        });

        reloadProductUI();
    };

    const checkProductMessage = () => {
        let check = false;

        const selectedFranchise = selectedFranchiseMsg();
        if (selectedFranchise) {
            check = selectedFranchise.checkProductMessage;
        }

        return check;
    };

    const onCheckProductMessage = () => {
        const ok = window.confirm(
            "방 별 설정/해제를 하면 해당 지점에 작성해 둔 내용이 모두 사라집니다. 계속 진행하시겠습니까?",
        );

        if (!ok) {
            return;
        }

        const selectedFranchise = selectedFranchiseMsg();

        if (selectedFranchise) {
            selectedFranchise.messages = [""];
            selectedFranchise.page = 0;

            selectedFranchise.products.map((product) => {
                product.messages = [""];
                product.page = 0;
            });

            setInsertedMessage("");

            let check = !selectedFranchise.checkProductMessage;
            selectedFranchise.checkProductMessage = check;
            setCheckProductMsgUI(check);

            reloadUI();
        }
    };

    const reloadFranchiseUI = () => {
        const selectedFranchise = selectedFranchiseMsg();

        if (selectedFranchise) {
            setSelectedFranchiseUI(selectedFranchise);
            setSelectedProductUI(null);
            setCurrentPageUI(selectedFranchise.page + 1);
        }
        const msg = getInsertedMessage();
        setCurrentBytes(byte(msg));
        setInsertedTextUI(msg);
    };

    const reloadProductUI = () => {
        productNamesSetting();

        const selectedProduct = selectedProductMsg();
        if (selectedProduct) {
            setSelectedProductUI(selectedProduct);

            setSelectedProductName({
                key: selectedProduct.key,
                value: selectedProduct.name,
            });

            setCurrentPageUI(selectedProduct.page + 1);
        }

        setSelectedFranchiseUI(null);
        const msg = getInsertedMessage();
        setCurrentBytes(byte(msg));
        setInsertedTextUI(msg);
    };

    const productNamesSetting = () => {
        const selectedFranchise = selectedFranchiseMsg();

        if (selectedFranchise) {
            const temp: KeyValues[] = [];

            franchiseMessages.map((franchise) => {
                if (franchise.key === selectedFranchise.key) {
                    franchise.products.map((product) => {
                        if (product) {
                            temp.push({
                                key: product.key,
                                value: product.name,
                            });
                        }
                    });
                    return;
                }
            });

            setProductNames(temp);
        }
    };

    const getInsertedMessage = () => {
        let result = "";
        if (checkProductMessage()) {
            const product = selectedProductMsg();

            if (product) {
                result = product.messages[product.page];
            }
        } else {
            const franchise = selectedFranchiseMsg();

            if (franchise) {
                result = franchise.messages[franchise.page];
            }
        }

        return result;
    };

    const setInsertedMessage = (text: string) => {
        if (text.length <= TEXT_MAX_BYTES) {
            setCurrentBytes(byte(text));
            setInsertedTextUI(text);

            if (checkProductMessage()) {
                const selectedProduct = selectedProductMsg();

                if (selectedProduct) {
                    selectedProduct.messages[selectedProduct.page] = text;
                }
            } else {
                const selectedFranchise = selectedFranchiseMsg();

                if (selectedFranchise) {
                    selectedFranchise.messages[selectedFranchise.page] = text;
                }
            }
        }
    };

    const onClickPlusPage = () => {
        let cost = 0;

        if (system?.extraTextMessageCost) {
            cost = system.extraTextMessageCost;
        }
        const confirmMsg = `문자 개수를 추가하시겠습니까?(최대 ${MAX_PAGE_COUNT}개)\n\n** 문자는 기본 1개가 제공되며 개수 추가시 월 '${toWon(
            cost,
        )} x 추가된 문자의 수 x 방 개수' 만큼 추가 비용이 발생합니다.`;
        let errorMsg = null;
        if (checkProductMessage()) {
            const selectedProduct = selectedProductMsg();
            if (selectedProduct) {
                if (selectedProduct.messages.length < MAX_PAGE_COUNT) {
                    const ok = window.confirm(confirmMsg);

                    if (!ok) {
                        return;
                    }

                    selectedProduct.page = selectedProduct.messages.length;
                    selectedProduct.messages = [
                        ...selectedProduct.messages,
                        "",
                    ];
                }
            }
        } else {
            const selectedFranchise = selectedFranchiseMsg();
            if (selectedFranchise) {
                if (selectedFranchise.messages.length < MAX_PAGE_COUNT) {
                    const ok = window.confirm(confirmMsg);

                    if (!ok) {
                        return;
                    }

                    selectedFranchise.page = selectedFranchise.messages.length;
                    selectedFranchise.messages = [
                        ...selectedFranchise.messages,
                        "",
                    ];
                }
            }
        }

        if (errorMsg) {
            alert(errorMsg);
        }

        reloadUI();
    };

    const onClickMinusPage = () => {
        const confirmMsg = `현재 문자를 삭제하시겠습까?(되돌리기 불가)`;
        if (checkProductMessage()) {
            const selectedProduct = selectedProductMsg();
            if (selectedProduct) {
                const page = selectedProduct.page;
                if (page > 0) {
                    const ok = window.confirm(confirmMsg);

                    if (!ok) {
                        return;
                    }

                    selectedProduct.messages = selectedProduct.messages.filter(
                        (_, index) => index !== page,
                    );

                    selectedProduct.page -= 1;
                }
            }
        } else {
            const selectedFranchise = selectedFranchiseMsg();
            if (selectedFranchise) {
                const page = selectedFranchise.page;
                if (page > 0) {
                    const ok = window.confirm(confirmMsg);

                    if (!ok) {
                        return;
                    }

                    selectedFranchise.messages =
                        selectedFranchise.messages.filter(
                            (_, index) => index !== page,
                        );

                    selectedFranchise.page -= 1;
                }
            }
        }

        reloadUI();
    };

    const onClickLeftPage = () => {
        if (checkProductMessage()) {
            const selectedProduct = selectedProductMsg();
            if (selectedProduct) {
                let page = selectedProduct.page;
                page = page - 1;

                if (page >= 0) {
                    selectedProduct.page = page;
                }
            }
        } else {
            const selectedFranchise = selectedFranchiseMsg();
            if (selectedFranchise) {
                let page = selectedFranchise.page;
                page = page - 1;

                if (page >= 0) {
                    selectedFranchise.page = page;
                }
            }
        }

        reloadUI();
    };

    const onClickRightPage = () => {
        if (checkProductMessage()) {
            const selectedProduct = selectedProductMsg();
            if (selectedProduct) {
                let page = selectedProduct.page;
                page = page + 1;

                if (page < selectedProduct.messages.length) {
                    selectedProduct.page = page;
                }
            }
        } else {
            const selectedFranchise = selectedFranchiseMsg();
            if (selectedFranchise) {
                let page = selectedFranchise.page;
                page = page + 1;

                if (page < selectedFranchise.messages.length) {
                    selectedFranchise.page = page;
                }
            }
        }

        reloadUI();
    };

    const getEmptyMessageAlertText = () => {
        let alertText: string | null = null;
        for (
            let franchiseIndex = 0;
            franchiseIndex < franchiseMessages.length;
            ++franchiseIndex
        ) {
            const franchiseMessage = franchiseMessages[franchiseIndex];

            if (franchiseMessage.checkProductMessage === false) {
                if (franchiseMessage.messages.length === 0) {
                    alertText = `지점 ${franchiseMessage.name} 1페이지의 메시지 내용이 비어있습니다.`;
                } else {
                    for (
                        let msgIndex = 0;
                        msgIndex < franchiseMessage.messages.length;
                        ++msgIndex
                    ) {
                        const message = franchiseMessage.messages[msgIndex];

                        if (message.length === 0) {
                            alertText = `지점 ${franchiseMessage.name} ${
                                msgIndex + 1
                            }페이지의 메시지 내용이 비어있습니다.`;

                            break;
                        }
                    }
                }

                if (alertText !== null) {
                    break;
                }
            } else {
                for (
                    let productIndex = 0;
                    productIndex < franchiseMessage.products.length;
                    ++productIndex
                ) {
                    const productMessage =
                        franchiseMessage.products[productIndex];

                    if (productMessage.messages.length === 0) {
                        alertText = `지점 ${franchiseMessage.name}, 방 ${productMessage.name} 1페이지의 메시지 내용이 비어있습니다.`;
                    } else {
                        for (
                            let msgIndex = 0;
                            msgIndex < productMessage.messages.length;
                            ++msgIndex
                        ) {
                            const message = productMessage.messages[msgIndex];

                            if (message.length === 0) {
                                alertText = `지점 ${
                                    franchiseMessage.name
                                }, 방 ${productMessage.name} ${
                                    msgIndex + 1
                                }페이지의 메시지 내용이 비어있습니다.`;

                                break;
                            }
                        }

                        if (alertText !== null) {
                            break;
                        }
                    }
                }
            }
        }

        return alertText;
    };

    const onClickKakaoButton = () => {
        window.history.replaceState({}, "");
        window.location.replace(consoleRoutes.kakao);
    };

    const onClickConfirmButton = async () => {
        const alertText = getEmptyMessageAlertText();

        if (alertText) {
            alert(alertText);
        } else {
            if (firstInsertText === true) {
                franchiseMessages.map((franchiseMessage) => {
                    if (franchiseMessage.checkProductMessage === false) {
                        setWillSendUpdateMsg(true);
                        updateProductMessageMutation({
                            variables: {
                                franchiseIndex: franchiseMessage.index,
                                messages: franchiseMessage.messages,
                                isProductMsg: false,
                                sendToUserUpdatedMsg,
                            },
                        });
                    } else {
                        franchiseMessage.products.map((productMessage) => {
                            setWillSendUpdateMsg(true);
                            updateProductMessageMutation({
                                variables: {
                                    franchiseIndex: franchiseMessage.index,
                                    productIndex: productMessage.index,
                                    messages: productMessage.messages,
                                    isProductMsg: true,
                                    sendToUserUpdatedMsg,
                                },
                            });
                        });
                    }
                });
            } else {
                if (didClosePopup) {
                    didClosePopup();
                }
            }
        }
    };

    const getLastPage = () => {
        let lastPage = 1;
        if (checkProductMessage()) {
            const selectedProduct = selectedProductMsg();
            if (selectedProduct) {
                lastPage = selectedProduct.messages.length;
            }
        } else {
            const selectedFranchise = selectedFranchiseMsg();
            if (selectedFranchise) {
                lastPage = selectedFranchise.messages.length;
            }
        }

        return lastPage;
    };

    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        setInsertedMessage(text);
        setFirstInsertText(true);
    };

    const disableConfirmBtn = () => {
        //메시지 전체를 확인하여 TEXT_MAX_BYTES 만큼 길이가 넘어가면 메시지 저장이 되지않도록 적용
        let result = false;

        if (currentBytes > TEXT_MAX_BYTES) {
            result = true;
        }

        for (
            let franchiseIndex = 0;
            franchiseIndex < franchiseMessages.length;
            ++franchiseIndex
        ) {
            const franchiseMessage = franchiseMessages[franchiseIndex];

            if (franchiseMessage.checkProductMessage === false) {
                for (
                    let msgIndex = 0;
                    msgIndex < franchiseMessage.messages.length;
                    ++msgIndex
                ) {
                    const message = franchiseMessage.messages[msgIndex];

                    if (byte(message) > TEXT_MAX_BYTES) {
                        result = true;
                        break;
                    }
                }
            } else {
                for (
                    let productIndex = 0;
                    productIndex < franchiseMessage.products.length;
                    ++productIndex
                ) {
                    const product = franchiseMessage.products[productIndex];

                    for (
                        let msgIndex = 0;
                        msgIndex < product.messages.length;
                        ++msgIndex
                    ) {
                        const message = product.messages[msgIndex];

                        if (byte(message) > TEXT_MAX_BYTES) {
                            result = true;
                            break;
                        }
                    }
                }
            }
        }

        if (willSendUpdateMsg === true) {
            result = true;
        }

        return result;
    };

    useQuery<seeFranchises>(SEE_FRANCHISES_QUERY, {
        onCompleted: (data: seeFranchises) => {
            const {
                seeFranchises: { ok, error, franchises: recvFranchises },
            } = data;

            if (ok) {
                setFranchiseMessages([]);

                const scTemp: seeFranchises_seeFranchises_franchises[] = [];
                const naTemp: seeFranchises_seeFranchises_franchises[] = [];
                recvFranchises?.map((franchise) => {
                    if (franchise) {
                        if (franchise.host.site === SiteType.SC) {
                            scTemp.push(franchise);
                        } else if (franchise.host.site === SiteType.NA) {
                            naTemp.push(franchise);
                        }
                    }
                });

                let temp = scTemp;

                if (temp.length === 0) {
                    temp = naTemp;
                }

                temp.map((franchise, index) => {
                    setFranchiseNames((prev) => [
                        ...prev,
                        { key: franchise.key, value: franchise.name },
                    ]);

                    let fmsg: IFranchiseProductMessage | null = null;
                    let fmsgs: IFranchiseProductMessage[] = franchiseMessages;

                    fmsgs.map((f) => {
                        if (f.key === franchise.key) {
                            fmsg = f;
                            return;
                        }
                    });

                    if (fmsg === null) {
                        let selected = false;

                        if (index === 0) {
                            selected = true;
                        }

                        let messages: string[] = [];

                        if (
                            franchise.messages &&
                            franchise.messages.length > 0
                        ) {
                            if (franchise.isProductMessage === false) {
                                franchise.messages.map((msg) => {
                                    if (msg) {
                                        messages.push(msg);
                                    }
                                });
                            }
                        } else {
                            messages = [""];
                        }

                        fmsg = {
                            ...franchise,
                            messages,
                            products: [],
                            page: 0,
                            checkProductMessage: franchise.isProductMessage,
                            selected,
                        };
                        fmsgs.push(fmsg);
                    }

                    franchise.products?.map((product, index) => {
                        if (product) {
                            if (fmsg) {
                                let selected = false;

                                if (index === 0) {
                                    selected = true;
                                }

                                let messages: string[] = [];

                                if (franchise.isProductMessage === true) {
                                    if (
                                        product.messages &&
                                        product.messages.length > 0
                                    ) {
                                        product.messages.map((msg) => {
                                            if (msg) {
                                                messages.push(msg);
                                            }
                                        });
                                    } else {
                                        messages = [""];
                                    }
                                } else {
                                    messages = [""];
                                }

                                fmsg.products.push({
                                    ...product,
                                    messages,
                                    page: 0,
                                    selected,
                                });
                            }
                        }
                    });

                    setFranchiseMessages(fmsgs);
                    const selectedFranchise = selectedFranchiseMsg();

                    if (selectedFranchise) {
                        // console.log("selected:", selectedFranchise);
                        setSelectedFranchiseUI(selectedFranchise);
                    }
                });
            } else {
                alert(error);
            }
        },
    });

    const onSelectCheckSendToUserMsg = () => {
        if (sendToUserUpdatedMsg === true) {
            const ok = window.confirm(
                "체크가 해제되면 메시지 변경 시\n이미 메시지가 전송된 예약자들에게\n메시지를 재전송하지 않습니다.\n\n(단, 체크가 해제되어도 변경 이후에\n들어온 예약은 변경된 메시지로 전송됩니다.)\n\n계속 진행하시겠습니까?",
            );

            if (ok) {
                setSendToUserUpdatedMsg(!sendToUserUpdatedMsg);
            }
        } else {
            setSendToUserUpdatedMsg(!sendToUserUpdatedMsg);
        }
    };

    return (
        <Popup
            maxWidth="700px"
            didClosePopup={didClosePopup}
            disabledBackgrounClickClose={true}
        >
            <Container>
                <Header>
                    <SelectBox
                        key_values={franchiseNames}
                        selectValue={selectedFranchiseName?.value}
                        onSelectValue={(key_value) => {
                            onSelectFranchise(key_value);
                        }}
                    />
                    {viewer === false ? (
                        <CheckBoxWithText
                            style={{ marginLeft: "30px", whiteSpace: "nowrap" }}
                            isChecked={checkProductMsgUI}
                            title="방 별로 설정"
                            onClick={() => {
                                onCheckProductMessage();
                            }}
                        />
                    ) : (
                        <Text
                            onClick={() => {
                                copyToClipboard(insertedTextUI);
                            }}
                        >
                            복사
                        </Text>
                    )}
                </Header>
                {checkProductMsgUI && selectedProductName ? (
                    <Header>
                        <SelectBox
                            key_values={productNames}
                            selectValue={selectedProductName.value}
                            onSelectValue={(key_value) => {
                                onSelectProduct(key_value);
                            }}
                        />
                    </Header>
                ) : null}
                <TextLengthContainer>
                    {currentBytes <= TEXT_MAX_BYTES ? (
                        <TextLength>{`${currentBytes} / ${TEXT_MAX_BYTES} Bytes`}</TextLength>
                    ) : (
                        <TextLength>
                            <TextLengthError>
                                {`${currentBytes}`}
                            </TextLengthError>
                            {` / ${TEXT_MAX_BYTES} Bytes`}
                        </TextLength>
                    )}
                </TextLengthContainer>

                {selectedFranchiseUI ? (
                    <InputContainer>
                        <TextArea
                            disabled={viewer}
                            onChange={onChangeText}
                            value={insertedTextUI}
                            placeholder={`이곳에 지점 ${selectedFranchiseUI.name}의 문자 내용을 작성해주세요[문자가 전송되면 첫 페이지 하단에 방 이름(예약 상품)과 예약 사용 시간이 자동으로 포함됩니다]`}
                        />
                    </InputContainer>
                ) : null}
                {selectedProductUI ? (
                    <InputContainer>
                        <TextArea
                            onChange={onChangeText}
                            value={insertedTextUI}
                            placeholder={`이곳에 ${selectedProductUI.name}의 문자 내용을 작성해주세요[문자가 전송되면 첫 페이지 하단에 방 이름(예약 상품)과 예약 사용 시간이 자동으로 포함됩니다]`}
                        />
                    </InputContainer>
                ) : null}
                <Footer>
                    {viewer === false ? (
                        <FooterButton
                            onClick={onClickMinusPage}
                            disabled={currentPageUI === 1 ? true : false}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </FooterButton>
                    ) : null}

                    <FooterCenter>
                        <FooterButton
                            onClick={onClickLeftPage}
                            disabled={currentPageUI === 1 ? true : false}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </FooterButton>
                        <FooterPage>{`${currentPageUI}`}</FooterPage>
                        <FooterButton
                            onClick={onClickRightPage}
                            disabled={
                                currentPageUI === getLastPage() ? true : false
                            }
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </FooterButton>
                    </FooterCenter>
                    {viewer === false ? (
                        <FooterButton
                            onClick={onClickPlusPage}
                            disabled={
                                getLastPage() === MAX_PAGE_COUNT ? true : false
                            }
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </FooterButton>
                    ) : null}
                </Footer>
                {viewer === false ? (
                    <>
                        <Hint>
                            {me?.signUpCompleteStep !==
                            SignUpCompleteStep.COMPLETION
                                ? `문자 내용을 수정할 경우, 수정 전 문자를 받은 이용자들에게도 새로운 내용으로 다시 전송 됩니다.`
                                : `금일~익일 11am까지 이용은 예약과 동시에 안내 문자 전송, 그외에 모든 안내 문자는 이용 당일 11am에 일괄 전송됩니다.`}
                        </Hint>
                        <CheckBoxWithText
                            style={{ padding: "0px" }}
                            isChecked={sendToUserUpdatedMsg}
                            title="전송 완료된 예약자에게 재전송"
                            onClick={onSelectCheckSendToUserMsg}
                        />

                        <FormButton
                            title="예약 문자 등록"
                            onClick={onClickConfirmButton}
                            disabled={disableConfirmBtn()}
                        />
                        {me?.frontVersion &&
                        system?.frontVersion &&
                        me.frontVersion >= system.frontVersion ? (
                            <FormButton
                                title="카카오톡으로 전환하기"
                                onClick={onClickKakaoButton}
                                backgroundColor="#f7e50b"
                                color="#391c1e"
                            />
                        ) : null}
                    </>
                ) : null}
            </Container>
        </Popup>
    );
}
