import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { client, isSignedInVar } from "./apollo";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme } from "./styles";
import routes from "./routes";
import Layout from "./components/Layout";
import { Home } from "./screens/Home";
import { SingUp } from "./screens/SignUp";
import { Privacy } from "./screens/Privacy";
import { Terms } from "./screens/Terms";
import { SingIn } from "./screens/SignIn";
import { Console } from "./screens/Console";
import { HelmetProvider } from "react-helmet-async";
import { FAQDetail } from "./components/faq/FAQDetail";
import { InfoItemsNewPage } from "./components/home/InfoItemsNewPage";

function App() {
    const isSignedIn = useReactiveVar(isSignedInVar);

    return (
        <ApolloProvider client={client}>
            <HelmetProvider>
                <ThemeProvider theme={{ ...lightTheme }}>
                    <GlobalStyles />
                    <BrowserRouter>
                        <Routes>
                            <Route path={routes.signup} element={<SingUp />} />
                            <Route
                                path={routes.consulting}
                                element={<SingUp />}
                            />
                            <Route
                                path={routes.signin}
                                element={
                                    isSignedIn ? (
                                        <Navigate replace to={routes.console} />
                                    ) : (
                                        <SingIn />
                                    )
                                }
                            />

                            <Route
                                path={routes.privacy}
                                element={<Privacy />}
                            />
                            <Route path={routes.terms} element={<Terms />} />
                            <Route
                                path={routes.saleItems}
                                element={<InfoItemsNewPage />}
                            />
                            <Route
                                path={routes.console}
                                element={
                                    isSignedIn ? (
                                        <Layout>
                                            <Console />
                                        </Layout>
                                    ) : (
                                        <Navigate replace to={routes.signin} />
                                    )
                                }
                            />
                            <Route
                                path={routes.faq}
                                element={<FAQDetail newTab={true} />}
                            />
                            <Route
                                path="/*"
                                element={
                                    <Layout>
                                        <Home />
                                    </Layout>
                                }
                            />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </HelmetProvider>
        </ApolloProvider>
    );
}

export default App;
