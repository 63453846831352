import { ApolloClient, InMemoryCache, makeVar } from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { consoleRoutes } from "./routes";
import { createUploadLink } from "apollo-upload-client";

const LOGIN_INFO = "LOGIN_INFO";
const TOKEN = "TOKEN";
const SIGNED_IN = "SIGNED_IN";
const MINIMIZE_NAVIGATION = "MINIMIZE_NAVIGATION";

let storage = sessionStorage;

if (localStorage.getItem(TOKEN)) {
    storage = localStorage;
}

interface IOnbiLoginInfoData {
    loginId: string;
    token: string;
    signedIn: boolean;
}

interface IOnbiLoginInfos {
    datas: IOnbiLoginInfoData[];
}

const getOnbiLoginInfo = () => {
    let onbiInfo: IOnbiLoginInfos | null = null;
    const loginInfoStr = localStorage.getItem(LOGIN_INFO);
    if (loginInfoStr && loginInfoStr.length > 0) {
        onbiInfo = JSON.parse(loginInfoStr) as IOnbiLoginInfos;
    }

    return onbiInfo;
};

const getOnbiLoginInfoToken = () => {
    let token: string | null = null;
    const loginInfo = getOnbiLoginInfo();

    if (loginInfo) {
        for (let i = 0; i < loginInfo.datas.length; ++i) {
            const data = loginInfo.datas[i];

            if (data.signedIn === true) {
                token = data.token;
                break;
            }
        }
    }

    return token;
};

const allOnbiLoginInfoSignInFalse = () => {
    const onbiLoginInfo = getOnbiLoginInfo();

    if (onbiLoginInfo) {
        onbiLoginInfo.datas.map((data) => {
            data.signedIn = false;
        });

        const loginInfosStr = JSON.stringify(onbiLoginInfo);
        localStorage.setItem(LOGIN_INFO, loginInfosStr);
    }
};

export const selectOnbiLoginInfo = (loginId: string) => {
    allOnbiLoginInfoSignInFalse();

    const onbiLoginInfo = getOnbiLoginInfo();

    if (onbiLoginInfo) {
        for (let i = 0; i < onbiLoginInfo.datas.length; ++i) {
            const data = onbiLoginInfo.datas[i];

            if (data.loginId === loginId) {
                data.signedIn = true;
                break;
            }
        }

        const loginInfosStr = JSON.stringify(onbiLoginInfo);
        localStorage.setItem(LOGIN_INFO, loginInfosStr);

        window.history.replaceState({}, "");
        window.location.replace(consoleRoutes.bookings);
    }
};

const addOnbiLoginInfo = (param: { loginId: string; token: string }) => {
    allOnbiLoginInfoSignInFalse();

    let onbiLoginInfo = getOnbiLoginInfo();

    if (onbiLoginInfo) {
        if (hasOnbiLoginInfoId(param.loginId) === false) {
            onbiLoginInfo.datas.push({
                signedIn: true,
                loginId: param.loginId,
                token: param.token,
            });
        } else {
            for (let i = 0; i < onbiLoginInfo.datas.length; ++i) {
                const data = onbiLoginInfo.datas[i];

                if (data.loginId === param.loginId) {
                    data.signedIn = true;
                    data.token = param.token;
                    break;
                }
            }
        }
    } else {
        onbiLoginInfo = {
            datas: [
                { signedIn: true, loginId: param.loginId, token: param.token },
            ],
        };
    }

    const loginInfosStr = JSON.stringify(onbiLoginInfo);
    localStorage.setItem(LOGIN_INFO, loginInfosStr);
};

export const getOnbiLoginInfoDatas = () => {
    let datas: IOnbiLoginInfoData[] = [];
    const loginInfo = getOnbiLoginInfo();
    if (loginInfo) {
        datas = loginInfo.datas;
    }

    return datas;
};

export const hasOnbiLoginInfoId = (loginId: string) => {
    let has = false;
    const datas = getOnbiLoginInfoDatas();

    for (let i = 0; i < datas.length; ++i) {
        const data = datas[i];

        if (data.loginId === loginId) {
            has = true;
            break;
        }
    }

    return has;
};

export const isSignedIn = () => {
    let result = false;
    let token = getOnbiLoginInfoToken();

    if (!token) {
        token = storage.getItem(TOKEN);
    }

    if (token && token.length > 0) {
        result = true;
    }

    if (result === true && getOnbiLoginInfoToken() === null) {
        const signedIn = storage.getItem(SIGNED_IN);
        if (!signedIn || signedIn.length === 0) {
            result = false;
        }
    }

    return result;
};

export const isSignedInVar = makeVar(
    Boolean(storage.getItem(TOKEN) || getOnbiLoginInfoToken()),
);

export const signUserIn = (param: {
    loginId: string;
    token: string;
    autoSignIn: boolean;
    refresh: boolean;
}) => {
    sessionStorage.setItem(TOKEN, param.token);
    sessionStorage.setItem(SIGNED_IN, param.token);
    isSignedInVar(true);

    if (param.autoSignIn) {
        addOnbiLoginInfo(param);
    }

    if (param.refresh) {
        window.history.replaceState({}, "");
        window.location.replace(consoleRoutes.bookings);
    }
};

export const minimizeNavigationBar = (param: { minimize: boolean }) => {
    localStorage.setItem(MINIMIZE_NAVIGATION, String(param.minimize));
};

export const isMinimizeNavigationBar = () => {
    let isMinimize = false;
    let min = localStorage.getItem(MINIMIZE_NAVIGATION);

    if (min) {
        isMinimize = /true/i.test(min);
    }

    return isMinimize;
};

export const signUserOut = () => {
    removeTokens();
    window.history.replaceState({}, "");
    window.location.reload();
};

export const refresh = () => {
    window.history.replaceState({}, "");
    window.location.reload();
};

export const removeTokens = () => {
    sessionStorage.removeItem(TOKEN);
    localStorage.removeItem(TOKEN);
    sessionStorage.removeItem(SIGNED_IN);
    localStorage.removeItem(SIGNED_IN);
    localStorage.removeItem(LOGIN_INFO);
};

let refreshId: number | null = null;

export const hasRefreshIntervalId = () => makeVar(Boolean(refreshId));

export const setRefreshIntervalId = (id: number) => {
    if (refreshId !== null && refreshId !== id) {
        //아이디가 다르면 기존 아이디 interval 정지
        clearInterval(refreshId);
    }

    refreshId = id;
};

// const uploadLink = createUploadLink({
//     uri:
//         process.env.NODE_ENV === "production"
//             ? "https://pontis-onbi-backend-management.herokuapp.com/graphql"
//             : "http://localhost:4000/graphql",
// });

const uploadLink = createUploadLink({
    uri:
        process.env.NODE_ENV === "production"
            ? "https://pontis-onbi-backend-management.herokuapp.com/graphql"
            : "https://pontis-onbi-backend-management.herokuapp.com/graphql",
});

const authLink = setContext((_, { headers }) => {
    let token = getOnbiLoginInfoToken();

    if (token === null) {
        token = sessionStorage.getItem(TOKEN);
    }

    if (token === null) {
        token = localStorage.getItem(TOKEN);
    }

    return {
        headers: {
            ...headers,
            token,
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache({
        typePolicies: {
            Franchise: {
                keyFields: (obj) => `Franchise: ${obj.key}`,
            },
            Product: {
                keyFields: (obj) => `Product: ${obj.key}`,
            },
            Member: {
                keyFields: (obj) => `Member: ${obj.key}`,
            },
            Booking: {
                keyFields: (obj) => `Booking: ${obj.key}`,
            },
            Message: {
                keyFields: (obj) => `Message: ${obj.groupId},${obj.messageId}`,
            },
        },
    }),
});
