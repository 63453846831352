import styled from "styled-components";
import { InfoItemAdditionsGrid } from "./InfoItemAdditionsGrid";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.white};
    padding: 90px;

    @media ${({ theme }) => theme.tablet} {
        display: none;
    }
`;

const Title = styled.span`
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: 700;
`;

export function InfoItemAdditions() {
    return (
        <Container>
            <Title>부가서비스</Title>
            <InfoItemAdditionsGrid />
        </Container>
    );
}
