import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { SEE_FRANCHISES_QUERY } from "../../../graphqls/queries";
import { useBookingHeaderHeight } from "../../../hooks/useBookingHeaderHeight";
import { useNavigationWidth } from "../../../hooks/useNavigationWidth";
import { lightTheme } from "../../../styles";
import { KeyValues } from "../../../utils";
import {
    seeFranchises,
    seeFranchisesVariables,
    seeFranchises_seeFranchises_franchises,
} from "../../../__generated__/seeFranchises";
import {
    IProduct,
    onCompletedSeeFranchises,
    updateProduct,
} from "../../bookings/Bookings";
import {
    BookingSelectorContentInner,
    BookingsSelectorContainer,
    BookingsSelectorContent,
} from "../../commonBookingHeaders/BookingHeaderStyles";
import { DateSelector } from "../../commonBookingHeaders/DateSelector";
import { DateColloct, DateSwitch } from "../../commonBookingHeaders/DateSwitch";
import { FranchiseSelector } from "../../commonBookingHeaders/FranchiseSelector";
import {
    FRANCHISE_SELECT_ALL,
    ProductSelector,
    PRODUCT_SELECT_ALL,
} from "../../commonBookingHeaders/ProductSelector";
import { HEADER_HEIGHT } from "../../Header";
import { StatisticsBookingGraph } from "./StatisticsBookingsGraph";
import { useLocation } from "react-router-dom";
import { consoleRoutes } from "../../../routes";

const Container = styled.div<{ navigationWidth: string }>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.white};
    width: calc(100vw - ${(props) => props.navigationWidth});
`;

const ContentScroll = styled.div<{
    navigationWidth: string;
    bookingHeaderHeight: string;
}>`
    display: inline-block;
    overflow-x: hidden;
    overflow-y: overlay;
    border-top: 1px solid;
    border-color: ${(props) => props.theme.border};
    min-width: calc(100vw - ${(props) => props.navigationWidth});
    max-height: calc(
        100vh - ${HEADER_HEIGHT} - ${(props) => props.bookingHeaderHeight} - 1px
    );
`;

interface IStatisticsBookingsProps {
    navigationMinimize: boolean;
}

export function StatisticsBookings({
    navigationMinimize,
}: IStatisticsBookingsProps) {
    const navigationWidth = useNavigationWidth();
    const bookingHeaderHeight = useBookingHeaderHeight(false);
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    const [franchises, setFranchises] = useState<
        seeFranchises_seeFranchises_franchises[]
    >([]);
    const [_, setProducts] = useState<IProduct[]>([]);
    const [franchiseNames, setFranchiseNames] = useState<KeyValues[]>([]);
    const [productNames, setProductNames] = useState<KeyValues[]>([]);
    const [selectedFranchiseIndex, setSelectedFranchiseIndex] =
        useState(FRANCHISE_SELECT_ALL);
    const [selectedProductIndex, setSelectedProductIndex] =
        useState(PRODUCT_SELECT_ALL);
    const [baseDate, setBaseDate] = useState(new Date());
    const [dateCollector, setDateCollector] = useState(DateColloct.Day);
    const routes: KeyValues[] = [
        { key: DateColloct.Day, value: consoleRoutes.statistics_bookings_day },
        {
            key: DateColloct.Calendar,
            value: consoleRoutes.statistics_bookings_calendar,
        },
    ];
    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname.includes(
                consoleRoutes.statistics_bookings_calendar,
            )
        ) {
            setDateCollector(DateColloct.Calendar);
        } else {
            setDateCollector(DateColloct.Day);
        }
    }, [location.pathname]);

    useEffect(() => {
        const date = new Date();
        if (dateCollector === DateColloct.Day) {
            setBaseDate(date);
        } else {
            date.setDate(1);
            setBaseDate(date);
        }
    }, [dateCollector]);

    useEffect(() => {
        if (selectedFranchiseIndex !== FRANCHISE_SELECT_ALL) {
            updateProduct(
                franchises,
                selectedFranchiseIndex,
                true,
                setProducts,
                setProductNames,
            );
        } else {
            setProductNames([
                { key: `${PRODUCT_SELECT_ALL}`, value: "선택 불가" },
            ]);
        }
    }, [franchises, selectedFranchiseIndex]);

    useEffect(() => {
        const date = new Date();
        date.setFullYear(baseDate.getFullYear());
        date.setMonth(baseDate.getMonth());
        date.setDate(baseDate.getDate());

        setBaseDate(date);
    }, [navigationMinimize]);

    useQuery<seeFranchises, seeFranchisesVariables>(SEE_FRANCHISES_QUERY, {
        onCompleted: (data) => {
            onCompletedSeeFranchises(
                data,
                true,
                setFranchiseNames,
                setFranchises,
            );
        },
    });

    const onSelectFranchise = (index: number) => {
        setProducts([]);
        setSelectedProductIndex(PRODUCT_SELECT_ALL);
        setSelectedFranchiseIndex(index);
    };

    const getFranchiseIndex = (selectedIndex: number) => {
        let result: number = 0;

        for (let i = 0; i < franchiseNames.length; ++i) {
            const keyValue = franchiseNames[i];
            if (keyValue.key === String(selectedIndex)) {
                result = i;
            }
        }

        return result;
    };

    const getProductIndex = (selectedIndex: number) => {
        let result: number = 0;

        for (let i = 0; i < productNames.length; ++i) {
            const keyValue = productNames[i];
            if (keyValue.key === String(selectedIndex)) {
                result = i;
            }
        }

        return result;
    };

    return (
        <Container navigationWidth={navigationWidth}>
            <BookingsSelectorContainer
                bookingHeaderHeight={bookingHeaderHeight}
            >
                <BookingsSelectorContent>
                    {isMobile ? (
                        <>
                            <BookingSelectorContentInner>
                                <DateSelector
                                    dateCollector={dateCollector}
                                    date={baseDate}
                                    onChangeDate={(date) => {
                                        setBaseDate(date);
                                    }}
                                />
                            </BookingSelectorContentInner>
                            <BookingSelectorContentInner
                                style={{ marginTop: "10px" }}
                            >
                                <DateSwitch
                                    style={{
                                        height: "100%",
                                        margin: "0px",

                                        borderColor: lightTheme.border,
                                    }}
                                    dateCollector={dateCollector}
                                    routes={routes}
                                />
                                <FranchiseSelector
                                    style={{
                                        height: "100%",
                                        margin: "0px",
                                        marginLeft: "10px",
                                        borderColor: lightTheme.border,
                                    }}
                                    franchiseNames={franchiseNames}
                                    selectedIndex={getFranchiseIndex(
                                        selectedFranchiseIndex,
                                    )}
                                    onSelectedIndex={(index) => {
                                        const keyValue = franchiseNames[index];
                                        onSelectFranchise(Number(keyValue.key));
                                    }}
                                />
                                <ProductSelector
                                    disabled={
                                        selectedFranchiseIndex ===
                                        FRANCHISE_SELECT_ALL
                                            ? true
                                            : false
                                    }
                                    style={{
                                        height: "100%",
                                        margin: "0px",
                                        marginLeft: "10px",
                                        borderColor: lightTheme.border,
                                    }}
                                    productNames={productNames}
                                    selectedIndex={getProductIndex(
                                        selectedProductIndex,
                                    )}
                                    onSelectedIndex={(index) => {
                                        const keyValue = productNames[index];
                                        setSelectedProductIndex(
                                            Number(keyValue.key),
                                        );
                                    }}
                                />
                            </BookingSelectorContentInner>
                        </>
                    ) : (
                        <BookingSelectorContentInner>
                            <DateSwitch
                                style={{
                                    height: "100%",
                                    margin: "0px",

                                    borderColor: lightTheme.border,
                                }}
                                dateCollector={dateCollector}
                                routes={routes}
                            />
                            <FranchiseSelector
                                style={{
                                    height: "100%",
                                    margin: "0px",
                                    marginLeft: "10px",
                                    borderColor: lightTheme.border,
                                }}
                                franchiseNames={franchiseNames}
                                selectedIndex={getFranchiseIndex(
                                    selectedFranchiseIndex,
                                )}
                                onSelectedIndex={(index) => {
                                    const keyValue = franchiseNames[index];
                                    onSelectFranchise(Number(keyValue.key));
                                }}
                            />
                            <ProductSelector
                                disabled={
                                    selectedFranchiseIndex ===
                                    FRANCHISE_SELECT_ALL
                                        ? true
                                        : false
                                }
                                style={{
                                    height: "100%",
                                    margin: "0px",
                                    marginLeft: "10px",
                                    borderColor: lightTheme.border,
                                }}
                                productNames={productNames}
                                selectedIndex={getProductIndex(
                                    selectedProductIndex,
                                )}
                                onSelectedIndex={(index) => {
                                    const keyValue = productNames[index];
                                    setSelectedProductIndex(
                                        Number(keyValue.key),
                                    );
                                }}
                            />
                            <DateSelector
                                style={{ marginLeft: "10px" }}
                                dateCollector={dateCollector}
                                date={baseDate}
                                onChangeDate={(date) => {
                                    setBaseDate(date);
                                }}
                            />
                        </BookingSelectorContentInner>
                    )}
                </BookingsSelectorContent>
            </BookingsSelectorContainer>
            <ContentScroll
                navigationWidth={navigationWidth}
                bookingHeaderHeight={bookingHeaderHeight}
            >
                <StatisticsBookingGraph
                    selectedFranchiseIndex={selectedFranchiseIndex}
                    selectedProductIndex={selectedProductIndex}
                    baseDate={baseDate}
                    isMonth={dateCollector === DateColloct.Day ? false : true}
                />
            </ContentScroll>
        </Container>
    );
}
