export interface KeyValues {
    key: string;
    value: string;
}

export function makeSameKeyValueList(keys: string[]) {
    let result: Array<KeyValues> = [];
    keys.map((key) => {
        result.push({
            key,
            value: key,
        });
    });

    return result;
}

export function openUrlNewTab(url: string) {
    window.open(url, "_blank")?.focus();
}

export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
        .then(() => {
            // 복사 성공 시 토스트 메시지
            showToast("복사됨");
        })
        .catch(err => {
            // 복사 실패 시 오류 메시지
            showToast("복사에 실패");
            console.error("Failed to copy: ", err);
        });
}

function showToast(message: string) {
    // 간단한 토스트 메시지 구현
    const toast = document.createElement("div");
    toast.textContent = message;
    toast.style.position = "fixed";
    toast.style.bottom = "20px";
    toast.style.left = "50%";
    toast.style.transform = "translateX(-50%)";
    toast.style.backgroundColor = "black";
    toast.style.color = "white";
    toast.style.padding = "10px";
    toast.style.borderRadius = "5px";
    toast.style.zIndex = "1000";
    document.body.appendChild(toast);

    // 3초 후에 토스트 메시지 제거
    setTimeout(() => {
        document.body.removeChild(toast);
    }, 3000);
}

export const phoneHyphen = (target: string) => {
    const result = target
        .replace(/[^0-9, *]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    return result;
};

export const isIOS = () => {
    let result = false;
    const agent = navigator.userAgent.toLowerCase();

    if (agent.indexOf("android") > -1) {
        // 안드로이드인 경우
    } else if (
        agent.indexOf("iphone") > -1 ||
        agent.indexOf("ipad") > -1 ||
        agent.indexOf("ipod") > -1
    ) {
        result = true;
        // IOS인 경우
    } else {
        // 기타
    }

    return result;
};
