import styled from "styled-components";

const SForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    padding-bottom: 20px;
`;

interface IFormProps {
    children: any;
    onSubmit?: React.FormEventHandler;
}

export function Form({ children, onSubmit }: IFormProps) {
    return <SForm onSubmit={onSubmit}>{children}</SForm>;
}
