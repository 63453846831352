import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { SEE_BOARD } from "../../graphqls/queries";
import {
    seeBoard,
    seeBoard_seeBoard_board,
} from "../../__generated__/seeBoard";
import {
    BOARD_CHANGE_EMAIL_INDEX,
    FAQDetailChangeEmail,
} from "./FAQDetailChangeEmail";
import { FAQDetailHeader } from "./FAQDetailHeader";

const Base = styled.div`
    display: flex;
    width: 100%;
`;

const Container = styled.div<{ newTab: boolean }>`
    display: flex;
    flex-direction: column;
    padding: ${(props) => (props.newTab ? "30px" : "10px")};
    width: 80%;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.black};
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${(props) => props.theme.fontGray};
    width: 100%;
`;

export const FAQImage = styled.img`
    max-width: 100%;
`;

export const FAQText = styled.span`
    font-size: 15px;
    line-height: 170%;
    width: 100%;
`;

export const FAQEnter = styled.span`
    padding: 10px;
`;

interface IFAQDetailProps {
    idx?: number;
    newTab: boolean;
}

export function FAQDetail({ idx, newTab }: IFAQDetailProps) {
    const location = useLocation();
    const [board, setBoard] = useState<seeBoard_seeBoard_board>();
    const [error, setError] = useState("");

    let index = -1;

    if (!idx) {
        const pathnames = location.pathname.split("/");
        const last = pathnames[pathnames.length - 1];
        if (last.length > 0) {
            index = Number(last);
            if (isNaN(index)) {
                index = -1;
            }
        }
    } else {
        index = idx;
    }

    useQuery<seeBoard>(SEE_BOARD, {
        variables: {
            index,
            newTab,
        },
        onCompleted: (data: seeBoard) => {
            const {
                seeBoard: { ok, error, board },
            } = data;
            if (ok) {
                if (board) {
                    setBoard(board);
                }
            } else {
                if (error) {
                    setError(error);
                }
            }
        },
    });

    const getBoardInner = (index: number, board: seeBoard_seeBoard_board) => {
        let result = <></>;

        if (index === -1) {
            result = <>{"잘못된 접근입니다."}</>;
        } else if (index === BOARD_CHANGE_EMAIL_INDEX) {
            result = <FAQDetailChangeEmail board={board} newTab={newTab} />;
        }

        return result;
    };

    return (
        <Base>
            <Container newTab={newTab}>
                {board ? (
                    <>
                        <FAQDetailHeader title={board.title} newTab={newTab} />
                        {getBoardInner(index, board)}
                        <Footer>
                            <FAQText>
                                {
                                    "해당 게시글 및 기타 궁금하신 사항이 있으신 경우"
                                }
                            </FAQText>
                            <FAQText>
                                {
                                    "고객센터(031-341-8880, 월~금 13:00~19:00)로 문의해주시기 바랍니다."
                                }
                            </FAQText>
                        </Footer>
                    </>
                ) : error ? (
                    <>{error}</>
                ) : null}
            </Container>
        </Base>
    );
}
