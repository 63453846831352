import styled from "styled-components";
import routes from "../routes";
import { ButtonSpan, LogoFontSpan } from "../styles";
import { openUrlNewTab } from "../utils";

const Container = styled.footer`
    display: flex;
    background-color: ${(props) => props.theme.footerBackground};
    color: ${(props) => props.theme.fontGray};
    min-width: 840px;

    @media ${({ theme }) => theme.tablet} {
        min-width: 320px;
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 180px;

    @media ${({ theme }) => theme.tablet} {
        padding: 20px;
    }

    line-height: 120%;
    width: 100%;
`;

const Logo = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

const HeaderButtonContainer = styled.div`
    display: flex;
`;

const Text = styled.span`
    font-size: 13px;
`;

const Button = styled(ButtonSpan)`
    font-size: 13px;
`;

const LogoWhite = styled(LogoFontSpan)`
    font-size: 25px;
    color: ${(props) => props.theme.white};
`;

const LogoBlue = styled(LogoFontSpan)`
    font-size: 25px;
    color: ${(props) => props.theme.blue};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

const CompanyName = styled.span`
    font-size: 18px;
    font-weight: 800;
    color: ${(props) => props.theme.white};
`;

export function Footer() {
    return (
        <Container>
            <Inner>
                <Logo>
                    <LogoWhite>
                        ON<LogoBlue>BI</LogoBlue>
                    </LogoWhite>
                </Logo>
                <Header>
                    <CompanyName>(주)폰티스</CompanyName>
                    <HeaderButtonContainer>
                        <Button onClick={() => openUrlNewTab(routes.privacy)}>
                            개인정보 처리방침
                        </Button>
                        <Text style={{ margin: "0px 10px" }}>|</Text>
                        <Button onClick={() => openUrlNewTab(routes.terms)}>
                            온비 이용약관
                        </Button>
                    </HeaderButtonContainer>
                </Header>
                <Body>
                    <Text>대표이사: 박태준 | 사업자등록번호: 404-86-02543</Text>
                    <Text>
                        고객센터:{" "}
                        <Text
                            onClick={() => {
                                window.location.href = "tel:031-341-8880";
                            }}
                        >
                            031-341-8880
                        </Text>{" "}
                        | 지원:{" "}
                        <Text
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                window.location.href =
                                    "mailto:support@pontis.co.kr";
                            }}
                        >
                            support@pontis.co.kr
                        </Text>{" "}
                        | 운영시간: (월~금) 13:00 ~ 19:00
                    </Text>
                    <Text>경기도 안양시 만안구 안양로 111 11층 1109호</Text>
                </Body>
                <Text style={{ marginTop: "10px" }}>
                    © Copyright 2022. All Rights Reserved.
                </Text>
            </Inner>
        </Container>
    );
}
