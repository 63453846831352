import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import { useNavigationWidth } from "../../hooks/useNavigationWidth";
import { ButtonSpan, lightTheme } from "../../styles";
import { seeSolapiMessages_seeSolapiMessages_messages } from "../../__generated__/seeSolapiMessages";
import { IMessageObj, MessageTableCell } from "./MessageTableCell";
import { getKRDate } from "../bookings/BookingsCalendarMonthBooking";
import { TextMessagePopup } from "../popup/TextMessagePopup";

const Container = styled.div<{ navigationWidth: string }>`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    background-color: ${(props) => props.theme.white};
    border-radius: 8px;
`;

const Hint = styled.span`
    font-size: 12px;
    width: 100%;
    color: ${(props) => props.theme.fontDarkGray};
    text-align: right;
    margin-bottom: 5px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

const Bottom = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
`;

const BottomPageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 110px;
`;

const Button = styled(ButtonSpan)`
    color: ${(props) => props.theme.blue};
`;

const EmptyText = styled.span`
    position: relative;
    top: 30px;
    left: 50%;
    transform: translateX(-30%);
    width: 300px;
    color: ${(props) => props.theme.fontGray};
`;

const TableCellButton = styled(ButtonSpan)``;
const PageText = styled.span``;

interface IMessageTable {
    messages?: seeSolapiMessages_seeSolapiMessages_messages[];
    msgIndex: number;
    loading: boolean;
    moveNextPage: (next: boolean) => void;
}

export function MessageTable({
    messages,
    msgIndex,
    loading,
    moveNextPage,
}: IMessageTable) {
    const navigationWidth = useNavigationWidth();

    const [selectedMessageIndex, setSelectedMessageIndex] = useState(-1);
    const [messageObjs, setMessageObjs] = useState<IMessageObj[]>([]);
    const [emptyMessage, setEmptyMessage] = useState(false);

    const makeCreateDate = (date: Date) => {
        const krDate = getKRDate(date);

        const result = `${String(krDate.getMonth() + 1).padStart(
            2,
            "0",
        )}/${String(krDate.getDate()).padStart(2, "0")} ${String(
            krDate.getHours(),
        ).padStart(2, "0")}:${String(krDate.getMinutes()).padStart(
            2,
            "0",
        )}:${String(krDate.getSeconds()).padStart(2, "0")}`;

        return result;
    };

    useEffect(() => {
        const MIN_COUNT = 10;
        let count = MIN_COUNT;

        if (messages) {
            if (messages.length > MIN_COUNT) {
                count = messages.length;
            }
        }

        if (messages === undefined || messages.length === 0) {
            setEmptyMessage(true);
        } else {
            setEmptyMessage(false);
        }

        const temp: IMessageObj[] = [];

        for (let i = 0; i < count; i++) {
            let msg: seeSolapiMessages_seeSolapiMessages_messages | null = null;
            if (messages) {
                if (messages.length > i) {
                    msg = messages[i];
                }
            }

            const msgObj: IMessageObj = {
                empty: true,
                create: "",
                to: "",
                status: "",
                messageId: "",
            };

            if (msg) {
                msgObj.empty = false;
                msgObj.create = makeCreateDate(
                    new Date(String(msg.dateCreated)),
                );
                if (msg.to) {
                    msgObj.to = msg.to;
                }

                if (msg.reason) {
                    msgObj.status = msg.reason;
                }

                if (msg.messageId) {
                    msgObj.messageId = msg.messageId;
                }
            }

            temp.push(msgObj);
        }

        setMessageObjs(temp);
    }, [messages]);

    return (
        <Container navigationWidth={navigationWidth}>
            <Hint>
                * 상태가 이통사로 접수 또는 전송시간 초과등의 사유는 수신측에서
                확인을
            </Hint>
            <Hint>
                안했거나 수신측 단말기에 스팸으로 등록 또는 정지된 단말기일 수
                있습니다.
            </Hint>
            <MessageTableCell
                header={true}
                messageObj={{
                    empty: false,
                    create: "생성일",
                    to: "수신번호",
                    status: "상태",
                    messageId: "",
                }}
            />
            {emptyMessage ? (
                <EmptyText>문자 내역을 불러오고있습니다.</EmptyText>
            ) : null}
            {messageObjs.map((obj, index) => {
                return (
                    <TableCellButton
                        style={
                            selectedMessageIndex === index
                                ? { backgroundColor: lightTheme.bookingUsed }
                                : undefined
                        }
                        key={index}
                        onClick={() => {
                            setSelectedMessageIndex(index);
                        }}
                    >
                        <MessageTableCell header={false} messageObj={obj} />
                    </TableCellButton>
                );
            })}
            <Bottom>
                <BottomPageContainer>
                    <Button
                        onClick={() => moveNextPage(false)}
                        disabled={loading}
                    >
                        <FontAwesomeIcon icon={faCaretLeft} size="2x" />
                    </Button>
                    <PageText>{`${msgIndex + 1}`}</PageText>
                    {loading ? (
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={loading}
                        />
                    ) : (
                        <Button
                            onClick={() => moveNextPage(true)}
                            disabled={loading}
                        >
                            <FontAwesomeIcon icon={faCaretRight} size="2x" />
                        </Button>
                    )}
                </BottomPageContainer>
            </Bottom>

            {selectedMessageIndex >= 0 &&
            messageObjs.length > selectedMessageIndex ? (
                <TextMessagePopup
                    messageId={messageObjs[selectedMessageIndex].messageId}
                    didClosePopup={() => {
                        setSelectedMessageIndex(-1);
                    }}
                />
            ) : null}
        </Container>
    );
}
