import { useQuery } from "@apollo/client";
import { useState } from "react";
import styled from "styled-components";
import { SEE_SOLAPI_MESSAGES } from "../../graphqls/queries";
import { useNavigationWidth } from "../../hooks/useNavigationWidth";
import {
    seeSolapiMessages,
    seeSolapiMessagesVariables,
    seeSolapiMessages_seeSolapiMessages_messages,
} from "../../__generated__/seeSolapiMessages";
import { MessageTable } from "./MessageTable";

export const MAX_A_PAGE_MSG_COUNT = 15;

const Container = styled.div<{ navigationWidth: string }>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.background};
`;

interface IMessagePage {
    messages: seeSolapiMessages_seeSolapiMessages_messages[];
    index: number;
    nextKey: string | null;
}

export function Messages() {
    const navigationWidth = useNavigationWidth();
    const [messagePage, setMessagePage] = useState<IMessagePage[]>([]);
    const [currentMessagePage, setCurrentMessagePage] = useState<
        IMessagePage | undefined
    >(undefined);
    const [nextKey, setNextKey] = useState<string | null>(null);

    const { loading } = useQuery<seeSolapiMessages, seeSolapiMessagesVariables>(
        SEE_SOLAPI_MESSAGES,
        {
            variables: {
                limit: MAX_A_PAGE_MSG_COUNT,
                nextKey,
            },
            onCompleted: ({
                seeSolapiMessages: {
                    ok,
                    error,
                    messages: seeMessages,
                    nextKey,
                },
            }: seeSolapiMessages) => {
                if (ok) {
                    const temp: seeSolapiMessages_seeSolapiMessages_messages[] =
                        [];

                    seeMessages?.map((message) => {
                        if (message) {
                            temp.push(message);
                        }
                    });
                    let index = 0;
                    if (messagePage.length > 0) {
                        index = messagePage[messagePage.length - 1].index + 1;
                    }

                    const msgPage = { messages: temp, index, nextKey };

                    setMessagePage((prev) => [...prev, msgPage]);

                    setCurrentMessagePage(msgPage);
                } else {
                    alert(error);
                }
            },
        },
    );

    const onMoveNextPage = (next: boolean) => {
        if (loading === false) {
            let index = 0;
            if (currentMessagePage) {
                index = currentMessagePage.index;
            }

            if (next) {
                index = index + 1;
                if (index >= messagePage.length) {
                    setNextKey(messagePage[index - 1].nextKey);
                } else {
                    setCurrentMessagePage(messagePage[index]);
                }
            } else {
                index = index - 1;
                if (index < 0) {
                    index = 0;
                }

                setCurrentMessagePage(messagePage[index]);
            }
        }
    };

    return (
        <Container navigationWidth={navigationWidth}>
            <MessageTable
                messages={currentMessagePage?.messages}
                msgIndex={currentMessagePage ? currentMessagePage.index : 0}
                loading={loading}
                moveNextPage={onMoveNextPage}
            ></MessageTable>
        </Container>
    );
}
