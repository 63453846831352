import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonSpan } from "../styles";

const SBanner = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.red};
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const BannerContent = styled.div`
    display: flex;
    max-width: 900px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px;
`;

const BannerText = styled.span`
    font-size: 15px;
    font-weight: 700;
    color: ${(props) => props.theme.white};

    @media ${({ theme }) => theme.mobile} {
        font-size: 13px;
    }
`;

const BannerButton = styled(ButtonSpan)`
    font-size: 13px;
    font-weight: 700;
    padding: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    user-select: none;
    a {
        color: ${(props) => props.theme.white};
        text-decoration: none;
    }

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

interface BannerProps {
    text: string;
    linkTo?: string;
    buttonText?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function Banner({ text, linkTo, buttonText, onClick }: BannerProps) {
    return (
        <SBanner>
            <BannerContent>
                <BannerText>{text}</BannerText>
                {linkTo && buttonText ? (
                    <BannerButton>
                        <Link to={linkTo}>{buttonText}</Link>
                    </BannerButton>
                ) : null}
                {onClick && buttonText ? (
                    <BannerButton onClick={onClick}>{buttonText}</BannerButton>
                ) : null}
            </BannerContent>
        </SBanner>
    );
}
