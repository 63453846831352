import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import { evictCacheRootQuery, getDayText, toWon } from "../../global";
import {
    CREATE_ONBI_SCHEDULES_MUTATION,
    DELETE_ONBI_SCHEDULE_MUTATION,
    UPDATE_SCHEDULE_INFO_MUTATION,
} from "../../graphqls/mutations";
import { phoneHyphen } from "../../utils";
import {
    deleteOnbiSchedule,
    deleteOnbiScheduleVariables,
} from "../../__generated__/deleteOnbiSchedule";
import { ScheduleRepeatType, SiteType } from "../../__generated__/globalTypes";
import { seeBookingCalendar_seeBookingCalendar_bookings } from "../../__generated__/seeBookingCalendar";
import { Popup } from "./Popup";
import { PopupButton } from "./PopupCommon";
import { getKRDate } from "../bookings/BookingsCalendarMonthBooking";
import useMember from "../../hooks/useMember";
import { ButtonSpan } from "../../styles";
import {
    updateScheduleInfo,
    updateScheduleInfoVariables,
} from "../../__generated__/updateScheduleInfo";
import {
    createOnbiSchedules,
    createOnbiSchedulesVariables,
} from "../../__generated__/createOnbiSchedules";
import { AddSchedulePopup } from "./AddSchedulePopup";
import { TextPopup } from "./TextPopup";
import {
    seeBookingDetail,
    seeBookingDetailVariables,
    seeBookingDetail_seeBookingDetail_booking,
} from "../../__generated__/seeBookingDetail";
import { SEE_BOOKING_DETAIL } from "../../graphqls/queries";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const Header = styled.span`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.blue};
`;

const HeaderTitle = styled.span`
    font-size: 18px;
    font-weight: 800;
`;

const HeaderSubTitle = styled.span`
    font-size: 15px;
    font-weight: 700;
    margin-top: 5px;
    color: ${(props) => props.theme.fontDarkGray};
`;

const Cell = styled.div`
    display: grid;
    width: 100%;
    height: 50px;
    grid-template-columns: 80px 0.9fr 40px;
    border-bottom: 1px solid ${(props) => props.theme.borderLight};
    align-items: center;
`;

const CellTitle = styled.span`
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.fontDarkGray};
`;

const CellTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const CellText = styled.span`
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    font-size: 13px;
    height: 70%;
    width: 100%;
`;

const CellTextButton = styled(ButtonSpan)`
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    font-size: 13px;
    height: 70%;
    width: 100%;
`;

const CellButton = styled(ButtonSpan)`
    font-size: 12px;
    font-weight: 700;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.blue};
    color: ${(props) => props.theme.blue};
`;

const CellButtonContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const CellError = styled.span`
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 130%;
    height: 100%;
    width: 100%;
    color: ${(props) => props.theme.red};
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface IBookingDetailPopup {
    booking: seeBookingCalendar_seeBookingCalendar_bookings;
    franchiseIndex: number;
    productIndex: number;
    franchiseName: string;
    productName: string;
    useHalfTimeFranchise: boolean;
    didClosePopup: () => void;
}

export function BookingDetailPopup({
    booking,
    franchiseIndex,
    productIndex,
    franchiseName,
    productName,
    useHalfTimeFranchise,
    didClosePopup,
}: IBookingDetailPopup) {
    const me = useMember();
    const [deleteOnbiScheduleLoading, setDeleteOnbiScheduleLoading] =
        useState(false);
    const [createScheduleLoading, setCreateScheduleLoading] = useState(false);
    const [showAddSchedulePopup, setShowAddSchedulePopup] = useState(false);
    const [showMemoPopup, setShowMemoPopup] = useState(false);
    const [detailBooking, setDetailBooking] =
        useState<seeBookingDetail_seeBookingDetail_booking>();

    const [defaultWillSendMessage, setDefaultWillSendMessage] = useState(
        "해당 메시지는 예약 당일 11:00에 전송됩니다.",
    );

    useQuery<seeBookingDetail, seeBookingDetailVariables>(SEE_BOOKING_DETAIL, {
        onCompleted: (data: seeBookingDetail) => {
            const {
                seeBookingDetail: { ok, booking },
            } = data;

            if (ok && booking) {
                setDetailBooking(booking);
            }
        },
        variables: {
            key: booking.key,
        },
    });

    useEffect(() => {
        if (me) {
            let sendMessageStatusName =
                "해당 메시지는 예약 당일 11:00에 전송됩니다.";
            if (me) {
                sendMessageStatusName = `해당 메시지는 예약 당일 ${String(
                    me.bookingMsgScheduleHours,
                ).padStart(2, "0")}:${String(
                    me.bookingMsgScheduleMinutes,
                ).padStart(2, "0")}에 전송됩니다.`;
            }

            setDefaultWillSendMessage(sendMessageStatusName);
        }
    }, [me]);

    const getDateTime = (utc: string) => {
        const date = new Date(Number(utc));
        const krDate = getKRDate(date);

        const dayText = getDayText(krDate.getDay());

        const result = `${krDate.getFullYear()}-${String(
            krDate.getMonth() + 1,
        ).padStart(2, "0")}-${String(krDate.getDate()).padStart(
            2,
            "0",
        )}(${dayText}) ${String(krDate.getHours()).padStart(2, "0")}:${String(
            krDate.getMinutes(),
        ).padStart(2, "0")}`;

        return result;
    };

    const getElapsedTime = (startUTC: string, endUTC: string) => {
        const elapsedMSec =
            new Date(Number(endUTC)).getTime() -
            new Date(Number(startUTC)).getTime();

        const elapsedHour = elapsedMSec / 1000 / 60 / 60;

        const elapsedMin = (elapsedMSec / 1000 / 60) % 60;

        let result = `${Math.ceil(elapsedHour)}시간`;

        if (elapsedMin === 30) {
            result = `${Math.floor(elapsedHour)}시간 ${elapsedMin}분`;
        }

        return result;
    };

    const onCompletedDeleteOnbiSchedule = (data: deleteOnbiSchedule) => {
        setDeleteOnbiScheduleLoading(false);

        const {
            deleteOnbiSchedule: { ok, error },
        } = data;

        if (ok) {
            evictCacheRootQuery();
            didClosePopup();
        } else {
            alert(error);
        }
    };

    const [deleteOnbiScheduleMutation] = useMutation<
        deleteOnbiSchedule,
        deleteOnbiScheduleVariables
    >(DELETE_ONBI_SCHEDULE_MUTATION, {
        onError: (error) => {
            alert(error);
            setDeleteOnbiScheduleLoading(false);
        },
        onCompleted: onCompletedDeleteOnbiSchedule,
    });

    const [updateScheduleInfoMutation] = useMutation<
        updateScheduleInfo,
        updateScheduleInfoVariables
    >(UPDATE_SCHEDULE_INFO_MUTATION, {
        onError: (error) => {
            alert(error);
        },
        onCompleted: ({ updateScheduleInfo: { ok, error } }) => {
            if (ok) {
                evictCacheRootQuery();
                alert(
                    "정보가 변경되었습니다.\n오직 온비에만 변경이 적용됩니다.\n(다른 사이트들의 변경은 불가능합니다.)",
                );
            } else {
                alert(error);
            }
        },
    });

    const [createOnbiSchedulesMutation] = useMutation<
        createOnbiSchedules,
        createOnbiSchedulesVariables
    >(CREATE_ONBI_SCHEDULES_MUTATION, {
        onCompleted: (data: createOnbiSchedules) => {
            setCreateScheduleLoading(false);

            const {
                createOnbiSchedules: { ok, error, message },
            } = data;

            if (ok) {
                evictCacheRootQuery();
                didClosePopup();

                if (message && message.length > 0) {
                    alert(message);
                }
            } else {
                alert(error);
            }
        },
    });

    const onClickCancelSchedule = () => {
        if (deleteOnbiScheduleLoading === false) {
            setDeleteOnbiScheduleLoading(true);
            deleteOnbiScheduleMutation({
                variables: {
                    franchiseIndex,
                    productIndex,
                    startDateKST: booking.startDateKST,
                    endDateKST: booking.endDateKST,
                },
            });
        }
    };

    const isPhoneError = (phone: string) => {
        let result = false;

        if (phone.includes("*")) {
            result = true;
        }

        return result;
    };

    const canAddSchedule = () => {
        let can = false;

        if (booking.endDateUTC) {
            const endDate = new Date(Number(booking.endDateUTC));

            can = true;

            if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
                can = false;
            }
        }

        return can;
    };

    const getUserPhone = (phone: string, site: SiteType) => {
        let result = phoneHyphen(phone);

        if (phone.includes("*")) {
            let name = "스페이스클라우드";
            if (site === SiteType.NA) {
                name = "네이버";
            }
            result = `이미 지난 예약은 예약자의 개인정보를 노출 할 수 없습니다. 연락처가 필요하신 경우 ${name}에서 직접 확인하셔야합니다.`;
        }

        return result;
    };

    const onClickChangeName = (bookingKey: string, name?: string | null) => {
        if (name === null) {
            name = undefined;
        }
        const text = window.prompt("변경할 이름을 입력하세요", name);
        if (text !== null) {
            updateScheduleInfoMutation({
                variables: {
                    key: bookingKey,
                    username: text,
                },
            });
        } else {
            alert("변경 작업이 취소되었습니다");
        }
    };

    const onClickChangePhone = (bookingKey: string, phone?: string | null) => {
        if (phone === null) {
            phone = undefined;
        }
        const text = window.prompt(
            "변경할 전화번호를 입력하세요(- 없이)",
            phone,
        );

        if (text !== null) {
            updateScheduleInfoMutation({
                variables: {
                    key: bookingKey,
                    userPhone: text,
                },
            });
        } else {
            alert("변경 작업이 취소되었습니다.");
        }
    };

    const onClickChangeMemo = (bookingKey: string, memo?: string | null) => {
        if (memo === null) {
            memo = undefined;
        }
        const text = window.prompt("변경할 메모를 입력하세요.", memo);
        if (text !== null) {
            updateScheduleInfoMutation({
                variables: {
                    key: bookingKey,
                    memo: text,
                },
            });
        } else {
            alert("변경 작업이 취소되었습니다");
        }
    };

    const onClickAddTime = (
        addMin: number,
        endDate: Date,
        username: string,
        userPhone: string,
    ) => {
        if (createScheduleLoading === false) {
            const _startDate = new Date(endDate);
            const _endDate = new Date(endDate);
            _endDate.setMinutes(_endDate.getMinutes() + addMin);

            if (_endDate.getDate() !== _startDate.getDate()) {
                if (_endDate.getHours() !== 0 || _endDate.getMinutes() !== 0) {
                    alert(
                        `종료일이 시작일과 다를 수 없습니다.\n시작일: ${_startDate.getDate()}일, 종료일: ${_endDate.getDate()}일\n날짜를 다르게 설정하기를 원하시는 경우 스케줄을 2번 추가해주시기 바랍니다.`,
                    );
                    return;
                }
            }

            const ok = window.confirm(
                `예약자 [${username}]님의 추가 시간:\n${String(
                    _startDate.getHours(),
                ).padStart(2, "0")}:${String(_startDate.getMinutes()).padStart(
                    2,
                    "0",
                )} ~ ${String(_endDate.getHours()).padStart(2, "0")}:${String(
                    _endDate.getMinutes(),
                ).padStart(2, "0")} 을 등록하시겠습니까?`,
            );

            if (ok) {
                const startHour = _startDate.getHours();
                let endHour = _endDate.getHours();
                let startHalf = false;
                let endHalf = false;

                if (_startDate.getMinutes() !== 0) {
                    startHalf = true;
                }

                if (_endDate.getMinutes() !== 0) {
                    endHalf = true;
                }

                if (_startDate.getDate() !== _endDate.getDate()) {
                    endHour = 24;
                }

                setCreateScheduleLoading(true);

                createOnbiSchedulesMutation({
                    variables: {
                        franchiseIndex: franchiseIndex,
                        productIndex: productIndex,
                        allRooms: false,
                        repeatStartDateTime: String(endDate.getTime()),
                        startHour,
                        startHalf,
                        endHour,
                        endHalf,
                        username,
                        userPhone,
                        repeatType: ScheduleRepeatType.NONE,
                        repeatEndDateTime: String(endDate.getTime()),
                    },
                });
            } else {
                alert("스케줄 등록이 취소되었습니다.");
            }
        }
    };

    const getTextSlice = (text: string, maxLength: number) => {
        let result = "";

        if (text.length > maxLength) {
            result = `${text.slice(0, maxLength)}..`;
        } else {
            result = text;
        }
        return result;
    };

    const getSendMessageStatus = () => {
        let result = defaultWillSendMessage;

        if (detailBooking) {
            if (detailBooking.textMessages.length > 0) {
                result = detailBooking.textMessages[0].statusMessage;
            }
        } else {
            if (booking.textMessages.length > 0) {
                result = booking.textMessages[0].statusMessage;
            }
        }

        return result;
    };

    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <Header>
                    <HeaderTitle>{`${franchiseName}(${productName})`}</HeaderTitle>
                    <HeaderSubTitle>{`예약번호: ${booking.checkIndex}`}</HeaderSubTitle>
                </Header>
                <Cell>
                    <CellTitle>- 일자</CellTitle>
                    <CellTextContainer>
                        <CellText>{`${getDateTime(
                            booking.startDateUTC,
                        )} ~`}</CellText>
                        <CellText>{`${getDateTime(
                            booking.endDateUTC,
                        )} (${getElapsedTime(
                            booking.startDateUTC,
                            booking.endDateUTC,
                        )})`}</CellText>
                    </CellTextContainer>
                </Cell>

                {booking.schedule === false ? (
                    <Cell>
                        <CellTitle>- 사이트</CellTitle>
                        <CellText>
                            {booking.site === SiteType.NA
                                ? "네이버"
                                : "스페이스 클라우드"}
                        </CellText>
                    </Cell>
                ) : null}
                {booking.totalPrice !== null && booking.totalPrice > 0 ? (
                    <Cell>
                        <CellTitle>- 금액</CellTitle>
                        <CellText>{toWon(booking.totalPrice)}</CellText>
                    </Cell>
                ) : null}
                <Cell>
                    <CellTitle>- 예약자명</CellTitle>
                    <CellText>{booking.username}</CellText>
                    {me?.plusItem?.useUpdateScheduleInfo === true &&
                    booking.schedule === true ? (
                        <CellButton
                            onClick={() => {
                                onClickChangeName(
                                    booking.key,
                                    booking.username,
                                );
                            }}
                        >
                            {"변경"}
                        </CellButton>
                    ) : null}
                </Cell>
                {booking.schedule ? (
                    <Cell>
                        <CellTitle>- 전화번호</CellTitle>
                        <CellText
                            onClick={() => {
                                window.location.href = `tel:${booking.userPhone}`;
                            }}
                        >
                            {booking.userPhone
                                ? getUserPhone(booking.userPhone, booking.site)
                                : ""}
                        </CellText>
                        {me?.plusItem?.useUpdateScheduleInfo === true &&
                        booking.schedule === true ? (
                            <CellButton
                                onClick={() => {
                                    onClickChangePhone(
                                        booking.key,
                                        booking.userPhone,
                                    );
                                }}
                            >
                                {"변경"}
                            </CellButton>
                        ) : null}
                    </Cell>
                ) : (
                    <>
                        {booking.userPhone &&
                        isPhoneError(booking.userPhone) === false ? (
                            <Cell>
                                <CellTitle>- 전화번호</CellTitle>
                                <CellText
                                    onClick={() => {
                                        window.location.href = `tel:${booking.userPhone}`;
                                    }}
                                >
                                    {booking.userPhone
                                        ? getUserPhone(
                                              booking.userPhone,
                                              booking.site,
                                          )
                                        : ""}
                                </CellText>
                            </Cell>
                        ) : null}
                        {booking.userPhone &&
                        isPhoneError(booking.userPhone) === true ? (
                            <Cell style={{ height: "100px" }}>
                                <CellTitle>- 전화번호</CellTitle>
                                <CellError>
                                    {getUserPhone(
                                        booking.userPhone,
                                        booking.site,
                                    )}
                                </CellError>
                            </Cell>
                        ) : null}
                    </>
                )}

                <Cell>
                    <CellTitle>- 메모</CellTitle>
                    {booking.memo && booking.memo.length > 0 ? (
                        <CellTextButton
                            onClick={() => {
                                setShowMemoPopup(true);
                            }}
                        >
                            {getTextSlice(booking.memo ? booking.memo : "", 13)}
                        </CellTextButton>
                    ) : (
                        <CellText />
                    )}

                    <CellButton
                        onClick={() => {
                            onClickChangeMemo(booking.key, booking.memo);
                        }}
                    >
                        {"변경"}
                    </CellButton>
                </Cell>

                {canAddSchedule() === true ? (
                    <Cell>
                        <CellTitle>- 스케줄 추가</CellTitle>
                        {createScheduleLoading ? (
                            <LoadingContainer>
                                <RotatingLines
                                    strokeColor="black"
                                    strokeWidth="3"
                                    animationDuration="0.75"
                                    width="20"
                                    visible={createScheduleLoading}
                                />
                            </LoadingContainer>
                        ) : (
                            <CellButtonContainer>
                                {useHalfTimeFranchise === true ? (
                                    <CellButton
                                        style={{
                                            padding: "5px",
                                            marginLeft: "5px",
                                            marginRight: "8px",
                                        }}
                                        onClick={() => {
                                            onClickAddTime(
                                                30,
                                                new Date(
                                                    Number(booking.endDateUTC),
                                                ),
                                                booking.username
                                                    ? booking.username
                                                    : "",
                                                booking.userPhone
                                                    ? booking.userPhone
                                                    : "",
                                            );
                                        }}
                                    >
                                        {"+30분"}
                                    </CellButton>
                                ) : null}

                                <CellButton
                                    style={
                                        useHalfTimeFranchise === true
                                            ? {
                                                  padding: "5px",
                                                  marginRight: "8px",
                                              }
                                            : {
                                                  padding: "5px",
                                                  marginRight: "8px",
                                                  marginLeft: "10px",
                                              }
                                    }
                                    onClick={() => {
                                        onClickAddTime(
                                            60,
                                            new Date(
                                                Number(booking.endDateUTC),
                                            ),
                                            booking.username
                                                ? booking.username
                                                : "",
                                            booking.userPhone
                                                ? booking.userPhone
                                                : "",
                                        );
                                    }}
                                >
                                    {"+1시간"}
                                </CellButton>
                                <CellButton
                                    style={{
                                        padding: "5px",
                                        marginRight: "8px",
                                    }}
                                    onClick={() => {
                                        onClickAddTime(
                                            120,
                                            new Date(
                                                Number(booking.endDateUTC),
                                            ),
                                            booking.username
                                                ? booking.username
                                                : "",
                                            booking.userPhone
                                                ? booking.userPhone
                                                : "",
                                        );
                                    }}
                                >
                                    {"+2시간"}
                                </CellButton>
                                <CellButton
                                    style={{ padding: "5px" }}
                                    onClick={() => {
                                        setShowAddSchedulePopup(true);
                                    }}
                                >
                                    {"기타"}
                                </CellButton>
                            </CellButtonContainer>
                        )}
                    </Cell>
                ) : null}

                {booking.schedule ? (
                    <PopupButton
                        disabled={deleteOnbiScheduleLoading}
                        onClick={onClickCancelSchedule}
                    >
                        스케줄 취소
                    </PopupButton>
                ) : (
                    <>
                        {me?.plusItem?.useBookingMessage === true ? (
                            <Cell>
                                <CellTitle>- 메시지</CellTitle>
                                <CellText
                                    style={{
                                        whiteSpace: "normal",
                                        lineHeight: "120%",
                                    }}
                                >
                                    {getSendMessageStatus()}
                                </CellText>
                            </Cell>
                        ) : null}
                    </>
                )}
                {deleteOnbiScheduleLoading ? (
                    <LoadingContainer>
                        <RotatingLines
                            strokeColor="black"
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="20"
                            visible={deleteOnbiScheduleLoading}
                        />
                    </LoadingContainer>
                ) : null}
            </Container>
            {showAddSchedulePopup ? (
                <AddSchedulePopup
                    franchiseIndex={franchiseIndex}
                    startProductIndex={productIndex}
                    franchiseName={franchiseName}
                    productName={productName}
                    date={new Date(Number(booking.endDateUTC))}
                    didClosePopup={() => setShowAddSchedulePopup(false)}
                    didSuccessAddSchedule={() => {
                        setShowAddSchedulePopup(false);
                        didClosePopup();
                    }}
                    useHalfTimeFranchise={useHalfTimeFranchise}
                    username={booking.username}
                    userPhone={booking.userPhone}
                    startDate={new Date(Number(booking.endDateUTC))}
                />
            ) : null}
            {showMemoPopup && booking.memo && booking.memo.length > 0 ? (
                <TextPopup
                    text={booking.memo}
                    style={{ height: "150px" }}
                    didClosePopup={() => {
                        setShowMemoPopup(false);
                    }}
                />
            ) : null}
        </Popup>
    );
}
