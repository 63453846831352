import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { signUserOut } from "../../apollo";
import { evictCacheRootQuery } from "../../global";
import {
    CREATE_HOST_MUTATION,
    WITHDRAWAL_MEMBER_MUTATION,
} from "../../graphqls/mutations";
import routes from "../../routes";
import { ButtonSpan, lightTheme } from "../../styles";
import { openUrlNewTab } from "../../utils";
import {
    createHost,
    createHostVariables,
} from "../../__generated__/createHost";
import { LoginType, SiteType } from "../../__generated__/globalTypes";

import { withdrawalMember } from "../../__generated__/withdrawalMember";
import { CheckBox } from "../CheckBox";
import {
    HostFormButton,
    HostFormCheckContainer,
    HostFormCheckText,
    HostFormCheckTitle,
} from "./hostForm/HostFormCommon";
import { NAHostForm } from "./hostForm/NAHostForm";
import { SCHostForm } from "./hostForm/SCHostForm";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
`;

export interface IHostForm {
    sc_host_id: string;
    sc_host_password: string;
    sc_host_password_check: string;
    na_onbi_id: string;
}

export function SignUpHostForm() {
    const [isAgree, setIsAgree] = useState(false);
    const [isNAChecked, setIsNAChecked] = useState(false);
    const [disableAgreeButton, setDisableAgreeButton] = useState(true);
    const [agreeButtonShowTime, setAgreeButtonShowTime] = useState(5);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [isValidSubmitButton, setIsValidSubmitButton] = useState(false);

    const onCompletedCreateHost = (data: createHost) => {
        const {
            createHost: { ok, error },
        } = data;

        if (ok) {
            evictCacheRootQuery();
        } else {
            alert(error);
        }
    };

    const onCompletedWithdrawalMember = (data: withdrawalMember) => {
        const {
            withdrawalMember: { ok, error },
        } = data;

        if (ok) {
            alert("탈퇴처리 되었습니다.");
            signUserOut();
        } else {
            alert(error);
        }
    };

    const [createHostMutation] = useMutation<createHost, createHostVariables>(
        CREATE_HOST_MUTATION,
        {
            onCompleted: onCompletedCreateHost,
        },
    );

    const [withdrawalMemberMutation] = useMutation<withdrawalMember>(
        WITHDRAWAL_MEMBER_MUTATION,
        {
            onCompleted: onCompletedWithdrawalMember,
        },
    );

    const onClickCreate = () => {
        const ok = window.confirm(
            "스페이스 계정 입력이 공란이거나\n네이버 권한 위임이 안된 경우\n관리자가 연락 드릴 수도 있습니다.\n(사전에 협의되었으면 제외)",
        );

        if (ok) {
            const loginId = getValues("sc_host_id");
            let isSCInactive = true;

            if (loginId && loginId.length >= 0) {
                isSCInactive = false;
            }

            createHostMutation({
                variables: {
                    loginId,
                    loginPassword: getValues("sc_host_password"),
                    site: SiteType.SC,
                    isInactive: isSCInactive,
                    provider: LoginType.EMAIL,
                },
            });

            const onbiId = getValues("na_onbi_id");
            let isNAInactive = true;

            if (onbiId && onbiId.length >= 0) {
                isNAInactive = false;
            }

            createHostMutation({
                variables: {
                    site: SiteType.NA,
                    representativeLoginId: onbiId,
                    isInactive: isNAInactive,
                },
            });
        }
    };

    const onClickCancel = () => {
        const ok = window.confirm(
            "계정 공유를 원하지 않으시면 가입이 취소되며 모든 정보가 사라집니다.",
        );

        if (ok) {
            const ok = window.confirm(
                "모든 정보가 사라지면 복구가 불가할 수 있습니다.",
            );

            if (ok) {
                withdrawalMemberMutation();
            }
        }
    };

    const {
        register,
        formState: { errors, isValid },
        getValues,
    } = useForm<IHostForm>({
        mode: "onChange",
    });

    useEffect(() => {
        let isSubmit = false;
        if (isPrivacyChecked === false) {
            isSubmit = false;
        } else {
            if (isNAChecked === false) {
                isSubmit = true;
            } else {
                isSubmit = isValid;
            }
        }

        setIsValidSubmitButton(isSubmit);
    }, [isNAChecked, isPrivacyChecked, isValid]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined = undefined;
        if (agreeButtonShowTime === 0) {
            setDisableAgreeButton(false);
        } else if (agreeButtonShowTime > 0) {
            intervalId = setInterval(() => {
                setAgreeButtonShowTime((prev) => prev - 1);
                if (intervalId) {
                    clearInterval(intervalId);
                }
            }, 1000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [agreeButtonShowTime]);

    return (
        <Container>
            {isAgree === false ? (
                <HostFormCheckContainer>
                    <HostFormCheckTitle>
                        안전한 ONBI 사용을 위해 아래 내용을 꼭 읽어주시기
                        바랍니다.
                    </HostFormCheckTitle>
                    <HostFormCheckText>
                        - ONBI는 회원님의 계정 정보를 통해 예약 연동을
                        진행합니다.
                    </HostFormCheckText>
                    <HostFormCheckText>
                        - 회원님의 계정 정보는 ONBI 서비스 외의 용도로는 절대
                        사용하지 않습니다.
                    </HostFormCheckText>
                    <HostFormCheckText>
                        - 계정의 공유를 원하지 않으실 경우 더 이상 가입을
                        진행하지 않으셔도 됩니다.
                    </HostFormCheckText>

                    <ButtonContainer>
                        <HostFormButton
                            style={{
                                backgroundColor: lightTheme.blue,
                                color: lightTheme.white,
                                marginTop: "10px",
                                width: "150px",
                            }}
                            disabled={disableAgreeButton}
                            onClick={() => {
                                if (disableAgreeButton === false) {
                                    setIsAgree((prev) => !prev);
                                }
                            }}
                        >
                            위 내용을 모두 확인하였습니다
                        </HostFormButton>
                        <HostFormButton
                            style={{
                                backgroundColor: lightTheme.red,
                                color: lightTheme.white,
                                marginTop: "10px",
                                width: "150px",
                            }}
                            disabled={disableAgreeButton}
                            onClick={() => {
                                if (disableAgreeButton === false) {
                                    onClickCancel();
                                }
                            }}
                        >
                            계정 공유를 원하지 않습니다
                        </HostFormButton>
                    </ButtonContainer>
                    {disableAgreeButton === true ? (
                        <HostFormCheckText>{`${agreeButtonShowTime}초 후 버튼이 활성화됩니다.`}</HostFormCheckText>
                    ) : null}
                </HostFormCheckContainer>
            ) : null}

            {isAgree ? (
                <>
                    <SCHostForm
                        register={register}
                        getValues={getValues}
                        formErrors={errors}
                    ></SCHostForm>
                    <NAHostForm
                        register={register}
                        getValues={getValues}
                        formErrors={errors}
                        isNAChecked={isNAChecked}
                        onClickCheck={() => {
                            setIsNAChecked((prev) => {
                                const result = !prev;
                                return result;
                            });
                        }}
                    ></NAHostForm>
                    <PrivacyText
                        isChecked={isPrivacyChecked}
                        onClick={() => {
                            setIsPrivacyChecked((prev) => {
                                const result = !prev;
                                return result;
                            });
                        }}
                    />
                    <ButtonContainer>
                        <HostFormButton
                            style={{
                                backgroundColor: lightTheme.blue,
                                color: lightTheme.white,
                                marginTop: "10px",
                                width: "300px",
                            }}
                            disabled={!isValidSubmitButton}
                            onClick={onClickCreate}
                        >
                            ONBI와 함께 지점 관리하기
                        </HostFormButton>
                    </ButtonContainer>
                </>
            ) : null}
        </Container>
    );
}

interface IPrivacyTextProps {
    isChecked: boolean;
    onClick: () => void;
}

const PrivacyTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    margin-bottom: 10px;
`;
const Text = styled.span`
    font-size: 14px;
    line-height: 150%;
    margin-left: 10px;
`;
const LinkText = styled(ButtonSpan)`
    color: ${(props) => props.theme.lightBlue};

    &:hover {
        border-bottom: 1px solid;
        padding-bottom: 1px;
    }
`;

const PrivacyText = ({ isChecked, onClick }: IPrivacyTextProps) => {
    return (
        <PrivacyTextContainer>
            <CheckBox
                isChecked={isChecked}
                color="chocolate"
                onClick={onClick}
            />
            <Text>
                <LinkText
                    onClick={() => {
                        openUrlNewTab(routes.privacy);
                    }}
                >
                    온비의 개인정보 처리방침
                </LinkText>{" "}
                및{" "}
                <LinkText
                    onClick={() => {
                        openUrlNewTab(routes.terms);
                    }}
                >
                    이용 약관
                </LinkText>
                에 따라 개인 정보를 수집, 사용, 타사에 대한 제공 및 처리하는데
                동의합니다.
            </Text>
        </PrivacyTextContainer>
    );
};
