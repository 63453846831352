import styled from "styled-components";
import { lightTheme } from "../../styles";
import { GridHintContainer, ItemText, ItemTitle } from "./InfoItemGrid";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const HintContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`;

const Hint = styled.span`
    font-size: 15px;
    color: ${(props) => props.theme.fontDarkGray};
    margin-bottom: 8px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 270px repeat(2, 150px) 450px;
    grid-template-rows: repeat(5, 90px);
    margin-top: 30px;
    margin-bottom: 5px;
    user-select: none;
`;

const ItemTextHintContainter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid ${(props) => props.theme.border};
`;

const ItemTextHint = styled.div`
    margin: 0px 8px;
    font-size: 15px;
    line-height: 130%;

    @media ${({ theme }) => theme.tablet} {
        font-size: 13px;
    }
`;

export function InfoItemAdditionsGrid() {
    return (
        <Container>
            <GridHintContainer>
                <GridContainer>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                            borderBottom: "2px solid black",
                            backgroundColor: lightTheme.bookingUsed,
                            color: lightTheme.blue,
                        }}
                    >
                        제품
                    </ItemTitle>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottom: "2px solid black",
                            backgroundColor: lightTheme.bookingUsed,
                            color: lightTheme.blue,
                        }}
                    >
                        수량
                    </ItemTitle>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottom: "2px solid black",
                            backgroundColor: lightTheme.bookingUsed,
                            color: lightTheme.blue,
                        }}
                    >
                        가격
                    </ItemTitle>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottom: "2px solid black",
                            backgroundColor: lightTheme.bookingUsed,
                            color: lightTheme.blue,
                        }}
                    >
                        비고
                    </ItemTitle>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        체험단
                    </ItemTitle>
                    <ItemText>5건</ItemText>
                    <ItemText style={{ color: lightTheme.red }}>
                        120,000원
                    </ItemText>
                    <ItemTextHintContainter>
                        <ItemTextHint>
                            - 1개월 동안 체험단이 직접 운영하는 지점에 방문 후
                            블로그 포스팅 및 예약 플랫폼에 리뷰 작성
                        </ItemTextHint>
                    </ItemTextHintContainter>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        방문자 리뷰
                    </ItemTitle>
                    <ItemText>100건</ItemText>
                    <ItemText style={{ color: lightTheme.red }}>
                        600,000원
                    </ItemText>
                    <ItemTextHintContainter>
                        <ItemTextHint>
                            - 2~3개월 동안 체험단이 직접 운영하는 지점에 방문 후
                            예약 플랫폼에 리뷰 작성
                        </ItemTextHint>
                    </ItemTextHintContainter>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        운영 관리 서비스
                    </ItemTitle>
                    <ItemText>3일</ItemText>
                    <ItemText style={{ color: lightTheme.red }}>
                        200,000원
                    </ItemText>
                    <ItemTextHintContainter>
                        <ItemTextHint>
                            - 예약 연동 및 전화, 청소 서비스가 제공되고 해당
                            서비스는 최소 7일 전 상담 후 구매 가능(청소 1회)
                        </ItemTextHint>
                        <ItemTextHint style={{ marginTop: "12px" }}>
                            - 가격은 방 개수에 따라 변동 될 수 있습니다
                        </ItemTextHint>
                    </ItemTextHintContainter>
                    <ItemTitle
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        운영 관리 서비스+
                    </ItemTitle>
                    <ItemText>7일</ItemText>
                    <ItemText style={{ color: lightTheme.red }}>
                        400,000원
                    </ItemText>
                    <ItemTextHintContainter>
                        <ItemTextHint>
                            - 예약 연동 및 전화, 청소 서비스가 제공되고 해당
                            서비스는 최소 7일 전 상담 후 구매 가능(청소 2회)
                        </ItemTextHint>
                        <ItemTextHint style={{ marginTop: "12px" }}>
                            - 가격은 방 개수에 따라 변동 될 수 있습니다
                        </ItemTextHint>
                    </ItemTextHintContainter>
                </GridContainer>
                <HintContainer>
                    <Hint>{`* VAT 별도`}</Hint>
                    <Hint>{`* 부가서비스는 ONBI와 상담 후 결제 진행하시면 됩니다`}</Hint>
                    <Hint>{`* 부가서비스 상품의 진행은 결제가 완료된 후 진행됩니다`}</Hint>
                </HintContainer>
            </GridHintContainer>
        </Container>
    );
}
