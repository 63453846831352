import { useQuery, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { isSignedInVar, signUserOut } from "../apollo";
import { ME_QUERY, SEE_SYSTEM_QUERY } from "../graphqls/queries";
import {
    MemberInactiveReason,
    MemberStatus,
} from "../__generated__/globalTypes";
import { me, me_me_member } from "../__generated__/me";
import {
    seeSystem,
    seeSystemVariables,
    seeSystem_seeSystem_system,
} from "../__generated__/seeSystem";

function useMember() {
    const hasToken = useReactiveVar(isSignedInVar);

    const [member, setMember] = useState<me_me_member | null>(null);
    useQuery<me>(ME_QUERY, {
        skip: !hasToken,
        onCompleted: (data: me) => {
            const {
                me: { ok, error, member: resMember },
            } = data;

            if (resMember && resMember.signInSessionExpiryDate) {
                const now = new Date();
                const sessingExpiryDate = new Date(
                    Number(resMember?.signInSessionExpiryDate),
                );

                if (now.getTime() > sessingExpiryDate.getTime()) {
                    alert("다시 로그인해주세요.");
                    signUserOut();
                    return;
                }
            }

            if (resMember?.memberStatus === MemberStatus.INACTIVE) {
                if (resMember.inactiveReason !== MemberInactiveReason.DEFAULT) {
                    signUserOut();
                    return;
                }
            }

            setMember(resMember);

            if (!ok) {
                if (error) {
                    alert(error);
                }
                signUserOut();
            }
        },
    });

    return member;
}

export function useSystem() {
    const [system, setSystem] = useState<seeSystem_seeSystem_system | null>(
        null,
    );
    useQuery<seeSystem, seeSystemVariables>(SEE_SYSTEM_QUERY, {
        variables: {
            name: "onbi",
        },
        onCompleted: (data: seeSystem) => {
            const {
                seeSystem: { ok, error, system },
            } = data;

            setSystem(system);

            if (!ok) {
                if (error) {
                    alert(error);
                }
            }
        },
    });

    return system;
}

export default useMember;
