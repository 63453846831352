import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SEE_FRANCHISES_QUERY } from "../../graphqls/queries";

import {
    seeFranchises,
    seeFranchisesVariables,
    seeFranchises_seeFranchises_franchises,
} from "../../__generated__/seeFranchises";
import { HEADER_HEIGHT } from "../Header";
import { BookingsCalendarDay } from "./BookingsCalendarDay";
import { ButtonSpan, lightTheme } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { KeyValues } from "../../utils";
import useMember from "../../hooks/useMember";
import { MemberStatus, SiteType } from "../../__generated__/globalTypes";
import { BookingColorHint } from "./BookingColorHint";
import { AddSchedulePopup } from "../popup/AddSchedulePopup";
import { BookingsCalendarMonth } from "./BookingCalendarMonth";
import { DateColloct, DateSwitch } from "../commonBookingHeaders/DateSwitch";
import { DateSelector } from "../commonBookingHeaders/DateSelector";
import {
    FRANCHISE_SELECT_ALL,
    ProductSelector,
    PRODUCT_SELECT_ALL,
} from "../commonBookingHeaders/ProductSelector";
import { FranchiseSelector } from "../commonBookingHeaders/FranchiseSelector";
import {
    BookingSelectorContentInner,
    BookingsSelectorContainer,
    BookingsSelectorContent,
} from "../commonBookingHeaders/BookingHeaderStyles";
import { useNavigationWidth } from "../../hooks/useNavigationWidth";
import { useBookingHeaderHeight } from "../../hooks/useBookingHeaderHeight";
import { useMediaQuery } from "react-responsive";
import { consoleRoutes } from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.white};
`;

const Inner = styled.div<{
    navigationWidth: string;
    bookingHeaderHeight: string;
}>`
    display: flex;
    flex-direction: column;
    max-width: calc(100vw - ${(props) => props.navigationWidth});
    max-height: calc(
        100vh - ${HEADER_HEIGHT} + ${(props) => props.bookingHeaderHeight}
    );
`;

const BookingsHeaderBottom = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const FranchiseTitleContainer = styled.div`
    margin-top: 10px;
    display: flex;
    height: 29px;
    justify-content: center;
    align-items: center;
`

const FranchiseTitle = styled.span`
    font-size: 17px;
    font-weight: 800;
`

const AddScheduleButton = styled(ButtonSpan)`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    border: 1px solid ${(props) => props.theme.blue};
    color: ${(props) => props.theme.blue};
    border-radius: 8px;
    margin-left: 10px;
`;

const AddScheduleButtonText = styled.span`
    padding: 0px 5px;
    white-space: nowrap;
`;

const Blur = styled.div<{ navigationWidth: string }>`
    font-size: 14px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw - ${(props) => props.navigationWidth});
    height: calc(100vh - ${HEADER_HEIGHT});
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
`;

export interface IProduct {
    name: string;
    index: number;
}

export const onCompletedSeeFranchises = (
    data: seeFranchises,
    useFranchiseAll: boolean,
    setFranchiseNames: React.Dispatch<React.SetStateAction<KeyValues[]>>,
    setFranchises: React.Dispatch<
        React.SetStateAction<seeFranchises_seeFranchises_franchises[]>
    >,
) => {
    const {
        seeFranchises: { ok, error, franchises },
    } = data;

    if (ok) {
        let siteStr: string | null = null;
        if (franchises && franchises.length > 0) {
            const franchise = franchises[0];
            if (franchise) {
                siteStr = franchise.host.site;
            }
        }

        if (siteStr) {
            let fList: seeFranchises_seeFranchises_franchises[] = [];
            let franchiseNameTemp: KeyValues[] = [];

            if (useFranchiseAll) {
                franchiseNameTemp.push({
                    value: "지점 전체",
                    key: `${FRANCHISE_SELECT_ALL}`,
                });
            }

            franchises?.map((franchise) => {
                if (franchise) {
                    let hasFranchiseName = false;

                    for (let i = 0; i < franchiseNameTemp.length; ++i) {
                        const temp = franchiseNameTemp[i];

                        if (temp.key === `${franchise.index}`) {
                            hasFranchiseName = true;

                            break;
                        }
                    }

                    if (hasFranchiseName === false) {
                        franchiseNameTemp.push({
                            key: `${franchise.index}`,
                            value: franchise.name,
                        });
                    }

                    if (franchise.host.site === siteStr) {
                        fList.push(franchise);
                    }
                }
            });
            ///////test code///////
            // fList =
            //     testFranchises() as seeFranchises_seeFranchises_franchises[];
            // franchiseNameTemp = [];
            // fList.map((f) => {
            //     franchiseNameTemp.push({
            //         key: `${f.index}`,
            //         value: f.name,
            //     });
            // });
            ///////test code///////

            setFranchiseNames(franchiseNameTemp);

            setFranchises(fList);
        }
    } else {
        alert(error);
    }
};

export const updateProduct = (
    franchises: seeFranchises_seeFranchises_franchises[],
    selectedFranchiseIndex: number,
    useProductAll: boolean,
    setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>,
    setProductNames: React.Dispatch<React.SetStateAction<KeyValues[]>>,
) => {
    let temp: IProduct[] = [];

    if (useProductAll) {
        temp.push({
            name: "전체",
            index: PRODUCT_SELECT_ALL,
        });
    }

    if (franchises.length > 0) {
        const franchise = franchises[selectedFranchiseIndex];
        franchise.products?.map((product) => {
            if (product) {
                if (product.hideProduct === false) {
                    temp.push({
                        name: product.name,
                        index: product.index,
                    });
                }
            }
        });

        temp = temp.filter((e, index) => temp.indexOf(e) === index);

        setProducts(temp);

        let productNameTemp: KeyValues[] = [];

        temp.map((p) => {
            productNameTemp.push({ key: `${p.index}`, value: p.name });
        });

        setProductNames(productNameTemp);
    }
};

export function Bookings() {
    const me = useMember();
    const navigationWidth = useNavigationWidth();
    const bookingHeaderHeight = useBookingHeaderHeight();
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    const [franchises, setFranchises] = useState<
        seeFranchises_seeFranchises_franchises[]
    >([]);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [franchiseNames, setFranchiseNames] = useState<KeyValues[]>([]);
    const [productNames, setProductNames] = useState<KeyValues[]>([]);
    const [selectedFranchiseIndex, setSelectedFranchiseIndex] = useState(0);
    const [selectedProductIndex, setSelectedProductIndex] = useState(0);

    const [startDate, setStartDate] = useState(new Date());

    const [showAddSchedulePopup, setShowAddSchedulePopup] = useState(false);
    const [calendarDates, setCalendarDates] = useState<Date[]>([]);
    const routes: KeyValues[] = [
        { key: DateColloct.Day, value: consoleRoutes.bookingsDay },
        { key: DateColloct.Calendar, value: consoleRoutes.bookingsCalendar },
    ];

    const location = useLocation();
    const navigate = useNavigate();

    const [dateCollector, setDateCollector] = useState(
        location.pathname.includes(consoleRoutes.bookingsCalendar)
            ? DateColloct.Calendar
            : DateColloct.Day,
    );

    useEffect(() => {
        if (location.pathname.includes(consoleRoutes.bookingsCalendar)) {
            setDateCollector(DateColloct.Calendar);
        } else {
            setDateCollector(DateColloct.Day);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname.includes(consoleRoutes.bookingsCalendar)) {
            setDateCollector(DateColloct.Calendar);
        } else {
            setDateCollector(DateColloct.Day);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (franchises.length > 0 && products.length > 0) {
            let franchiseIndexStr = params.get("franchise");

            if (isNaN(Number(franchiseIndexStr))) {
                franchiseIndexStr = "0";
            }

            let franchiseIndex = Number(franchiseIndexStr);

            if (franchises.length <= franchiseIndex) {
                franchiseIndex = 0;
            }

            if (selectedFranchiseIndex !== franchiseIndex) {
                onSelectFranchise(franchiseIndex);
            }

            let productIndexStr = params.get("product");

            if (isNaN(Number(productIndexStr))) {
                productIndexStr = "0";
            }

            let productIndex = Number(productIndexStr);

            if (products.length <= productIndex) {
                productIndex = 0;
            }

            if (selectedProductIndex !== productIndex) {
                setSelectedProductIndex(productIndex);
            }
        }

        setStartDate(getDateTimeParamDate());
    }, [location.search, franchises, products, dateCollector]);

    const getDateTimeParamDate = () => {
        const params = new URLSearchParams(location.search);

        let dateTimeStr = params.get("dateTime");
        let date = new Date();

        if (
            isNaN(Number(dateTimeStr)) === false &&
            dateTimeStr !== null &&
            dateTimeStr !== undefined
        ) {
            date = new Date(Number(dateTimeStr));

            if (date.getTime() === 0) {
                date = new Date();
            }
        }

        if (dateCollector === DateColloct.Calendar) {
            date.setDate(1);
        }

        return date;
    };

    useEffect(() => {
        const date = getDateTimeParamDate();
        if (dateCollector === DateColloct.Day) {
            setStartDate(date);
        } else {
            date.setDate(1);
            setStartDate(date);
        }
    }, [dateCollector]);

    useEffect(() => {
        updateProduct(
            franchises,
            selectedFranchiseIndex,
            false,
            setProducts,
            setProductNames,
        );
    }, [franchises, selectedFranchiseIndex]);

    useEffect(() => {
        setCalendarDates([]);

        const sDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            1,
        );

        const lastDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            0,
        );
        const calendarStart = -(sDate.getDay() % 7);
        const calendarLast = lastDate.getDate() + (6 - lastDate.getDay());

        const temp: Date[] = [];

        for (let i = calendarStart; i < calendarLast; ++i) {
            const newDate = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                1,
            );
            newDate.setDate(newDate.getDate() + i);
            temp.push(newDate);
        }

        setCalendarDates(temp);
    }, [startDate]);

    useQuery<seeFranchises, seeFranchisesVariables>(SEE_FRANCHISES_QUERY, {
        onCompleted: (data) => {
            onCompletedSeeFranchises(
                data,
                false,
                setFranchiseNames,
                setFranchises,
            );
        },
    });

    const onSelectFranchise = (index: number) => {
        setProducts([]);
        setSelectedProductIndex(0);
        setSelectedFranchiseIndex(index);
    };

    const productValue = () => {
        let result = "";
        if (productNames.length > 0) {
            result = productNames[selectedProductIndex].value;
        }

        return result;
    };

    const franchiseValue = () => {
        let result = "";
        if (franchiseNames.length > 0) {
            result = franchiseNames[selectedFranchiseIndex].value;
        }

        return result;
    };

    return (
        <Container>
            <Inner
                navigationWidth={navigationWidth}
                bookingHeaderHeight={bookingHeaderHeight}
                style={
                    me && me?.memberStatus !== MemberStatus.ACTIVE
                        ? { filter: "blur(4px)" }
                        : undefined
                }
            >
                <BookingsSelectorContainer
                    bookingHeaderHeight={bookingHeaderHeight}
                >
                    <BookingsSelectorContent>
                        {isMobile ? (
                            <>
                                <BookingSelectorContentInner>
                                    <DateSelector
                                        dateCollector={dateCollector}
                                        date={startDate}
                                        onChangeDate={(date) => {
                                            navigate(
                                                `?franchise=${selectedFranchiseIndex}&product=${selectedProductIndex}&dateTime=${date.getTime()}`,
                                            );
                                        }}
                                    />
                                </BookingSelectorContentInner>
                                <BookingSelectorContentInner
                                    style={{ marginTop: "10px" }}
                                >
                                    <DateSwitch
                                        style={{
                                            height: "100%",
                                            margin: "0px",

                                            borderColor: lightTheme.border,
                                        }}
                                        dateCollector={dateCollector}
                                        routes={routes}
                                    />
                                    <FranchiseSelector
                                        style={{
                                            height: "100%",
                                            margin: "0px",
                                            marginLeft: "10px",
                                            borderColor: lightTheme.border,
                                        }}
                                        franchiseNames={franchiseNames}
                                        selectedIndex={selectedFranchiseIndex}
                                        onSelectedIndex={(index) => {
                                            const date = new Date();
                                            let param = `?franchise=${index}&dateTime=${date.getTime()}`;

                                            if (
                                                dateCollector ===
                                                DateColloct.Calendar
                                            ) {
                                                param = param + "&product=0";
                                            }
                                            navigate(param);
                                        }}
                                    />
                                    {dateCollector === DateColloct.Calendar ? (
                                        <ProductSelector
                                            style={{
                                                height: "100%",
                                                margin: "0px",
                                                marginLeft: "10px",
                                                borderColor: lightTheme.border,
                                            }}
                                            productNames={productNames}
                                            selectedIndex={selectedProductIndex}
                                            onSelectedIndex={(index) => {
                                                const date = new Date();
                                                date.setDate(1);
                                                navigate(
                                                    `?franchise=${selectedFranchiseIndex}&product=${index}&dateTime=${date.getTime()}`,
                                                );
                                            }}
                                        />
                                    ) : null}
                                    <AddScheduleButton
                                        onClick={() =>
                                            setShowAddSchedulePopup(true)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <AddScheduleButtonText>
                                            스케줄 추가
                                        </AddScheduleButtonText>
                                    </AddScheduleButton>
                                </BookingSelectorContentInner>
                            </>
                        ) : (
                            <BookingSelectorContentInner>
                                <DateSwitch
                                    style={{
                                        height: "100%",
                                        margin: "0px",

                                        borderColor: lightTheme.border,
                                    }}
                                    dateCollector={dateCollector}
                                    routes={routes}
                                />
                                <FranchiseSelector
                                    style={{
                                        height: "100%",
                                        margin: "0px",
                                        marginLeft: "10px",
                                        borderColor: lightTheme.border,
                                    }}
                                    franchiseNames={franchiseNames}
                                    selectedIndex={selectedFranchiseIndex}
                                    onSelectedIndex={(index) => {
                                        const date = new Date();
                                        let param = `?franchise=${index}&dateTime=${date.getTime()}`;

                                        if (
                                            dateCollector ===
                                            DateColloct.Calendar
                                        ) {
                                            param = param + "&product=0";
                                        }
                                        navigate(param);
                                    }}
                                />
                                {dateCollector === DateColloct.Calendar ? (
                                    <ProductSelector
                                        style={{
                                            height: "100%",
                                            margin: "0px",
                                            marginLeft: "10px",
                                            borderColor: lightTheme.border,
                                        }}
                                        productNames={productNames}
                                        selectedIndex={selectedProductIndex}
                                        onSelectedIndex={(index) => {
                                            const date = new Date();
                                            date.setDate(1);
                                            navigate(
                                                `?franchise=${selectedFranchiseIndex}&product=${index}&dateTime=${date.getTime()}`,
                                            );
                                        }}
                                    />
                                ) : null}
                                <DateSelector
                                    style={{ marginLeft: "10px" }}
                                    dateCollector={dateCollector}
                                    date={startDate}
                                    onChangeDate={(date) => {
                                        navigate(
                                            `?franchise=${selectedFranchiseIndex}&product=${selectedProductIndex}&dateTime=${date.getTime()}`,
                                        );
                                    }}
                                />
                                <AddScheduleButton
                                    onClick={() =>
                                        setShowAddSchedulePopup(true)
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <AddScheduleButtonText>
                                        스케줄 추가
                                    </AddScheduleButtonText>
                                </AddScheduleButton>
                            </BookingSelectorContentInner>
                        )}
                    </BookingsSelectorContent>
                    <BookingsHeaderBottom>
                        <BookingColorHint />
                    </BookingsHeaderBottom>
                    {franchiseNames.length > 0 && productNames.length > 0 ? <FranchiseTitleContainer>
                        {dateCollector === DateColloct.Day ? <FranchiseTitle>
                            {franchiseNames[selectedFranchiseIndex].value}
                        </FranchiseTitle> : <FranchiseTitle>
                            {`${franchiseNames[selectedFranchiseIndex].value}(${productNames[selectedProductIndex].value})`}
                        </FranchiseTitle>}
                    </FranchiseTitleContainer> : null}
                </BookingsSelectorContainer>
                {franchises.length > 0 && products.length > 0 ? (
                    <>
                        {dateCollector === DateColloct.Day ? (
                            <BookingsCalendarDay
                                franchiseIndex={Number(
                                    franchiseNames[selectedFranchiseIndex].key,
                                )}
                                franchiseName={franchiseValue()}
                                useHalfTimeFranchise={
                                    franchises[selectedFranchiseIndex]
                                        .useHalfTimeFranchise
                                }
                                products={products}
                                date={startDate}
                            />
                        ) : (
                            <>
                                {calendarDates.length > 0 ? (
                                    <BookingsCalendarMonth
                                        franchiseIndex={Number(
                                            franchiseNames[
                                                selectedFranchiseIndex
                                            ].key,
                                        )}
                                        selectedProductIndex={Number(
                                            productNames[selectedProductIndex]
                                                .key,
                                        )}
                                        franchiseName={franchiseValue()}
                                        useHalfTimeFranchise={
                                            franchises[selectedFranchiseIndex]
                                                .useHalfTimeFranchise
                                        }
                                        selectedProductName={productValue()}
                                        calendarDates={calendarDates}
                                        thisMonthIndex={startDate.getMonth()}
                                    />
                                ) : null}
                            </>
                        )}
                    </>
                ) : null}
            </Inner>
            {showAddSchedulePopup &&
            franchises.length > 0 &&
            franchiseNames.length > 0 ? (
                <AddSchedulePopup
                    franchises={franchises}
                    franchiseNames={franchiseNames}
                    franchiseIndex={selectedFranchiseIndex}
                    startProductIndex={
                        dateCollector === DateColloct.Day
                            ? 0
                            : selectedProductIndex
                    }
                    date={startDate}
                    didClosePopup={() => setShowAddSchedulePopup(false)}
                    didSuccessAddSchedule={() => setShowAddSchedulePopup(false)}
                />
            ) : null}
            {me && me?.memberStatus !== MemberStatus.ACTIVE ? (
                <Blur navigationWidth={navigationWidth}>
                    회원님의 상태가 활성화되지 않아 블러처리됩니다
                </Blur>
            ) : null}
        </Container>
    );
}
