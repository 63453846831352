import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Banner } from "../components/Banner";
import { Footer } from "../components/Footer";
import { InfoCards } from "../components/home/InfoCards";
import { InfoHowToUse } from "../components/home/InfoHowToUse";
import { InfoItemAdditions } from "../components/home/InfoItemAdditions";
import { InfoItems } from "../components/home/InfoItems";
import { PageTitle } from "../components/PageTitle";
import { SystemCancelRegScheduleBookingPopup } from "../components/popup/SystemCancelRegScheduleBookingPopup";
import { isMemberSignUp } from "../global";
import useMember from "../hooks/useMember";
import routes from "../routes";
import { ButtonSpan, lightTheme } from "../styles";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const TopContainer = styled.div`
    width: 100%;
    height: 360px;
    background-color: white;
    display: flex;
    justify-content: center;
    user-select: none;

    @media ${({ theme }) => theme.tablet} {
        height: 500px;
    }
`;

const TopContent = styled.div`
    display: flex;
    height: 100%;
    max-width: 900px;
    min-width: 460px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media ${({ theme }) => theme.tablet} {
        justify-content: center;
        flex-direction: column-reverse;
        max-width: 320px;
        min-width: 320px;
        margin-top: 20px;
    }

    @media ${({ theme }) => theme.miniMobile} {
        max-width: 300px;
        min-width: 300px;
    }
`;

const TopImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    height: 300px;
    width: 538px;

    @media ${({ theme }) => theme.tablet} {
        height: 200px;
        width: 358px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1;
    line-height: 200%;
    justify-content: center;
    white-space: nowrap;

    @media ${({ theme }) => theme.tablet} {
        white-space: pre-wrap;
        padding-bottom: 40px;
    }
`;

const TopTitle = styled.span`
    font-size: 35px;
    margin-top: 20px;
    font-weight: 800;
    margin-bottom: 20px;

    @media ${({ theme }) => theme.tablet} {
        font-size: 25px;
    }
`;

const TopTitleBlue = styled(TopTitle)`
    color: ${(props) => props.theme.lightBlue};
`;

const TopSubTitle = styled.span`
    font-size: 30px;
    font-weight: 700;

    @media ${({ theme }) => theme.tablet} {
        font-size: 20px;
    }
`;

const TopText = styled.span`
    font-size: 16px;

    @media ${({ theme }) => theme.tablet} {
        font-size: 14px;
    }
`;

const StartOnbiContainer = styled.div`
    display: flex;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.white};
`;
const StartOnbiButton = styled(ButtonSpan)`
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    padding: 15px 0px;
    border-radius: 2px;
    a {
        color: ${(props) => props.theme.white};
        padding: 15px 80px;
        text-decoration: none;
    }
`;

export function Home() {
    const me = useMember();
    const isTablet = useMediaQuery({ query: lightTheme.tablet });
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    const [isSignUp, setIsSignUp] = useState(false);
    const [showSystemCancelPopup, setShowSystemCancelPopup] = useState(false);

    useEffect(() => {
        if (me && me !== undefined) {
            setIsSignUp(isMemberSignUp(me.signUpCompleteStep));
        }
    }, [me]);

    return (
        <Container>
            <Content>
                <PageTitle title="나만의 온라인 비서" />
                {isSignUp === true ? (
                    <Banner
                        text="가입을 완료하시면 서비스를 이용하실 수 있습니다."
                        linkTo={routes.signup}
                        buttonText="가입하러 가기"
                    />
                ) : null}

                <TopContainer>
                    <TopContent>
                        <TextContainer>
                            <TopSubTitle>
                                온비 | 공간 플랫폼 통합 관리
                            </TopSubTitle>
                            <TopTitle>
                                나만의 온라인 비서{" "}
                                <TopTitleBlue>ONBI</TopTitleBlue>
                            </TopTitle>
                            {isMobile || isTablet ? (
                                <TopText>
                                    여러 플랫폼에 장소를 등록하면 매출은
                                    증가하지만 관리가 어려워 하나의 플랫폼만
                                    사용하고 있는 사업주 분들을 위한 ONBI입니다
                                </TopText>
                            ) : (
                                <>
                                    <TopText>
                                        여러 플랫폼에 장소를 등록하면 매출은
                                        증가하지만 관리가 어려워
                                    </TopText>
                                    <TopText>
                                        하나의 플랫폼만 사용하고 있는 사업주
                                        분들을 위한 ONBI입니다
                                    </TopText>
                                </>
                            )}
                        </TextContainer>
                        <TopImageContainer>
                            <Image
                                src={require("../assets/images/home_top.webp")}
                                alt="나만의 온라인 비서 온비"
                            />
                        </TopImageContainer>
                    </TopContent>
                </TopContainer>
                <InfoCards />
                <InfoHowToUse />
                <InfoItems />
                <InfoItemAdditions />
                <StartOnbiContainer>
                    <StartOnbiButton>
                        <Link to={routes.consulting}>온비 시작하기</Link>
                    </StartOnbiButton>
                </StartOnbiContainer>
            </Content>
            <Footer />
            {showSystemCancelPopup ? (
                <SystemCancelRegScheduleBookingPopup
                    didClosePopup={() => setShowSystemCancelPopup(false)}
                />
            ) : null}
        </Container>
    );
}
