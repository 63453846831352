import styled from "styled-components";

const SFormButton = styled.input<{ backgroundColor?: string; color?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    padding: 15px 0px;
    margin-top: 10px;
    border-radius: 8px;
    border: 0px;
    color: ${(props) => (props.color ? props.color : props.theme.white)};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : props.theme.blue};
    user-select: none;
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
    text-align: center;
`;

interface FormButtonProps {
    title: string;
    disabled?: boolean;
    onClick?: React.MouseEventHandler;
    type?: string;
    backgroundColor?: string;
    color?: string;
}

export function FormButton({
    title,
    disabled,
    onClick,
    type,
    backgroundColor,
    color,
}: FormButtonProps) {
    let newType = "button";

    if (type) {
        newType = type;
    }

    return (
        <SFormButton
            disabled={disabled}
            onClick={onClick}
            value={newType === "submit" ? title : undefined}
            defaultValue={newType === "submit" ? undefined : title}
            type={newType}
            backgroundColor={backgroundColor}
            color={color}
        />
    );
}
