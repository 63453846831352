import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { removeTokens, signUserIn, signUserOut } from "../apollo";
import { CheckBoxWithText } from "../components/CheckBox";
import { Form } from "../components/form/Form";
import { FormButton } from "../components/form/FormButton";
import { FormContainer } from "../components/form/FormContainer";
import {
    FormInput,
    FormInputContainer,
} from "../components/form/FormInputContainer";
import { FormLogo } from "../components/form/FormLogo";
import { FormTitle } from "../components/form/FormTitle";
import { PageTitle } from "../components/PageTitle";
import { SIGNIN_MEMBER_MUTATION } from "../graphqls/mutations";
import { ButtonSpan } from "../styles";
import { SignInType } from "../__generated__/globalTypes";
import {
    signInMember,
    signInMemberVariables,
} from "../__generated__/signInMember";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100%;
`;

interface ISignInForm {
    onbi_sign_in_id: string;
    onbi_sign_in_password: string;
}

export function SingIn() {
    const {
        register,
        formState: { isValid },
        getValues,
        setValue,
        handleSubmit,
    } = useForm<ISignInForm>({
        mode: "onChange",
    });

    const [submitting, setSubmitting] = useState(false);
    const [isCheckedAutoSignIn, setIsCheckedAutoSignIn] = useState(false);

    const onCompletedSignIn = (data: signInMember) => {
        setSubmitting(false);

        const {
            signInMember: { ok, error, token },
        } = data;

        if (ok) {
            if (token) {
                const loginId = getValues("onbi_sign_in_id");

                signUserIn({
                    loginId,
                    token,
                    autoSignIn: isCheckedAutoSignIn,
                    refresh: false,
                });
            } else {
                signUserOut();
            }
        } else {
            alert(error);
            removeTokens();
            setValue("onbi_sign_in_password", "");
        }
    };

    const [signInMutation] = useMutation<signInMember, signInMemberVariables>(
        SIGNIN_MEMBER_MUTATION,
        { onCompleted: onCompletedSignIn },
    );

    const onClickSignIn = () => {
        setSubmitting(true);
        signInMutation({
            variables: {
                id: getValues("onbi_sign_in_id"),
                password: getValues("onbi_sign_in_password"),
                autoSignIn: isCheckedAutoSignIn,
                signInType: SignInType.MEMBER,
            },
        });
    };

    return (
        <Container>
            <PageTitle title="로그인" />
            <FormContainer>
                <Form onSubmit={handleSubmit(onClickSignIn)}>
                    <ButtonSpan
                        onClick={() => {
                            window.location.href = "/";
                        }}
                        style={{ paddingTop: "50px" }}
                    >
                        <FormLogo>ONBI</FormLogo>
                    </ButtonSpan>

                    <FormTitle>나만의 온라인 비서</FormTitle>
                    <FormInputContainer
                        input={
                            <FormInput
                                {...register("onbi_sign_in_id", {
                                    required: true,
                                })}
                                placeholder="아이디"
                            />
                        }
                    ></FormInputContainer>
                    <FormInputContainer
                        input={
                            <FormInput
                                {...register("onbi_sign_in_password", {
                                    required: true,
                                })}
                                placeholder="비밀번호"
                                type="password"
                            />
                        }
                    ></FormInputContainer>
                    <CheckBoxWithText
                        isChecked={isCheckedAutoSignIn}
                        title="자동로그인"
                        onClick={() => {
                            if (!isCheckedAutoSignIn) {
                                alert(
                                    "현재 사용하시는 PC가 공용 PC라면 자동로그인 기능을 사용하지 마세요.\n\n개인정보 및 사생활 보호를 위해 공용PC의 자동로그인은 권장하지않습니다.",
                                );
                            }

                            setIsCheckedAutoSignIn(!isCheckedAutoSignIn);
                        }}
                    />
                    <FormButton
                        title="로그인"
                        disabled={!isValid || submitting}
                        type="submit"
                    />
                </Form>
            </FormContainer>
        </Container>
    );
}
