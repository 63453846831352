import { gql } from "@apollo/client";

export const ME_QUERY = gql`
    query me {
        me {
            ok
            error
            member {
                id
                key
                franchises {
                    key
                    name
                }
                memberPaymentInfo {
                    key
                    name
                    nextPaymentDate
                    businessNumber
                    businessName
                    bossName
                    memberCost
                    paymentCount
                    addItemCount
                    dayCost
                    diffDay
                    vat
                    sumVATCost
                }
                memberCertification {
                    key
                    phoneCertExpiryUTC
                }
                name
                memberStatus
                memberLevel
                inactiveReason
                signUpCompleteStep
                email
                phone
                saleItem {
                    key
                    name
                    cost
                    maxProductCount
                    maxMessageCount
                    addItemCost
                    addItemMessageCount
                }
                plusItem {
                    key
                    useManualSendBookingMsg
                    useExpandScheduleManagement
                    useBookingUserManagement
                    useUpdateScheduleInfo
                    useBookingImageMessage
                    immediatelyBookingMessage
                    useUnableToSendBookingText
                    usedBookingHideUserInfo
                    oldBookingDeleteDate
                    useBookingMessage
                    useHideProduct
                    useKakaoMessage
                }
                productCount
                createdAt
                firstActiveDate
                signInSessionExpiryDate
                bookingMsgScheduleHours
                bookingMsgScheduleMinutes
                frontVersion
            }
        }
    }
`;

export const SEE_ALL_SALE_ITEMS_QUERY = gql`
    query seeAllSaleItems {
        seeAllSaleItems {
            ok
            error
            saleItems {
                key
                name
                cost
                period
                maxProductCount
                maxMessageCount
                maxMarketingCount
            }
        }
    }
`;

export const SEE_MY_HOSTS_QUERY = gql`
    query seeMyHosts($site: SiteType!) {
        seeMyHosts(site: $site) {
            ok
            error
            hosts {
                site
                key
            }
        }
    }
`;

export const SEE_REPRESENTATIVE_HOST_ID = gql`
    query seeRepresentativeHostId($site: SiteType!) {
        seeRepresentativeHostId(site: $site) {
            ok
            error
            id
        }
    }
`;

export const SEE_FRANCHISES_QUERY = gql`
    query seeFranchises($site: SiteType) {
        seeFranchises(site: $site) {
            ok
            error
            franchises {
                key
                id
                name
                index
                messages
                isProductMessage
                useHalfTimeFranchise
                host {
                    key
                    site
                }
                products {
                    key
                    id
                    name
                    index
                    messages
                    hideProduct
                }
            }
        }
    }
`;

export const SEE_BOOKING_CALENDAR_QUERY = gql`
    query seeBookingCalendar(
        $startDateTime: String!
        $endDateTime: String!
        $franchiseIndex: Int!
    ) {
        seeBookingCalendar(
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            franchiseIndex: $franchiseIndex
        ) {
            ok
            error
            bookings {
                key
                checkIndex
                id
                site
                schedule
                statusCode
                startDateKST
                endDateKST
                startDateUTC
                endDateUTC
                totalPrice
                username
                userPhone
                memo
                textMessages {
                    statusCode
                    statusMessage
                }
                product {
                    key
                    index
                    name
                }
            }
        }
    }
`;

export const SEE_SYSTEM_QUERY = gql`
    query seeSystem($name: String!) {
        seeSystem(name: $name) {
            ok
            error
            system {
                key
                freePeriod
                paymentReminderDay
                settlementDay
                extraTextMessageCost
                frontVersion
            }
        }
    }
`;

export const SEE_STATISTICS_BOOKINGS_QUERY = gql`
    query seeStatisticsBookings(
        $baseDateTime: String!
        $beforeDateOrMonth: Int!
        $isMonth: Boolean!
    ) {
        seeStatisticsBookings(
            baseDateTime: $baseDateTime
            beforeDateOrMonth: $beforeDateOrMonth
            isMonth: $isMonth
        ) {
            ok
            error
            statisticsBookings {
                key
                date
                siteDatas {
                    site
                    totalPrice
                    useCount
                    salesProjectionPrice
                    salesProjectionUseCount
                }
                totalPrice
                useCount
                salesProjectionPrice
                salesProjectionUseCount
                productIndex
                franchiseIndex
            }
        }
    }
`;

export const SEE_MEMBER_SYSTEM_CANCEL_BOOKINGS_QUERY = gql`
    query seeMemberSystemCancelRegScheduleBookings {
        seeMemberSystemCancelRegScheduleBookings {
            ok
            error
            systemCancelBookings {
                key
                id
                schedule
                username
                userPhone
                statusCode
                startDateUTC
                endDateUTC
                site
                createdAt
                checkIndex
                memberKey
                sysCancelCauseBookingId
                sysCancelCauseScheduleKey
                product {
                    name
                    key
                    franchise {
                        name
                        key
                    }
                }
            }
            affectBookings {
                key
                id
                schedule
                username
                userPhone
                statusCode
                startDateUTC
                endDateUTC
                site
                createdAt
                checkIndex
                memberKey
            }
        }
    }
`;

export const SEE_MEMBER_FILES_QUERY = gql`
    query seeMemberFiles($fileType: OnbiFileType!) {
        seeMemberFiles(fileType: $fileType) {
            ok
            error
            memberFiles {
                key
                url
                fileType
            }
        }
    }
`;

export const SEE_BOARDS = gql`
    query seeBoards($type: BoardType!) {
        seeBoards(type: $type) {
            ok
            error
            boards {
                key
                type
                author
                title
                createdAt
                updatedAt
            }
        }
    }
`;

export const SEE_BOARD = gql`
    query seeBoard($index: Int!, $newTab: Boolean!) {
        seeBoard(index: $index, newTab: $newTab) {
            ok
            error
            board {
                key
                type
                author
                title
                content
                createdAt
                updatedAt
            }
        }
    }
`;

export const SEE_SOLAPI_MESSAGE = gql`
    query seeSolapiMessage($messageId: String!) {
        seeSolapiMessage(messageId: $messageId) {
            ok
            error
            message {
                to
                type
                text
                statusCode
                resendCount
                reason
                networkName
                messageId
                dateCreated
            }
        }
    }
`;
export const SEE_SOLAPI_MESSAGES = gql`
    query seeSolapiMessages($limit: Int!, $nextKey: String) {
        seeSolapiMessages(limit: $limit, nextKey: $nextKey) {
            ok
            error
            nextKey
            messages {
                type
                to
                reason
                messageId
                dateCreated
            }
        }
    }
`;

export const SEE_BOOKING_DETAIL = gql`
    query seeBookingDetail($key: String!) {
        seeBookingDetail(key: $key) {
            ok
            error
            booking {
                key
                checkIndex
                id
                site
                schedule
                statusCode
                startDateKST
                endDateKST
                startDateUTC
                endDateUTC
                totalPrice
                username
                userPhone
                memo
                textMessages {
                    statusCode
                    statusMessage
                }
                product {
                    key
                    index
                    name
                }
            }
        }
    }
`;

export const SEE_ALL_MEMBER_FRANCHISES_BY_KAKAO_QUERY = gql`
    query seeAllMemberFranchisesByKakao($memberKey: String) {
        seeAllMemberFranchisesByKakao(memberKey: $memberKey) {
            ok
            error
            kakaoBookingMessageOption {
                id
                useCustomMessage
            }
            franchises {
                key
                name
                index
                site
                isProductMessage
                products {
                    key
                    name
                    index
                    kakaoBookingMessage {
                        id
                        kakaoMessageId
                        url
                        kakaoCustomBookingMessage {
                            id
                            kakaoBookingMessageId
                            templateId
                        }
                    }
                }
            }
        }
    }
`;
