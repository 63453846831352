import styled from "styled-components";
import useMember from "../hooks/useMember";
import { FormButton } from "../components/form/FormButton";
import { useState } from "react";
import { KakaoMessageSendPopup } from "../components/popup/KakaoMessageSendPopup";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 20px;
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 700;
`;

const Content = styled.span`
    font-size: 16px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.div`
    width: 250px;
`;

const VGap = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`;

export function Kakao() {
    const me = useMember();

    const [showKakaoMessagePopup, setShowKakaoMessagePopup] = useState(false);

    const onCompletionClick = () => {
        setShowKakaoMessagePopup(true);
    };

    return (
        <Container>
            <Title>카카오톡 메시지로 전환하기</Title>
            <VGap />
            <Content>
                예약 안내 사항을 문자 메시지에서 카카오톡 메시지로 전환합니다.
            </Content>
            <VGap />
            <Content>
                카카오톡이 없는 사용자에게는 안내 사항이 문자 메시지로
                전송됩니다.
            </Content>
            <VGap />
            <VGap />
            <Content>
                1.{" "}
                <a
                    href="https://dotorisoop.notion.site/1-249e5d717cec4662bbfad2135d538fbf"
                    target="_blank"
                >
                    시작하기
                </a>
            </Content>
            <VGap />
            <VGap />
            <Content>
                2.{" "}
                <a
                    href="https://dotorisoop.notion.site/2-01638fa360c9407fa9b51cf8184781eb"
                    target="_blank"
                >
                    메시지 등록하기
                </a>
            </Content>
            <VGap />
            <VGap />
            <Content>
                카카오톡 메시지는 템플릿 형태로만 전송 가능하며 자세한 사항은
                [2. 메시지 등록하기]를 참고해주시기 바랍니다.
            </Content>
            <VGap />
            <Content>
                카카오 전환 후 문자로 다시 전환을 희망하시는 경우 고객센터로
                문의바랍니다.
            </Content>
            <VGap />
            <Content>
                가입에 어려움이 있으신 경우 온비 고객센터로 문의해주시기
                바랍니다.
            </Content>
            <VGap />
            <Content>
                카카오 채널 및 메시지 등록 대리 가입도 가능하며 채널 1개당
                55,000원의 수수료가 발생합니다.
            </Content>
            <VGap />

            <Content>
                ★ 모든 준비가 완료되신 경우 아래 버튼을 눌러 고객센터에
                전달해주세요.
            </Content>
            <VGap />
            <VGap />
            <VGap />
            <ButtonContainer>
                <Button>
                    <FormButton
                        title="완료되었습니다"
                        onClick={onCompletionClick}
                        backgroundColor="#f7e50b"
                        color="#391c1e"
                    />
                </Button>
            </ButtonContainer>

            {showKakaoMessagePopup ? (
                <KakaoMessageSendPopup
                    didClosePopup={() => setShowKakaoMessagePopup(false)}
                />
            ) : null}
        </Container>
    );
}
