import { useQuery } from "@apollo/client";
import { useState } from "react";
import { SEE_SOLAPI_MESSAGE } from "../../graphqls/queries";
import {
    seeSolapiMessage,
    seeSolapiMessageVariables,
} from "../../__generated__/seeSolapiMessage";
import { TextPopup } from "./TextPopup";

interface ITextMessagePopup {
    messageId: string;
    didClosePopup: () => void;
}

export function TextMessagePopup({
    messageId,
    didClosePopup,
}: ITextMessagePopup) {
    const [messageText, setMessageText] =
        useState("메시지를 불러오는 중입니다..");

    useQuery<seeSolapiMessage, seeSolapiMessageVariables>(SEE_SOLAPI_MESSAGE, {
        variables: {
            messageId,
        },
        onCompleted: ({
            seeSolapiMessage: { ok, error, message },
        }: seeSolapiMessage) => {
            if (ok) {
                if (message && message.text) {
                    setMessageText(message.text);
                }
            }
        },
    });

    return <TextPopup text={messageText} didClosePopup={didClosePopup} />;
}
