import { KeyValues } from "../../utils";
import SelectBox from "../SelectBox";

interface IFranchiseSelectorProps {
    franchiseNames: KeyValues[];
    selectedIndex: number;
    style?: React.CSSProperties;
    onSelectedIndex: (index: number) => void;
}

export function FranchiseSelector({
    franchiseNames,
    selectedIndex,
    style,
    onSelectedIndex,
}: IFranchiseSelectorProps) {
    const franchiseValue = () => {
        let result = "";
        if (franchiseNames.length > 0) {
            result = franchiseNames[selectedIndex].value;
        }

        return result;
    };

    const onSelectFranchise = (index: number) => {
        onSelectedIndex(index);
    };

    return (
        <SelectBox
            style={style}
            key_values={franchiseNames}
            selectValue={franchiseValue()}
            onSelectValue={(_, index) => {
                onSelectFranchise(index);
            }}
        />
    );
}
