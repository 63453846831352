import styled from "styled-components";

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.border};
    margin: 30px 0px;
    background-color: ${(props) => props.theme.white};
`;
