import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { toWonWithoutWonText } from "../../../global";
import { lightTheme } from "../../../styles";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.black};

    @media ${({ theme }) => theme.mobile} {
        margin-bottom: 10px;
    }
`;

const ChartSubTitle = styled.span`
    font-size: 13px;
    margin-top: 20px;
    margin-right: 50px;
    font-weight: 700;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
        margin-top: 10px;
    }
`;

const ChartNumberSubTitle = styled.span`
    font-size: 13px;
    color: ${(props) => props.theme.fontDarkGray};
    margin-bottom: 5px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

const ChartNumber = styled.span`
    font-size: 24px;
    font-weight: 800;

    @media ${({ theme }) => theme.mobile} {
        font-size: 15px;
    }
`;

const ChartNumberSalesProjection = styled.span`
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.fontGray};

    @media ${({ theme }) => theme.mobile} {
        font-size: 12px;
    }
`;

const ChartNumberSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 7px;
`;

const ChartNumberSubRowContainer = styled.div`
    display: flex;
`;

const ChartNumberSub = styled.span`
    font-size: 13px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

const ChartNumberSubBlue = styled.span`
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.lightBlue};
    margin-left: 3px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

const ChartNumberSubRed = styled.span`
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.red};
    margin-left: 3px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

interface StatisticsBookingsTitleProp {
    subTitle?: string;
    subTitleColor?: string;
    numberTitle: string;
    baseData: number;
    beforeData: number;
    salesProjection: number;
}

export function StatisticsBookingsTitle({
    subTitle,
    subTitleColor,
    numberTitle,
    baseData,
    beforeData,
    salesProjection,
}: StatisticsBookingsTitleProp) {
    const isMobile = useMediaQuery({ query: lightTheme.mobile });

    const compSales = (today: number, yesterday: number) => {
        const sales = today - yesterday;
        let result = `${sales}`;

        if (sales > 0) {
            result = `+${sales}`;
        }

        return result;
    };

    const compSalesPercent = (today: number, yesterday: number) => {
        const sales = today - yesterday;
        let result = `-100%`;
        if (today > 0) {
            const percent = Math.ceil((sales / today) * 100);
            result = `${percent}%`;

            if (sales > 0) {
                result = `+${percent}%`;
            }
        }

        return result;
    };

    return (
        <Container>
            {subTitle ? (
                <ChartSubTitle
                    style={subTitleColor ? { color: subTitleColor } : undefined}
                >
                    {subTitle}
                </ChartSubTitle>
            ) : null}
            <ChartNumberSubContainer
                style={isMobile ? { marginTop: "10px" } : { marginTop: "15px" }}
            >
                <ChartNumber>
                    {toWonWithoutWonText(baseData)}
                    <ChartNumberSub>{numberTitle}</ChartNumberSub>
                </ChartNumber>
                {salesProjection > 0 ? (
                    <>
                    <ChartNumberSalesProjection>
                        <ChartNumberSub>앞으로</ChartNumberSub>
                        {` +${toWonWithoutWonText(salesProjection)}`}
                        <ChartNumberSub>{numberTitle}</ChartNumberSub>
                    </ChartNumberSalesProjection>
                    <ChartNumberSalesProjection>
                        <ChartNumberSub>총</ChartNumberSub>
                        {` ${toWonWithoutWonText(baseData + salesProjection)}`}
                        <ChartNumberSub>{numberTitle}</ChartNumberSub>
                    </ChartNumberSalesProjection>
                    </>
                    
                    
                ) : null}
            </ChartNumberSubContainer>
            {isMobile === false ? (
                <ChartNumberSubContainer style={{ marginTop: "10px" }}>
                    <ChartNumberSubTitle>이전 대비</ChartNumberSubTitle>
                    <ChartNumberSubRowContainer>
                        <ChartNumberSub>
                            {`${compSales(baseData, beforeData)}`}
                            {baseData - beforeData > 0 ? (
                                <ChartNumberSubRed>{`(${compSalesPercent(
                                    baseData,
                                    beforeData,
                                )})`}</ChartNumberSubRed>
                            ) : (
                                <ChartNumberSubBlue>{`(${compSalesPercent(
                                    baseData,
                                    beforeData,
                                )})`}</ChartNumberSubBlue>
                            )}
                        </ChartNumberSub>
                    </ChartNumberSubRowContainer>
                </ChartNumberSubContainer>
            ) : null}
        </Container>
    );
}
