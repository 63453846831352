import { useState } from "react";
import { FieldError, UseFormGetValues, UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import { ButtonSpan, lightTheme } from "../../../styles";
import { LoginType } from "../../../__generated__/globalTypes";
import { FormInput, FormInputContainer } from "../../form/FormInputContainer";
import { LoginSelectorPopup } from "../../popup/LoginSelectorPopup";
import { IHostForm } from "../SignUpHostForm";
import {
    HostFormCheckContainer,
    HostFormCheckText,
    HostFormContainer,
    HostFormInnerContainer,
    HostFormTitle,
} from "./HostFormCommon";

interface SCHostFormProps {
    register: UseFormRegister<IHostForm>;
    getValues: UseFormGetValues<IHostForm>;
    formErrors: {
        sc_host_id?: FieldError | undefined;
        sc_host_password?: FieldError | undefined;
        sc_host_password_check?: FieldError | undefined;
    };
}

const SelectorHint = styled.span`
    width: 100%;
    font-size: 13px;
    color: ${(props) => props.theme.fontDarkGray};
    margin-top: 10px;
    margin-bottom: 10px;
`;

const LoginSelectorButton = styled(ButtonSpan)`
    display: flex;
    width: 100%;
    height: 30px;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
`;

export function SCHostForm({
    register,
    getValues,
    formErrors,
}: SCHostFormProps) {
    const [showLoginSelectorPopup, setShowLoginSelectorPopup] = useState(false);

    return (
        <HostFormContainer>
            <HostFormInnerContainer>
                <HostFormTitle>스페이스 클라우드 정보</HostFormTitle>
                <SelectorHint>로그인방법</SelectorHint>
                <LoginSelectorButton
                    onClick={() => setShowLoginSelectorPopup(true)}
                >
                    {LoginType.EMAIL}
                </LoginSelectorButton>
                <FormInputContainer
                    style={{ marginTop: "10px" }}
                    input={
                        <FormInput
                            {...register("sc_host_id")}
                            placeholder="스페이스 클라우드 아이디(없으면 전체 공란)"
                        />
                    }
                />
                <FormInputContainer
                    style={{ marginTop: "5px" }}
                    input={
                        <FormInput
                            autoComplete="new-password"
                            {...register("sc_host_password")}
                            placeholder="비밀번호"
                            type="password"
                        />
                    }
                />
                <FormInputContainer
                    style={{ marginTop: "5px", marginBottom: "10px" }}
                    input={
                        <FormInput
                            {...register("sc_host_password_check", {
                                validate: (value) => {
                                    return (
                                        value ===
                                            getValues("sc_host_password") ||
                                        "두 비밀번호가 일치하지 않습니다."
                                    );
                                },
                            })}
                            placeholder="비밀번호 확인"
                            type="password"
                        />
                    }
                    isError={Boolean(
                        formErrors.sc_host_password_check?.message,
                    )}
                    error={formErrors.sc_host_password_check?.message}
                />
                <HostFormCheckContainer>
                    <HostFormCheckText style={{ marginBottom: "0px" }}>
                        <HostFormCheckText
                            style={{
                                marginBottom: "0px",
                                color: lightTheme.red,
                            }}
                        >
                            비밀번호가 변경되면
                        </HostFormCheckText>{" "}
                        서비스가 정지 될 수 있습니다.
                    </HostFormCheckText>
                    <HostFormCheckText style={{ marginBottom: "0px" }}>
                        (비밀번호 변경시 관리자에게 연락 바랍니다.)
                    </HostFormCheckText>
                </HostFormCheckContainer>
            </HostFormInnerContainer>

            {showLoginSelectorPopup ? (
                <LoginSelectorPopup
                    didClosePopup={() => setShowLoginSelectorPopup(false)}
                />
            ) : null}
        </HostFormContainer>
    );
}
