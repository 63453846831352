import styled from "styled-components";

export const BOARD_CHANGE_EMAIL_INDEX = 53;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.fontGray};
    padding-bottom: 20px;
`;

const FAQText = styled.span`
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    user-select: none;
`;

const Title = styled.span`
    font-size: 17px;
    font-weight: 700;
`;

interface IFAQDetailHeaderProps {
    title: string;
    newTab: boolean;
}

export function FAQDetailHeader({ title, newTab }: IFAQDetailHeaderProps) {
    return (
        <Container>
            {newTab ? <FAQText>{"FAQ"}</FAQText> : null}
            <Title>{title}</Title>
        </Container>
    );
}
