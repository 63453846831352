import styled from "styled-components";

export const Base = styled.div`
    display: flex;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    line-height: 150%;
    color: ${(props) => props.theme.black};
    max-width: 900px;
    padding-bottom: 50px;
`;
export const Header = styled.span`
    font-weight: 700;
    font-size: 25px;
    margin: 30px 0px;
    width: 100%;
`;

export const Enter = styled.br``;

export const Title = styled.span`
    font-weight: 700;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
`;

export const Text = styled.span`
    font-size: 13px;
    width: 100%;
`;
export const TabText = styled.span`
    padding-left: 25px;
    font-size: 13px;
    width: 90%;
`;

export const BoldText = styled.span`
    font-size: 13px;
    font-weight: 700;
    margin: 5px 0px;
    width: 100%;
`;

export const Table = styled.div`
    display: grid;
    grid-auto-rows: 32px;
    font-size: 13px;
`;

export const TableColumn = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 250px);
    :nth-child(1) {
        font-weight: 700;
    }
`;

export const TableCell = styled.div`
    display: flex;
    align-items: center;
    padding-left: 7px;
    border: 1px solid gray;
`;
