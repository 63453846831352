import styled from "styled-components";

export const FormFooter = styled.span`
    font-size: 11px;
    margin-top: 50px;
    width: 80%;
    text-align: center;
    color: ${(props) => props.theme.fontGray};

    a {
        color: ${(props) => props.theme.fontGray};
    }
`;
