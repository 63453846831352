import styled from "styled-components";
import { ButtonSpan } from "../../styles";

export const PopupCell = styled.div`
    display: grid;
    width: 100%;
    height: 50px;
    grid-template-columns: 80px 0.9fr;
    border-bottom: 1px solid ${(props) => props.theme.borderLight};
    align-items: center;
`;

export const PopupCellTitle = styled.span`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.fontDarkGray};
`;

export const PopupCellTitleHighlight = styled(PopupCellTitle)`
    color: ${(props) => props.theme.red};
`;

export const PopupCellContentRow = styled.div`
    display: flex;
    align-items: center;
    height: 70%;
`;

export const PopupCellContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
`;

export const PopupCellText = styled.span`
    font-size: 14px;
`;

export const PopupCellTextContainer = styled.div`
    display: flex;
`;

export const PopupCellInputContainer = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 5px;
    width: 100%;
    height: 100%;
    justify-content: center;
`;

export const PopupCellInput = styled.input`
    width: 90%;
    border: 0px;
    outline: none;
`;

export const PopupButton = styled(ButtonSpan)`
    font-size: 14px;
    text-align: center;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.blue};
`;
