import styled from "styled-components";
import { Popup } from "./Popup";
import {
    hasOnbiLoginInfoId,
    removeTokens,
    signUserIn,
    signUserOut,
} from "../../apollo";
import { useForm } from "react-hook-form";
import {
    signInMember,
    signInMemberVariables,
} from "../../__generated__/signInMember";
import { useMutation } from "@apollo/client";
import { SIGNIN_MEMBER_MUTATION } from "../../graphqls/mutations";
import { useState } from "react";
import { SignInType } from "../../__generated__/globalTypes";
import { FormContainer } from "../form/FormContainer";
import { Form } from "../form/Form";
import { ButtonSpan } from "../../styles";
import { FormLogo } from "../form/FormLogo";
import { FormTitle } from "../form/FormTitle";
import { FormInput, FormInputContainer } from "../form/FormInputContainer";
import { FormButton } from "../form/FormButton";

interface ISignInPopup {
    didClosePopup: () => void;
    style?: React.CSSProperties;
}

interface ISignInForm {
    onbi_sign_in_id: string;
    onbi_sign_in_password: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export function SignInPopup({ didClosePopup, style }: ISignInPopup) {
    const {
        register,
        formState: { isValid },
        getValues,
        setValue,
        handleSubmit,
    } = useForm<ISignInForm>({
        mode: "onChange",
    });

    const [submitting, setSubmitting] = useState(false);

    const onCompletedSignIn = (data: signInMember) => {
        setSubmitting(false);

        const {
            signInMember: { ok, error, token },
        } = data;

        if (ok) {
            if (token) {
                const loginId = getValues("onbi_sign_in_id");

                signUserIn({
                    loginId,
                    token,
                    autoSignIn: true,
                    refresh: true,
                });
            } else {
                signUserOut();
            }
        } else {
            alert(error);
            removeTokens();
            setValue("onbi_sign_in_password", "");
        }
    };

    const [signInMutation] = useMutation<signInMember, signInMemberVariables>(
        SIGNIN_MEMBER_MUTATION,
        { onCompleted: onCompletedSignIn },
    );

    const onClickSignIn = () => {
        const loginId = getValues("onbi_sign_in_id");

        if (hasOnbiLoginInfoId(loginId) === false) {
            setSubmitting(true);

            signInMutation({
                variables: {
                    id: getValues("onbi_sign_in_id"),
                    password: getValues("onbi_sign_in_password"),
                    autoSignIn: true,
                    signInType: SignInType.MEMBER,
                },
            });
        } else {
            alert("이미 로그인된 계정입니다.");
            setValue("onbi_sign_in_id", "");
            setValue("onbi_sign_in_password", "");
        }
    };

    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <FormContainer>
                    <Form onSubmit={handleSubmit(onClickSignIn)}>
                        <ButtonSpan
                            onClick={() => {
                                window.location.href = "/";
                            }}
                            style={{ paddingTop: "50px" }}
                        >
                            <FormLogo>ONBI</FormLogo>
                        </ButtonSpan>

                        <FormTitle>나만의 온라인 비서</FormTitle>
                        <FormInputContainer
                            input={
                                <FormInput
                                    {...register("onbi_sign_in_id", {
                                        required: true,
                                    })}
                                    placeholder="아이디"
                                />
                            }
                        ></FormInputContainer>
                        <FormInputContainer
                            input={
                                <FormInput
                                    {...register("onbi_sign_in_password", {
                                        required: true,
                                    })}
                                    placeholder="비밀번호"
                                    type="password"
                                />
                            }
                        ></FormInputContainer>
                        <FormButton
                            title="로그인"
                            disabled={!isValid || submitting}
                            type="submit"
                        />
                    </Form>
                </FormContainer>
            </Container>
        </Popup>
    );
}
