import styled from "styled-components";
import { lightTheme } from "../../styles";
import { seeBoard_seeBoard_board } from "../../__generated__/seeBoard";
import { FAQEnter, FAQImage, FAQText } from "./FAQDetail";

export const BOARD_CHANGE_EMAIL_INDEX = 53;

const Container = styled.div`
    margin: 10px;
    margin-top: 20px;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

interface IFAQDetailChangeEmailProps {
    board: seeBoard_seeBoard_board;
    newTab: boolean;
}

export function FAQDetailChangeEmail({
    board,
    newTab,
}: IFAQDetailChangeEmailProps) {
    return (
        <Container>
            <Content>
                <FAQText>{"안녕하세요 온비입니다."}</FAQText>
                <FAQText>
                    {
                        "스페이스 클라우드 로그인시 네이버나 카카오의 경우 2차인증 관련 문제가 있어 e-mail 계정으로 전환이 필요합니다."
                    }
                </FAQText>
                <FAQText>
                    {
                        "스페이스 클라우드 로그인 계정을 e-mail 계정으로 변경하는 방법을 설명합니다."
                    }
                </FAQText>
                <FAQEnter />
                <FAQText>
                    {
                        "1. 스페이스 클라우드에 호스트 회원가입을 e-mail 로 진행해주세요."
                    }
                </FAQText>
                <FAQImage
                    src={require("../../assets/images/space_change_email_signup.webp")}
                    style={{ width: "350px" }}
                />
                <FAQEnter />
                <FAQText>
                    {"2. 기존 관리하던 호스트계정으로 로그인해주세요."}
                </FAQText>
                <FAQEnter />
                <FAQText>
                    {
                        "3. 오른쪽 아래 채팅 화면을 열어 현재 계정을 가입하신 이메일 계정으로 변경 요청하세요."
                    }
                </FAQText>
                <FAQText style={{ color: lightTheme.red }}>
                    {
                        '  - 로그인 후 바로 나오는 홈 화면에서 채팅 버튼을 눌러야 "계정 변경 문의" 버튼이 정상적으로 보입니다.'
                    }
                </FAQText>
                <FAQImage
                    src={require("../../assets/images/space_change_email_chat.webp")}
                    style={{ width: "250px" }}
                />

                <FAQEnter />
                <FAQText>
                    {"4. 채팅에 표시된 정보를 입력하신 후 제출합니다."}
                </FAQText>
                <FAQText style={{ color: lightTheme.red }}>
                    {
                        '  - 변경 사유는 "메일 계정으로 운영 및 관리 희망 합니다"등을 입력하시면 됩니다.'
                    }
                </FAQText>
                <FAQImage
                    src={require("../../assets/images/space_change_email_chat2.webp")}
                    style={{ width: "250px" }}
                />
                <FAQEnter />
                <FAQText>
                    {
                        "5. 업무일 기준 약 2일이면 계정 변경이 되고 변경이 완료되셨으면 ONBI 가입을 이어서 진행하시면 됩니다."
                    }
                </FAQText>
                <FAQText style={{ color: lightTheme.red }}>
                    {
                        "  - 만약 업무일 기준 2~3일이 경과하여도 계정 변경이 이루어지지 않았다면,"
                    }
                </FAQText>
                <FAQText style={{ color: lightTheme.red }}>
                    {
                        "    스페이스클라우드에 채팅으로 직접 문의를 해보셔야할수도 있습니다."
                    }
                </FAQText>
            </Content>
        </Container>
    );
}
